import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent
} from '@material-ui/core';
import {
  Search,
  Assessment,
  MonetizationOn,
  People
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: '#f5f5f5'
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    textAlign: 'center'
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  icon: {
    fontSize: 40,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  },
  featureTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 500
  },
  description: {
    color: theme.palette.text.secondary
  }
}));

const features = [
  {
    icon: <Search />,
    title: "Quick Employer Search & Advice",
    description: "Effortlessly locate your 401k, 403b, 457, or TSP plan —just enter your employer's name and get the investment allocation for your risk and investment strategy."
  },
  {
    icon: <Assessment />,
    title: "AI Retirement Calculator",
    description: "Our AI-driven calculator analyzes your current contributions, lifestyle, and spending habits to provide insights, helping you make data-driven decisions."
  },
  {
    icon: <MonetizationOn />,
    title: "Save on hidden fees",
    description: "Stop losing money to hidden fees. Plootus optimizes your 401k and investment plans, potentially saving you an average of $131,255 over time."
  },
  {
    icon: <People />,
    title: "Connect Financial Advisors",
    description: "Financial Advisors can view all client data—income, expenses, assets, liabilities, and goals—in one place. Plootus even manages 401k and 403b plans for you."
  }
];

const FeaturesSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h3" className={classes.title}>
          Smart Retirement Planning — Tailored for You
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Whether you are optimizing your 401k, tracking goals, or seeking expert advice, 
          Plootus gives you the tools to secure your financial future.
        </Typography>
        
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.icon}>
                    {React.cloneElement(feature.icon, { className: classes.icon })}
                  </div>
                  <Typography variant="h5" className={classes.featureTitle}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default FeaturesSection;