import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import FAQHero from './components/FAQHero';
import FAQCategory from './components/FAQCategory';
import { faqData } from './data/faqData';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    }
  },
  categoryList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4) // Note: gap property might not work in older browsers
  }
}));

const FAQ = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');

  const getFilteredFAQs = () => {
    if (!searchQuery) return faqData;

    const filtered = {};
    Object.keys(faqData).forEach(category => {
      const filteredItems = faqData[category].items.filter(
        item => 
          item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.answer.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredItems.length > 0) {
        filtered[category] = {
          title: faqData[category].title,
          items: filteredItems
        };
      }
    });
    return filtered;
  };

  // For older browser support, you might want to add margin instead of gap
  const categoryListStyle = {
    '& > * + *': {
      marginTop: '32px' // This is equivalent to gap: 4 in the MUI v5 version
    }
  };

  return (
    <Box className={classes.root}>
      <FAQHero searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      
      <Container 
        maxWidth="md" 
        className={classes.container}
      >
        <Box 
          className={classes.categoryList}
          style={categoryListStyle}
        >
          {Object.keys(getFilteredFAQs()).map(category => (
            <FAQCategory
              key={category}
              title={getFilteredFAQs()[category].title}
              items={getFilteredFAQs()[category].items}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;