// src/pages/FAQ/data/faqData.js

export const faqData = {
    general: {
      title: 'General',
      items: [
        {
          question: "What is Plootus?",
          answer: "Plootus is an all-in-one financial companion that helps you optimize your retirement plan account (401k, 403b, 457, or TSP), budget smarter, and manage all your financial accounts in one place. Our platform provides personalized investment advice and helps you track your financial goals effectively."
        },
        // {
        //   question: "Is Plootus free to use?",
        //   answer: "Yes, Plootus offers a comprehensive free plan that includes basic account tracking and retirement planning features. We also offer premium plans with advanced features for users who need more detailed analysis and personalized recommendations."
        // },
        {
          question: "How do I get started with Plootus?",
          answer: "Getting started is easy! Simply download the Plootus app from the App Store or Google Play Store or use our website to create an account. You can then link your financial accounts to view your complete financial profile at once, including income, expenses, assets, liabilities and net worth. From there, you can easily set your retirement goal, and let our allocation engine help you with planning for retirement."
        },
        {
          question: "Do I need to be an investment expert to use Plootus?",
          answer: "Not at all! Plootus is designed for everyone, from beginners to experienced investors. Our platform simplifies complex financial decisions and provides clear, actionable recommendations tailored to your specific situation."
        }
      ]
    },
  
    security: {
      title: 'Security & Privacy',
      items: [
        {
          question: "How secure is my data with Plootus?",
          answer: "We implement bank-level security measures including 256-bit SSL encryption for all data in transit. We never store your financial institution login credentials, and all sensitive data is encrypted using industry-leading standards."
        },
        {
          question: "What information do you collect?",
          answer: "We collect only the minimum necessary information to provide our services. We do not ask for sensitive PII such as your Social Security Number. For linked accounts, we only have read-only access to your transaction data."
        },
        {
          question: "Can Plootus make changes to my accounts?",
          answer: "No, Plootus only has read-only access to your accounts. We can provide recommendations, but we do not have the ability to make any changes to your accounts or perform transactions on your behalf. You maintain full control over all your accounts."
        },
        {
          question: "How is my financial data protected?",
          answer: "We comply with relevant financial regulations and data protection standards."
        }
      ]
    },
  
    investments: {
      title: 'Investment & Retirement',
      items: [
        {
          question: "How does Plootus optimize my 401k, 403b, 457 or TSP retirement plan account?",
          answer: "Plootus analyzes the fund options within your retirement plan account and recommends the most optimal investment allocation based on your risk tolerance, age, and retirement goals. We consider factors like expense ratios, historical performance, and diversification to ensure better returns with lower fees."
        },
        {
          question: "Can Plootus manage my retirement accounts?",
          answer: "While we don't directly manage your accounts, we provide detailed recommendations and insights for your retirement accounts. Our AI-powered analysis helps you make informed decisions about your investments."
        },
        {
          question: "How often should I review my investment strategy?",
          answer: "We recommend reviewing your investment strategy regularly or whenever there are significant life changes"
        },
        {
          question: "What types of retirement accounts does Plootus support?",
          answer: "Plootus supports all major retirement account types including 401(k), 403(b), 457, TSP, Traditional IRA, and Roth IRA accounts. We provide specialized recommendations for each account type based on their unique characteristics and tax implications."
        }
      ]
    },
  
    features: {
      title: 'Features & Integration',
      items: [
        {
          question: "Which financial institutions does Plootus support?",
          answer: "Plootus supports over 14,000 financial institutions, including major banks, credit unions, investment firms, and retirement plan providers across the United States."
        },
        {
          question: "Can I track multiple accounts?",
          answer: "Yes, you can track unlimited accounts including checking, savings, credit cards, investments, and retirement accounts. Our dashboard provides a comprehensive view of all your finances in one place."
        },
        {
          question: "What reports and analytics are available?",
          answer: "Plootus provides detailed reports on spending patterns, investment performance, retirement projections, and net worth tracking."
        },
        {
          question: "Is there a mobile app available?",
          answer: "Yes, Plootus offers both iOS and Android mobile apps, providing full access to all features on the go. The apps are regularly updated with new features and improvements based on user feedback."
        }
      ]
    },
  
    support: {
      title: 'Support & Updates',
      items: [
        {
          question: "How can I get help if I have questions?",
          answer: "Our support team is available via email and chat, during business hours. We also maintain a comprehensive knowledge base and video tutorials."
        },
        {
          question: "How often is Plootus updated?",
          answer: "We release regular updates with new features, improvements, and security enhancements. Major updates are announced through our blog and email newsletter, while minor updates happen automatically in the background."
        },
        {
          question: "Can I suggest new features?",
          answer: "Absolutely! We welcome user feedback and feature suggestions. You can submit ideas through our feedback form in the app or contact our support team. Many of our features come directly from user suggestions."
        },
        {
          question: "What should I do if I cannot find my employer on Plootus?",
          answer: "If you are unable to locate your employer, please reach out to our support team at support@plootus.com. We will be happy to assist you and help and add your employer's plan."
        }
      ]
    }
  };