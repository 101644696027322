// AdvisorCard.jsx
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    padding: '24px 32px',
    border: '1px solid #E5E7EB',
    height: '100%',
    '&:hover': {
      boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
      transform: 'translateY(-2px)',
      transition: 'all 0.2s ease-in-out'
    }
  },
  imageContainer: {
    width: 130,
    height: 130,
    flexShrink: 0,
    borderRadius: 12,
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  content: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '16px 48px'
  },
  infoGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: '#111827',
    gridColumn: '1 / -1',
    marginBottom: 8
  },
  label: {
    fontSize: 14,
    color: '#6B7280'
  },
  value: {
    fontSize: 14,
    color: '#111827'
  }
}));

const AdvisorCard = ({ advisor }) => {
  const classes = useStyles();
  const [imgSrc, setImgSrc] = useState(advisor.image);

  const handleImageError = () => {
    // If the main image fails, use a fallback
    setImgSrc(`https://ui-avatars.com/api/?name=${encodeURIComponent(advisor.name)}&background=random`);
  };
  
  return (
    <div className={classes.card}>
      <div className={classes.imageContainer}>
        <img 
          src={imgSrc}
          alt={advisor.name} 
          className={classes.image}
          onError={handleImageError}
        />
      </div>
      <div className={classes.content}>
        <Typography className={classes.name}>
          {advisor.name}
        </Typography>

        <div className={classes.infoGroup}>
          <Typography className={classes.label}>
            Firm
          </Typography>
          <Typography className={classes.value}>
            {advisor.firm}
          </Typography>
        </div>

        <div className={classes.infoGroup}>
          <Typography className={classes.label}>
            City/State
          </Typography>
          <Typography className={classes.value}>
            {advisor.cityState}
          </Typography>
        </div>

        <div className={classes.infoGroup}>
          <Typography className={classes.label}>
            Email
          </Typography>
          <Typography className={classes.value}>
            {advisor.email}
          </Typography>
        </div>

        <div className={classes.infoGroup}>
          <Typography className={classes.label}>
            Website/Linkedin
          </Typography>
          <Typography className={classes.value}>
            {advisor.website}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AdvisorCard;