import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button,
  Container,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';

  // Developed and managed by Mejona technology

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  card: {
    flex: 1,
    border: '1px solid #E5E7EB',
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  blueHeader: {
    backgroundColor: '#F8F9FF',
  },
  greenHeader: {
    backgroundColor: '#F0FDF4',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  iconBox: {
    width: 40,
    height: 40,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 24,
      height: 24,
      objectFit: 'contain',
      filter: 'brightness(0) invert(1)',
    }
  },
  blueIcon: {
    backgroundColor: '#4361EE',
  },
  greenIcon: {
    backgroundColor: '#22C55E',
  },
  trialText: {
    fontSize: '0.875rem',
  },
  blueText: {
    color: '#4361EE',
  },
  greenText: {
    color: '#22C55E',
  },
  tableContainer: {
    padding: theme.spacing(3),
  },
  table: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(2),
      verticalAlign: 'top',
      '&.enterprise-cell': {
        position: 'relative',
        '&:first-child': {
          '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
            bottom: theme.spacing(2),
            width: 1,
            backgroundColor: '#E5E7EB',
          }
        }
      }
    },
  },
  pricingGrid: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  priceBox: {
    flex: 1,
    border: '1px solid #E5E7EB',
    borderRadius: 12,
    padding: theme.spacing(2),
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    '&.selected': {
      border: '2px solid #4361EE',
    }
  },
  radio: {
    padding: 0,
    marginBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
    '&.Mui-checked': {
      color: '#4361EE',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  price: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1,
  },
  periodText: {
    color: '#6B7280',
  },
  saveText: {
    color: '#4361EE',
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  button: {
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: 12,
    fontSize: '1rem',
    textTransform: 'none',
  },
  blueButton: {
    backgroundColor: '#4361EE',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3651D4',
    }
  },
  greenButton: {
    border: '2px solid #22C55E',
    color: '#22C55E',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(34, 197, 94, 0.04)',
    }
  },
  customText: {
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  teamSizeText: {
    color: '#6B7280',
  },
  featureList: {
    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  },
  divider: {
    borderTop: '1px solid #E5E7EB',
    margin: theme.spacing(3, 0),
  },
  tableTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 16,
      maxWidth: 500,
      position: 'relative'
    }
  },
  dialogTitle: {
    padding: theme.spacing(3),
    paddingRight: theme.spacing(6), // Space for close button
    '& h2': {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#1A202C',
      fontFamily: '"DM Sans", sans-serif',
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: '#9CA3AF',
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#4B5563'
    }
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  formField: {
    marginBottom: theme.spacing(2.5),
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
      '&:hover fieldset': {
        borderColor: '#4361EE',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4361EE',
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 16px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(16px, 16px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    }
  },
  radioGroup: {
    flexDirection: 'row',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& .MuiFormControlLabel-root': {
      marginRight: 0
    }
  },
  submitButton: {
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }
  }
}));

// Payment Dialog Component
const PaymentDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      className={classes.dialog}
    >
      <IconButton 
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h2">
          Payment Not Available
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography style={{ color: '#4B5563' }}>
          At this time we are not accepting payments. Please try again later or contact support for assistance.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
  // Developed and managed by Mejona technology

export { useStyles, PaymentDialog };
// Part 2 - Main Components
// Request Demo Dialog Component
const RequestDemoDialog = ({ open, onClose, type }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    userType: type || 'employer',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://jsonplaceholder.typicode.com/posts', {
        ...formData,
        timestamp: new Date().toISOString()
      });

      console.log('Demo request submitted:', response.data);
      setSnackbar({
        open: true,
        message: 'Thank you for your interest! We will contact you soon.',
        severity: 'success'
      });
      
      setFormData({
        name: '',
        email: '',
        phone: '',
        companyName: '',
        userType: type || 'employer',
      });
      
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: 'Something went wrong. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Developed and managed by Mejona technology
  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        className={classes.dialog}
      >
        <IconButton 
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={loading}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="h2">
            Request a Demo
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" className={classes.formField}>
              <RadioGroup
                name="userType"
                value={formData.userType}
                onChange={handleInputChange}
                className={classes.radioGroup}
              >
                <FormControlLabel 
                  value="employer" 
                  control={<Radio color="primary" />} 
                  label="I'm an Employer" 
                  disabled={loading}
                />
                <FormControlLabel 
                  value="advisor" 
                  control={<Radio color="primary" />} 
                  label="I'm an Advisor" 
                  disabled={loading}
                />
              </RadioGroup>
            </FormControl>

            <TextField
              className={classes.formField}
              fullWidth
              label="Full Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleInputChange}
              required
              disabled={loading}
            />

            <TextField
              className={classes.formField}
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
              required
              disabled={loading}
            />

            <TextField
              className={classes.formField}
              fullWidth
              label="Phone Number"
              name="phone"
              type="tel"
              variant="outlined"
              value={formData.phone}
              onChange={handleInputChange}
              required
              disabled={loading}
            />

            <TextField
              className={classes.formField}
              fullWidth
              label={formData.userType === 'employer' ? "Company Name" : "Firm Name"}
              name="companyName"
              variant="outlined"
              value={formData.companyName}
              onChange={handleInputChange}
              required
              disabled={loading}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={`${classes.button} ${classes.blueButton} ${classes.submitButton}`}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Submit Request'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

// Main PlanSelection Component
const PlanSelection = () => {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState('yearly');
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [demoDialogOpen, setDemoDialogOpen] = useState(false);
  const [demoType, setDemoType] = useState('');

  const handleDemoRequest = (type) => {
    setDemoType(type);
    setDemoDialogOpen(true);
  };

  return (
    <Container maxWidth="lg" style={{ paddingTop: 24, paddingBottom: 24 }}>
      <Box className={classes.cardsContainer}>
        {/* Individual Plan Card */}
        <Box className={classes.card}>
          <Box className={`${classes.header} ${classes.blueHeader}`}>
            <Box className={classes.titleContainer}>
              <Box className={`${classes.iconBox} ${classes.blueIcon}`}>
                <img src="/images/plan-icon/healthicons_person.png" alt="Individual Plan" />
              </Box>
              <Typography variant="h6">Individual Plan</Typography>
            </Box>
            <Typography className={`${classes.trialText} ${classes.blueText}`}>
              First 30-Days are on us!
            </Typography>
          </Box>

          <Box className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography>
                      Optimise your 401k/403b/457/TSP Investments and Build Long-Term Financial Security
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Box className={classes.pricingGrid}>
                      <Box 
                        className={`${classes.priceBox} ${selectedPlan === 'monthly' ? 'selected' : ''}`}
                        onClick={() => setSelectedPlan('monthly')}
                      >
                        <Radio 
                          checked={selectedPlan === 'monthly'}
                          className={classes.radio}
                          color="default"
                        />
                        <Typography className={classes.price}>$3.99</Typography>
                        <Typography className={classes.periodText}>/Month</Typography>
                      </Box>
                      <Box 
                        className={`${classes.priceBox} ${selectedPlan === 'yearly' ? 'selected' : ''}`}
                        onClick={() => setSelectedPlan('yearly')}
                      >
                        <Radio 
                          checked={selectedPlan === 'yearly'}
                          className={classes.radio}
                          color="default"
                        />
                        <Typography className={classes.price}>$39.99</Typography>
                        <Typography className={classes.periodText}>/Year</Typography>
                        <Typography className={classes.saveText}>Save 16% on yearly plan!</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Button 
                      className={`${classes.button} ${classes.blueButton}`}
                      onClick={() => setPaymentDialogOpen(true)}
                    >
                      Start Free Trial
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Box className={classes.divider} />
                    <Typography className={classes.tableTitle}>What's included?</Typography>
                    <Box className={classes.featureList}>
                      {[
                        "A Comprehensive View into Your Individual Financial Health",
                        "Personalized Allocation Advice for your Employer-Sponsored Retirement Plan Account",
                        "Actionable Insights on Getting to Your Retirement Goal",
                        "Real-Time Monitoring of Your Financial Progress"
                      ].map((feature, index) => (
                        <Box key={index}>
                          <CheckCircleIcon className={classes.blueText} />
                          <Typography>{feature}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>

        {/* Enterprise Plans Card */}
        <Box className={classes.card}>
          <Box className={`${classes.header} ${classes.greenHeader}`}>
            <Box className={classes.titleContainer}>
              <Box className={`${classes.iconBox} ${classes.greenIcon}`}>
                <img src="/images/plan-icon/f7_person-3-fill.png" alt="Enterprise Plans" />
              </Box>
              <Typography variant="h6">Enterprise Plans</Typography>
            </Box>
            <Typography className={`${classes.trialText} ${classes.greenText}`}>
              First 30-Days are on us!
            </Typography>
          </Box>

          <Box className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell className="enterprise-cell">
                    <Typography className={classes.tableTitle}>FOR EMPLOYERS</Typography>
                    <Typography>
                      Empower Your Workforce with Holistic Financial & Retirement Planning
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableTitle}>FOR FINANCIAL ADVISORS</Typography>
                    <Typography>
                      Enhance Client Offerings with Smart Investment Tools
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="enterprise-cell">
                    <Typography className={classes.customText}>Custom</Typography>
                    <Typography className={classes.teamSizeText}>(Based on Team Size)</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.customText}>Custom</Typography>
                    <Typography className={classes.teamSizeText}>(Based on Team Size)</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="enterprise-cell">
                    <Button 
                      className={`${classes.button} ${classes.greenButton}`}
                      onClick={() => handleDemoRequest('employer')}
                    >
                      Request a Demo
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button 
                      className={`${classes.button} ${classes.greenButton}`}
                      onClick={() => handleDemoRequest('advisor')}
                    >
                      Request a Demo
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="enterprise-cell">
                    <Box className={classes.divider} />
                    <Typography className={classes.tableTitle}>What's included?</Typography>
                    <Box className={classes.featureList}>
                      {[
                        "Optimized Retirement Plan Guidance",
                        "Centralised Financial Overview for Employees",
                        "Financial Wellness Webinars & Workshops"
                      ].map((feature, index) => (
                        <Box key={index}>
                          <CheckCircleIcon className={classes.greenText} />
                          <Typography>{feature}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.divider} />
                    <Typography className={classes.tableTitle}>What's included?</Typography>
                    <Box className={classes.featureList}>
                      {[
                        "Client Portfolio Optimization for Retirement Accounts",
                        "Multi-Client Dashboard",
                        "Financial Wellness Webinars & Workshops"
                      ].map((feature, index) => (
                        <Box key={index}>
                          <CheckCircleIcon className={classes.greenText} />
                          <Typography>{feature}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>

      <PaymentDialog 
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
      />

      <RequestDemoDialog
        open={demoDialogOpen}
        onClose={() => setDemoDialogOpen(false)}
        type={demoType}
      />
    </Container>
  );
};

export default PlanSelection;