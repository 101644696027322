import React from "react";
import styles from "./EmpCarousel.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import data from "./AdsList";

import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";

const EmpCarousel = () => {
  const { height, width } = useWindowDimensions();
  let cnt;
  if (width <= 686) cnt = 1;
  else if (width <= 1150 && width > 686) cnt = 2;
  else cnt = 3;

  return (
    <div className={styles.main}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={data.length}
        visibleSlides={cnt}
        isPlaying={true}
        className={styles.car}
      >
        <Slider>
          {data.map((item, index) => (
            <Slide index={index}>
              <div className={styles.card}>
                <img
                  src={item.img}
                  alt={item.alt}
                  className={styles.imgClass}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={styles.btns}>{`<`}</ButtonBack>
        <ButtonNext className={styles.btns}>{`>`}</ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default EmpCarousel;
