import {
  List,
  Typography,
  Button,
  Modal,
  Radio,
  Divider,
  Table,
  Spin,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import { getUsers, searchEmployer } from "./api";
import styles from "./EmailUsers.module.css";

function EmailUsersModal(props) {
  const [employer, setEmployer] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [employerEin, setEmployerEin] = useState(null);

  const [all, setAll] = useState(false);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [emailDesign, setEmailDesign] = useState({
    id: null,
    tag: null,
  });

  const { availableDesign } = props;

  const columns = [
    {
      title: "Select",
      key: "Select",
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleCheck(e, record)}
          checked={record.isChecked}
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
  ];

  const handleCheck = (e, user) => {
    if (e.target.checked) {
      let updatedUsers = users.map((u) => {
        if (u.uuid === user.uuid) {
          return { ...u, isChecked: true };
        }
        return { ...u };
      });
      setUsers(updatedUsers);
      let _users = selectedUsers;
      _users.push(user);
      setSelectedUsers(_users);
      if (_users.length === users.length) {
        setSelectAll(true);
      }
    } else {
      let updatedUsers = users.map((u) => {
        if (u.uuid === user.uuid) {
          return { ...u, isChecked: false };
        }
        return { ...u };
      });
      setUsers(updatedUsers);
      let _users = selectedUsers.filter((_user) => _user.uuid !== user.uuid);
      setSelectedUsers(_users);
      if (selectAll) {
        setSelectAll(false);
      }
    }
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    const employees = await searchEmployer(e.target.value);
    if (employees) {
      setEmployer(employees);
    }
  };

  const handleEmployerSelect = async (ein) => {
    setEmployer([]);
    setEmployerEin(ein);
    const users = await getUsers({ selected: "NOTALL", ein });
    if (users) {
      let _users = users.map((user) => {
        return { ...user, isChecked: false };
      });
      setUsers(_users);
    }
  };

  const handleEmailUsers = async () => {
    if (subject.length === 0 || emailDesign.tag === null) {
      toast.error("Fill all fields", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setShowSubjectModal(false);
    props.handleEmailUsers({
      all: all && selectAll,
      partial: !selectAll,
      users: selectedUsers,
      ein: employerEin,
      subject: subject,
      tag: emailDesign.tag,
    });
  };

  const handleAllUsers = async () => {
    setAll(true);
    const users = await getUsers({ selected: "ALL", ein: "" });
    if (users) {
      setUsers(users);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectAll(true);
      let _users = users.map((u) => {
        return { ...u, isChecked: true };
      });
      setUsers(_users);
      setSelectedUsers(_users);
    } else {
      setSelectAll(false);
      let _users = users.map((u) => {
        return { ...u, isChecked: false };
      });
      setUsers(_users);
      setSelectedUsers([]);
    }
  };

  const handleBack = () => {
    setUsers([]);
    setAll(false);
  };

  return (
    <>
      <Modal
        visible={true}
        title="Select Users"
        okText="Next"
        cancelText={users.length > 0 ? "Back" : "Cancel"}
        onCancel={() => {
          users.length > 0 ? handleBack() : props.setShowEmailUsersModal(false);
        }}
        onOk={() => {
          props.handleLoadDesignModal(false);
          setShowSubjectModal(true);
        }}
        width={700}
        className={styles.emailUsersModal}
      >
        {users.length > 0 ? (
          <div>
            <Checkbox checked={selectAll} onChange={handleSelectAll}>
              Select All
            </Checkbox>
            <Table
              dataSource={users}
              columns={columns}
              className={styles.list}
            />
          </div>
        ) : (
          <>
            {!isSearching && searchText.length === 0 && (
              <>
                <div className={styles.allUsersDiv}>
                  <span className={styles.allUsers}>All Users</span>
                  <Button
                    className={styles.allUsersButton}
                    onClick={handleAllUsers}
                  >
                    SELECT
                  </Button>
                </div>
                <br />
                <Divider plain>Or</Divider>
              </>
            )}
            <div>
              <TextField
                label="Employer"
                placeholder="Search for Employers..."
                onChange={handleSearch}
                fullWidth
                variant="outlined"
                onFocus={() => setIsSearching(true)}
                onBlur={() => setIsSearching(false)}
              />
              {searchText !== "" ? (
                employer.length === 0 ? (
                  <Spin style={{ marginLeft: 100, margin: 10 }} />
                ) : (
                  <List
                    header={null}
                    footer={null}
                    dataSource={employer}
                    className={styles.list}
                    renderItem={(employer) => {
                      return (
                        <List.Item>
                          <Typography.Text>
                            {employer.sponsor_name}
                          </Typography.Text>
                          <Button
                            onClick={() => handleEmployerSelect(employer.ein)}
                          >
                            SELECT
                          </Button>
                        </List.Item>
                      );
                    }}
                  />
                )
              ) : null}
            </div>
          </>
        )}
      </Modal>
      <Modal
        visible={showSubjectModal}
        title="Email Subject and Body Text"
        okText="Send Email"
        cancelText={users.length > 0 ? "Back" : "Cancel"}
        onCancel={() => {
          users.length > 0 ? setUsers([]) : props.setShowEmailUsersModal(false);
        }}
        onOk={handleEmailUsers}
        width={700}
        className={styles.emailUsersModal}
      >
        <TextField
          label="Subject"
          placeholder="Enter email subject"
          onChange={(e) => setSubject(e.target.value)}
          fullWidth
          className={styles.input}
          style={{ margin: 10 }}
        />

        <div style={{ margin: 10 }}>
          <h4>Select Template</h4>
          {availableDesign.length === 0 ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : (
            <List
              header={null}
              className={styles.list}
              footer={null}
              dataSource={availableDesign}
              locale={{ emptyText: "No Designs Available" }}
              renderItem={(design) => {
                return (
                  <List.Item>
                    <Typography.Text>
                      {design.tag +
                        " (Last Updated : " +
                        design.updatedAt.split("T")[0] +
                        " " +
                        design.updatedAt.split("T")[1].substring(0, 4) +
                        ")"}
                    </Typography.Text>
                    <Radio
                      checked={design.id === emailDesign.id}
                      onChange={() => setEmailDesign(design)}
                    >
                      SELECT
                    </Radio>
                  </List.Item>
                );
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

export default EmailUsersModal;
