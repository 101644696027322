// LogIn.jsx
import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './LogIn.module.css';
import InputCard from './InputCard';
import LoginSignupContext from '../../Reusables/VisualAndAccord/Context/LoginSignupContext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Set app element for accessibility
Modal.setAppElement('#root');

const LogIn = ({
  modalIsOpen,
  openModal,
  closeModal,
  signupopenModal,
  fromFees,
}) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { loginModal, idx, setIdx, setLoginModal } = useContext(LoginSignupContext);

  // Handle dropdown item click
  const handleClick = (index) => {
    setIdx(index);
    setLoginModal(true);
    setDropdownOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest(`.${styles.loginContainer}`)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownOpen]);

  return (
    <div className={styles.loginContainer}>
      {fromFees ? (
        <div className={styles.fromFeesBtn} onClick={() => openModal()}>
          LogIn
        </div>
      ) : (
        <>
          <button 
            className={styles.loginButton}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            Login
            <KeyboardArrowDownIcon 
              className={`${styles.dropdownIcon} ${dropdownOpen ? styles.dropdownIconOpen : ''}`} 
            />
          </button>
          
          {dropdownOpen && (
            <div className={styles.loginDropdown}>
              <button 
                className={styles.loginDropdownItem}
                onClick={() => handleClick(1)}
              >
                Login as User
              </button>
              <button 
                className={styles.loginDropdownItem}
                onClick={() => handleClick(2)}
              >
                Login as Advisor
              </button>
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={modalIsOpen}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            zIndex: 1001,
          }
        }}
      >
        <div className={styles.closeContainer}>
          <button
            onClick={closeModal}
            className={styles.closeButton}
            disabled={loading}
          >
            <img
              className={styles.crossButton}
              src="/cross-thin.png"
              alt="Close"
            />
          </button>
        </div>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>
            <h3>Welcome Back!</h3>
          </div>
          <div className={styles.belowHeader}>
            Please login to your account
          </div>
        </div>
        <InputCard
          closeModal={closeModal}
          signupopenModal={signupopenModal}
          setLoading={setLoading}
          loading={loading}
          index={idx}
          loginStyle={{ paddingBottom: '30px' }}
        />
      </Modal>
    </div>
  );
};

export default LogIn;