"use strict";
// colors
Object.defineProperty(exports, "__esModule", { value: true });
exports.color_pallete = exports.BORDER_2 = exports.BORDER_1 = exports.BORDER_COLOR = exports.TRANSPARENT = exports.DISABLE_GREY = exports.PLACEHOLDER_GREY = exports.LIGHT_GREY_1 = exports.LIGHT_GREY = exports.TEXT_GREY_1 = exports.TEXT_GREY = exports.GREY_DARK = exports.GREY_2 = exports.GREY_1 = exports.GREY = exports.TEXT_BLACK_3 = exports.TEXT_BLACK_2 = exports.TEXT_BLACK_1 = exports.TEXT_BLACK = exports.BLACK = exports.DISABLED_PICKER = exports.OFF_WHITE = exports.WHITE = exports.DANGER_RED = exports.RED_1 = exports.DARK_RED_PLOOT = exports.RED_PLOOT = exports.DRAK_GREEN_PLOOT = exports.GREEN_BUTTON = exports.GREEN_PLOOT_1 = exports.GREEN_PLOOT = exports.BLUE_PLOOT_1 = exports.BLUE_BUTTON = exports.BLUE_PLOOT = void 0;
exports.BLUE_PLOOT = "#416ce1";
exports.BLUE_BUTTON = "#416ce1";
exports.BLUE_PLOOT_1 = "#094392";
exports.GREEN_PLOOT = "#34d86a";
exports.GREEN_PLOOT_1 = "#41d287";
exports.GREEN_BUTTON = "#52da9c";
exports.DRAK_GREEN_PLOOT = "#1bad53";
exports.RED_PLOOT = "#e4747a";
exports.DARK_RED_PLOOT = "#f25f5f";
exports.RED_1 = "#f74747";
exports.DANGER_RED = "#d34a4a";
exports.WHITE = "#fff";
exports.OFF_WHITE = "#f1f1f1";
exports.DISABLED_PICKER = "#f6f6f6";
exports.BLACK = "#000";
exports.TEXT_BLACK = "#404a57";
exports.TEXT_BLACK_1 = "#2b2d38";
exports.TEXT_BLACK_2 = "#919191";
exports.TEXT_BLACK_3 = "#535353";
exports.GREY = "grey";
exports.GREY_1 = "#707070";
exports.GREY_2 = "#989b9f";
exports.GREY_DARK = "#888";
exports.TEXT_GREY = "#777777";
exports.TEXT_GREY_1 = "#9f9f9f";
exports.LIGHT_GREY = "#f8f8f8";
exports.LIGHT_GREY_1 = "#efefef";
exports.PLACEHOLDER_GREY = "#7b7c83";
exports.DISABLE_GREY = "#bcbec6";
exports.TRANSPARENT = "rgba(0,0,0,0)";
exports.BORDER_COLOR = "#c6cbde";
exports.BORDER_1 = "#ddd";
exports.BORDER_2 = "#72768c";
// Temporary
exports.color_pallete = [
    // "#3E68D9",
    "#5A60CC",
    "#000060",
    "#41D287",
    "#05BCBC",
    "#045F60",
    "#43B53F",
    "#F25F5F",
    "#A60F0B",
    "#FE4F06",
    "#F25F5F",
    "#672C3F",
    "#B11F52",
    "#DB516C",
    // "#3E68D9",
    // "#41D287",
    // "#000060",
    // "#F25F5F",
    // "#5A60CC",
    // "#05BCBC",
    // "#045F60",
    // "#43B53F",
    // "#F25F5F",
    // "#A60F0B",
    // "#FE4F06",
    // "#672C3F",
    // "#B11F52",
    // "#DB516C",
    // ********************
    "#3E68D9",
    "#5A60CC",
    "#7550AE",
    "#000060",
    "#005C91",
    "#352A58",
    "#423E83",
    "#209ADB",
    "#293DAC",
    // '#98527D',
    // '#80D3FF',
    // '#41D287',
    // '#78E6C1',
    // '#A0D928',
    // '#05BCBC',
    // '#2A5165',
    // '#045F60',
    // '#329587',
    // '#43B53F',
    // '#8CAD31',
    // '#AD9D09',
    // '#BEE588',
    // '#F25F5F',
    // '#672C3F',
    // '#A60F0B',
    // '#B11F52',
    // '#DB516C',
    // '#9B3014',
    // '#D00000',
    // '#FE4F06',
    // '#7B2711',
    // '#BD4800',
    // '#F7D700',
    // '#E79311',
    // '#F4C764',
    // '#FF741D',
    // '#ffb507',
    // '#CEAE05',
    // '#FCB273',
    // '#966C51',
    // '#B672F0',
    // '#E389F4',
    // '#EB7FC2',
    // '#E09269',
    // '#71673D',
    // '#88C1A7',
];
