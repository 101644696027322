"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_DATA = exports.RESET_GENERAL_PORT = exports.RESET_PORTFOLIO = exports.HIGHLIGHT_PIE = exports.PIE_DATA_EMPTY = exports.NESTED_ACCORD = exports.EXPAND_ACCORD = exports.GENERAL_TRASACTION = exports.GENERAL_SET_PIE_DATA = exports.GENERAL_SET_GRAPH_DATA = void 0;
exports.GENERAL_SET_GRAPH_DATA = 'GENERAL_SET_GRAPH_DATA';
exports.GENERAL_SET_PIE_DATA = 'GENERAL_SET_PIE_DATA';
exports.GENERAL_TRASACTION = 'GENERAL_TRASACTION';
exports.EXPAND_ACCORD = 'EXPAND_ACCORD';
exports.NESTED_ACCORD = 'NESTED_ACCORD';
exports.PIE_DATA_EMPTY = 'PIE_DATA_EMPTY';
exports.HIGHLIGHT_PIE = 'HIGHLIGHT_PIE';
exports.RESET_PORTFOLIO = 'RESET_PORTFOLIO';
exports.RESET_GENERAL_PORT = 'RESET_GENERAL_PORT';
exports.RESET_DATA = 'RESET_DATA';
