import React from "react";
import { ResponsiveLine } from "@nivo/line";
import styles from "./FeesGraph.module.css";
import { Card, CircularProgress } from "@material-ui/core";
import {
  BLUE_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  roundNum,
  getFormattedValue,
} from "@plootus/common";
import { linearGradientDef } from "@nivo/core";
import Tooltip from "../../../Utils/Tooltip/Tooltip";
import { useFeesSaved } from "../../../Reusables/VisualAndAccord/Hooks/useFeesSaved";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import moment from "moment";
import { useSelector } from "react-redux";

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const FeesGraph = ({ coming, strategy, fromStatic }) => {
  const { data, total, flag, saved } = useFeesSaved(strategy);
  const sponsor_name = useSelector(
    (state) => state.userReducer.userData?.sponsor_name
  );
  const amount = `$${formatCommaString(
    roundNum(Math.abs(Number.isNaN(saved) || saved === 0 ? total : saved), 0, 2)
  )}`;

  const toolTipElement = (props) => {
    const xVal = props.point.data.x.toString();
    return (
      <div className={styles.toolTip}>
        Year:
        <span className={styles.xVal}> {xVal}</span>
        <div className={styles.yVal}>
          Fees Saved:{" "}
          <span className={styles.money} style={{ color: DRAK_GREEN_PLOOT }}>
            {getFormattedValue(props.point.data.y, "money", 2)}
          </span>
        </div>
      </div>
    );
  };
  const createdAt = useSelector(
    (state) => state.userReducer.userData?.createdAt
  );

  const newCreatedAt = createdAt ? createdAt : moment.now();

  const { width } = useWindowDimensions();

  return (
    <div className={styles.container}>
      <Card
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "10px",
          opacity: coming ? "0.6" : "1",
          boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        {data ? (
          <div className={styles.wrapper}>
            <div className={styles.title}>
              <div className={styles.nameWrapper}>
                <div className={styles.name}>
                  <div className={styles.headerFees}>
                    {fromStatic && "Average"} Fees Saved
                  </div>
                  <Tooltip
                    title="Expected fees Plootus can help you save over 30 years. The amount is based on 0.70% average fees paid by customers and total fees a user will pay if they invest in Plootus recommended investments. "
                    render={(props) => (
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        src="/question.png"
                        alt=""
                      />
                    )}
                  />
                </div>
                <div className={styles.company}>
                  {sponsor_name && flag ? sponsor_name : ""}
                </div>
              </div>
              {!coming && (
                <div
                  className={styles.amount}
                  style={{ color: DRAK_GREEN_PLOOT }}
                >
                  {amount !== "$NaN" ? amount.slice(0, -2) : "$0."}
                  <span className={styles.afterDecimal}>
                    {amount !== "$NaN" ? amount.slice(-2) : "00"}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.graph}>
              <ResponsiveLine
                data={data}
                margin={{
                  top: 20,
                  right: width > 576 ? 60 : 40,
                  bottom: 50,
                  left: width > 576 ? 60 : 40,
                }}
                xScale={{
                  type: "linear",
                  min: +moment(newCreatedAt).format("YYYY"),
                  max: +moment(newCreatedAt).format("YYYY") + 29,
                }}
                theme={{
                  grid: {
                    line: {
                      stroke: "#e5e5e5",
                      strokeWidth: 1,
                      strokeDasharray: "5 5",
                    },
                  },
                }}
                axisTop={null}
                axisRight={undefined}
                axisLeft={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 5,
                }}
                colors={[BLUE_PLOOT]}
                useMesh={true}
                enableArea={true}
                pointSize={0}
                defs={[
                  linearGradientDef("gradientA", [
                    { offset: 0, color: "inherit", opacity: 1 },
                    { offset: 100, color: "inherit", opacity: 0.2 },
                  ]),
                ]}
                fill={[{ match: "*", id: "gradientA" }]}
                crosshairType="cross"
                curve="natural"
                enableGridX={false}
                isInteractive={true}
                tooltip={toolTipElement}
              />
            </div>
            {coming && <div className={styles.center}>Coming Soon ...</div>}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <MuiThemeProvider theme={theme}>
              <CircularProgress size={40} />
            </MuiThemeProvider>
          </div>
        )}
      </Card>
    </div>
  );
};

export default FeesGraph;
