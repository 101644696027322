import React, { useContext, useState } from "react";
import cx from "classnames";
import styles from "./DobModal.module.css";
import Modal from "react-modal";
import Close from "@material-ui/icons/Close";
import { BLUE_PLOOT, updateProfile } from "@plootus/common";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MyText from "../../Utils/Texts/MyText";
import InputText from "../../Utils/InputText/InputText";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import useDateValidation from "../../Reusables/VisualAndAccord/Hooks/useDateValidation";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DobInput from "../../Utils/DobInput/DobInput";

function DobModal({ showDobModal, setShowDobModal }) {
  const userReducer = useSelector((state) => state.userReducer);
  const { width } = useWindowDimensions();
  const { minDate, maxDate } = useDateValidation();
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("1993-01-01")
  );
  const dispatch = useDispatch();
  const [dob, setDob] = useState("1993-01-01");

  // function convert(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
    setDob(moment(date).format("YYYY-MM-DD"));
  };

  const handleSave = async () => {
    await dispatch(updateProfile({ dob }, true));
    setShowDobModal(false);
  };

  return (
    <Modal
      isOpen={showDobModal}
      className={styles.modal}
      footer={null}
      // onCancel={handleClose}
    >
      <div>
        <p className={styles.header}>
          Hello, {userReducer.userData?.firstName}
        </p>
      </div>
      <p>
        To calculate your future expenses and the your social security benifit
        we need to know your Date of Birth
      </p>
      <DobInput
        maxDate={maxDate}
        minDate={minDate}
        dob={dob}
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
        setDob={setDob}
      />
      {/* {width <= 1024 ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              width: "100%",
            }}
          >
            <MyText
              className="title"
              style={{
                marginBottom: "5px",
                fontSize: width > 576 ? "16px" : "12px",
              }}
            >
              Date of Birth
            </MyText>
            <KeyboardDatePicker
              // disableToolbar
              variant="inline"
              maxDate={maxDate}
              minDate={minDate}
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-dialog"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disableUnderline: true,
              }}
              autoOk={true}
            />
          </div>
        </MuiPickersUtilsProvider>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InputText
            type="date"
            containerStyle={{ width: "40%" }}
            titleStyle={{
              fontSize: width > 576 ? "16px" : "12px",
              marginBottom: "5px",
            }}
            textInputStyle={null}
            config={{
              value: dob,
              onChange: (e) => {
                setDob(e.target.value);
              },
              defaultValue: "1993-01-01",
              disabled: false,
              min: minDate,
              max: maxDate,
            }}
            title="Date of Birth"
            err={null}
            typed={null}
          />
        </div>
      )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className={styles.button} onClick={handleSave}>
          Save
        </button>
      </div>
      {/* <div className={styles.close}>
        <Close size={25} color="grey" />
      </div> */}
    </Modal>
  );
}

export default DobModal;
