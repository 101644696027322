import {
  BLUE_PLOOT,
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  roundNum,
  toTitleCase,
} from '@plootus/common';
import moment from 'moment';
import React from 'react';
import styles from './CannotExpandTable.module.css';

function CannotExpandTable({
  valueColor,
  rightValue,
  leftTop,
  leftBottomValue,
  index,
  rightBottom,
  first,
  last,
}) {
  const amount = `$${formatCommaString(roundNum(rightValue, 0, 2))}`;
  const color = index % 2 ? '#f8f8f8' : '#fff';
  console.log('date', typeof rightBottom);
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: color,
        border: '1px solid #c6cbde',
        borderTop: first ? '1px solid #c6cbde' : '0px',
        borderTopLeftRadius: first ? '10px' : '0px',
        borderTopRightRadius: first ? '10px' : '0px',
        borderBottomLeftRadius: last ? '10px' : '0px',
        borderBottomRightRadius: last ? '10px' : '0px',
      }}
    >
      <div className={styles.title}>
        <div className={styles.circleCont}>
          <div
            className={styles.circle}
            style={{ backgroundColor: BLUE_PLOOT }}
          ></div>
        </div>
        <div className={styles.text}>{toTitleCase(leftTop)}</div>
      </div>
      <div className={styles.category}>{leftBottomValue}</div>
      <div className={styles.date}>
        {moment(rightBottom).format('DD MMMM YYYY')}
      </div>
      <div
        className={styles.money}
        style={{
          color: valueColor === 'INCOME' ? DRAK_GREEN_PLOOT : DARK_RED_PLOOT,
        }}
      >
        {amount.slice(0, -2)}
        <span style={{ fontSize: '15px', paddingTop: '3.5px' }}>
          {amount.slice(-2)}
        </span>
      </div>
    </div>
  );
}

export default CannotExpandTable;
