import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../Assets/logo.png';
import styles from './NavBar.module.css';
import LoginSignupContext from '../../../Reusables/VisualAndAccord/Context/LoginSignupContext';
import LogIn from '../../LogIn/LogIn';
import MainModal from '../../SignUp/MainScreen';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const NavBar = () => {
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [mobileSolutionsOpen, setMobileSolutionsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { loginModal, setLoginModal, signupModal, setSignupModal } = useContext(LoginSignupContext);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (solutionsOpen && !event.target.closest(`.${styles.solutionsContainer}`)) {
        setSolutionsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [solutionsOpen]);

  // Prevent body scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileMenuOpen]);

  const isLinkActive = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const renderSolutionsDropdown = () => {
    if (!solutionsOpen) return null;
    
    return (
      <div className={styles.solutionsDropdown}>
        <Link 
          to="/individual-users" 
          className={`${styles.dropdownItem} ${isLinkActive('/individual-users') ? styles.active : ''}`}
          onClick={() => setSolutionsOpen(false)}
        >
          Individual Users
        </Link>
        <Link 
          to="/financial-advisors" 
          className={`${styles.dropdownItem} ${isLinkActive('/financial-advisors') ? styles.active : ''}`}
          onClick={() => setSolutionsOpen(false)}
        >
          Financial Advisors
        </Link>
      </div>
    );
  };

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.container}>
        <Link to="/" className={styles.logoContainer}>
          <img src={Logo} alt="Plootus" className={styles.logo} />
        </Link>

        {/* Desktop Navigation */}
        <div className={styles.navLinks}>
          <Link 
            to="/" 
            className={`${styles.navLink} ${isLinkActive('/') ? styles.active : ''}`}
          >
            Overview
          </Link>
          
          <div className={styles.solutionsContainer}>
            <button 
              className={`${styles.navLink} ${styles.solutionsButton} ${
                isLinkActive('/individual-users') || isLinkActive('/financial-advisors') ? styles.active : ''
              }`}
              onClick={() => setSolutionsOpen(!solutionsOpen)}
            >
              Solutions
              <KeyboardArrowDownIcon 
                className={`${styles.dropdownIcon} ${solutionsOpen ? styles.dropdownIconOpen : ''}`}
              />
            </button>
            {renderSolutionsDropdown()}
          </div>

          <Link 
            to="/find-an-advisor" 
            className={`${styles.navLink} ${isLinkActive('/find-an-advisor') ? styles.active : ''}`}
          >
            Find an Advisor
          </Link>
          
          <Link 
            to="/partners" 
            className={`${styles.navLink} ${isLinkActive('/partners') ? styles.active : ''}`}
          >
            Partners
          </Link>
          
          {/* <Link 
            to="/pricing" 
            className={`${styles.navLink} ${isLinkActive('/pricing') ? styles.active : ''}`}
          >
            Pricing
          </Link> */}
          
          <Link 
            to="/finance-101" 
            className={`${styles.navLink} ${isLinkActive('/finance-101') ? styles.active : ''}`}
          >
            Finance 101
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className={styles.mobileMenuButton}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>

        {/* Desktop Auth Buttons */}
        <div className={styles.authButtons}>
          <div className={styles.loginWrapper}>
            <LogIn 
              modalIsOpen={loginModal}
              openModal={() => setLoginModal(true)}
              closeModal={() => setLoginModal(false)}
              signupopenModal={() => setSignupModal(true)}
              fromFees={false}
            />
          </div>

          <div className={styles.signupWrapper}>
            <MainModal
              modalIsOpen={signupModal}
              openModal={() => setSignupModal(true)}
              closeModal={() => setSignupModal(false)}
              loginopenModal={() => setLoginModal(true)}
              fromFees={false}
            />
          </div>
        </div>

        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div 
            className={styles.mobileMenuBackdrop} 
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Mobile Navigation */}
        <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ''}`}>
          <div className={styles.mobileNavLinks}>
            <Link 
              to="/" 
              className={`${styles.navLink} ${isLinkActive('/') ? styles.active : ''}`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Overview
            </Link>

            <div className={styles.mobileSolutionsContainer}>
              <button 
                className={`${styles.mobileSolutionsButton} ${
                  isLinkActive('/individual-users') || isLinkActive('/financial-advisors') ? styles.active : ''
                }`}
                onClick={() => setMobileSolutionsOpen(!mobileSolutionsOpen)}
              >
                Solutions
                <KeyboardArrowDownIcon 
                  className={`${styles.arrow} ${mobileSolutionsOpen ? styles.rotated : ''}`}
                />
              </button>
              {mobileSolutionsOpen && (
                <div className={styles.mobileSolutionsDropdown}>
                  <Link 
                    to="/individual-users" 
                    className={`${styles.dropdownItem} ${isLinkActive('/individual-users') ? styles.active : ''}`}
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      setMobileSolutionsOpen(false);
                    }}
                  >
                    Individual Users
                  </Link>
                  <Link 
                    to="/financial-advisors" 
                    className={`${styles.dropdownItem} ${isLinkActive('/financial-advisors') ? styles.active : ''}`}
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      setMobileSolutionsOpen(false);
                    }}
                  >
                    Financial Advisors
                  </Link>
                </div>
              )}
            </div>

            <Link 
              to="/find-an-advisor" 
              className={`${styles.navLink} ${isLinkActive('/find-an-advisor') ? styles.active : ''}`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Find an Advisor
            </Link>
            
            <Link 
              to="/partners" 
              className={`${styles.navLink} ${isLinkActive('/partners') ? styles.active : ''}`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Partners
            </Link>
            
            {/* <Link 
              to="/pricing" 
              className={`${styles.navLink} ${isLinkActive('/pricing') ? styles.active : ''}`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Pricing
            </Link> */}
            
            <Link 
              to="/finance-101" 
              className={`${styles.navLink} ${isLinkActive('/finance-101') ? styles.active : ''}`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Finance 101
            </Link>

            <div className={styles.mobileAuthButtons}>
              <button 
                onClick={() => {
                  setLoginModal(true);
                  setIsMobileMenuOpen(false);
                }}
              >
                Log In
              </button>
              <button 
                onClick={() => {
                  setSignupModal(true);
                  setIsMobileMenuOpen(false);
                }}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;