import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  BLUE_PLOOT,
  confirmPassword,
  DANGER_RED,
  sendAlert,
  SUCCESS_CODE,
} from "@plootus/common";
import QueryString from "query-string";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import a3 from "../../Assets/forgot-password/a3.svg";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import Button from "../../Utils/Buttons/Button";
import PasswordInput from "../../Utils/PasswordInput/PasswordInput";
import PasswordRow from "../SignUp/screens/PasswordRow";
import { notNull } from "../SignUp/utils";
import styles from "./ForgotAfterEmailScreen.module.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const ForgotAfterEmailScreen = (props) => {
  const tp = QueryString.parse(props.location.search);

  const { width, height } = useWindowDimensions();
  const [params, setParams] = useState({
    route: false,
    loading: false,
  });
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(true);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [validations, setValidations] = useState({
    validationArr: null,
    isMatched: null,
  });

  const [fields, setFields] = useState({
    password: "",
    confPassword: "",
  });

  const handleTextChange = (value, field) => {
    setFields((prevState) => ({ ...prevState, [field]: value }));

    if (field === "password") {
      const val_arr = [false, false, false, false];

      if (value.length >= 8) {
        val_arr[0] = true;
      }

      if (/(?=.*[A-Z])/.test(value)) {
        val_arr[1] = true;
      }

      if (/(?=.*\d)/.test(value)) {
        val_arr[2] = true;
      }

      if (/(?=.*[a-z])/.test(value)) {
        val_arr[3] = true;
      }

      setValidations({
        validationArr: val_arr,
        isMatched: notNull(isMatched) ? value === fields.confPassword : null,
      });
    } else {
      setValidations((prevState) => ({
        ...prevState,
        isMatched: value === fields.password,
      }));
    }
  };

  const { validationArr, isMatched } = validations;
  const { password, confPassword } = fields;
  const handlePress = async () => {
    let isInvalid = false;

    if (validationArr != null && isMatched !== null) {
      isInvalid = validationArr.includes(false) || !isMatched;
    } else {
      isInvalid = true;
      if (validationArr != null)
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
        }));
      else
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
          validationArr: Array(5).fill(false),
        }));
    }

    if (!isInvalid) {
      setParams((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const code = await dispatch(confirmPassword(tp.id, password, tp.vt));
      if (code === SUCCESS_CODE)
        dispatch(
          sendAlert({
            title: "Success",
            textContent: "Password was updated successfully!",
          })
        );
      else
        dispatch(
          sendAlert({
            title: "Error",
            textContent: "Password update failed",
          })
        );
      setParams({
        loading: false,
        route: true,
      });
      closeModal();
    }
  };

  if (params.route) return <Redirect to="/" />;

  return (
    <div style={{ height: "100vh" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.container}>
          <button onClick={closeModal} className={styles.closeButton}>
            <img src="/cross-thin.png" className={styles.crossButton} />
          </button>
          <div className={styles.header}>Forgot Password</div>
          <div className={styles.image}>
            <img src={a3} className={styles.innerImage} />
          </div>

          <div className={styles.content}>
            <p className={styles.t1}>Please enter a new password</p>
            <PasswordInput
              title="Set Password"
              textInputStyle={{
                borderRadius: "10px",
                height: width > 576 ? "50px" : "35px",
              }}
              titleStyle={{ fontSize: "14px" }}
              config={{
                onChangeText: (value) => handleTextChange(value, "password"),
                value: password,
              }}
              msg={null}
            />
            <PasswordInput
              title="Confirm Password"
              textInputStyle={{
                borderRadius: "10px",
                height: width > 576 ? "50px" : "35px",
              }}
              titleStyle={{ fontSize: "14px" }}
              containerStyle={{ marginTop: "2vh" }}
              config={{
                onChangeText: (value) =>
                  handleTextChange(value, "confPassword"),
                value: confPassword,
              }}
              msg={
                notNull(isMatched)
                  ? isMatched
                    ? "Passwords match"
                    : "Passwords don't match"
                  : null
              }
              msgStyle={{
                color: isMatched ? "#52da9c" : DANGER_RED,
                height: "3vh",
                fontSize: "12px",
              }}
            />
          </div>

          <div className={styles.inputBox}>
            <PasswordRow val_arr={validationArr} />
          </div>
          <div className={styles.btnDiv}>
            {params.loading ? (
              <MuiThemeProvider theme={theme}>
                <CircularProgress size={30} />
              </MuiThemeProvider>
            ) : (
              <Button
                text="Change Password"
                action={handlePress}
                myStyle={{ width: "200px" }}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ForgotAfterEmailScreen;
