"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDidUpdate = void 0;
var react_1 = require("react");
// Dont execute on first render just like component did update
var useDidUpdate = function (func, deps, didUpdate) {
    if (didUpdate === void 0) { didUpdate = true; }
    var didMount = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () {
        if (didUpdate) {
            if (didMount.current) {
                func();
            }
            else {
                didMount.current = true;
            }
        }
        else {
            func();
        }
    }, deps);
};
exports.useDidUpdate = useDidUpdate;
