import React, { useState } from "react";
import IncomeData from "./Income/IncomeData";
import ExpenseData from "./Expense/ExpenseData";
import CashFlow from "./Cashflow/CashFlowData";
import Tabs from "../../Utils/Tabs/Tabs";
import styles from "./IncomeExpTab.module.css";

// options for the Tab Bar
const options = [
  { id: 0, title: "Expenses" },
  { id: 1, title: "Income" },
  { id: 2, title: "Cash Flow" },
];

// function which switched the Tabs as per the index which is provided
const switchTab = (index) => {
  switch (index) {
    case 0:
      return <ExpenseData />;
    case 1:
      return <IncomeData />;
    case 2:
      return <CashFlow />;
    default:
      return <></>;
  }
};

const IncomeExpTab = (props) => {
  // index: which signifies which tab needs to be rendered
  const [index, setIndex] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div className={styles.left}>INCOME AND EXPENSES</div>
        <div className={styles.innerTabs}>
          <Tabs data={options} setContent={setIndex} />
        </div>
      </div>
      {/* Actual blocks  */}
      <div className={styles.timePeriod}>{switchTab(index)}</div>
    </div>
  );
};

export default IncomeExpTab;
