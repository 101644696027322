import { CircularProgress } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BLUE_PLOOT,
  getUser,
  GREY_1,
  isAdvisorSelector,
  updateProfile
} from '@plootus/common';
import React, { useRef, useState } from 'react';
import Avatar from 'react-avatar';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import useDateValidation from '../../../Reusables/VisualAndAccord/Hooks/useDateValidation';
import useEmployerSearch from '../../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';
import InputText from '../../../Utils/InputText/InputText';
import SearchBox401k from '../../../Utils/Search401kNew/SearchBox401kNew';
import MyText from '../../../Utils/Texts/MyText';
import { validate } from '../../SignUp/utils';
import AdvisorProfileModal from './AdvisorProfileModal';
import styles from './ProfileModal.module.css';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT } // Purple and green play nicely together.
  },
  typography: { useNextVariants: true }
});

const getInputConfig = (value = '') => ({
  value,
  err: { err: false, value: '' },
  typed: false
});

const getInitialState = (updateData) => [
  {
    firstName: getInputConfig(updateData.firstName),
    lastName: getInputConfig(updateData.lastName),
    mobileNo: getInputConfig(updateData.mobileNo),
    email: getInputConfig(updateData.email),
    zipcode: getInputConfig(updateData.zipcode),
    company: getInputConfig(updateData.company),
    sponsor_name: getInputConfig(updateData.sponsor_name),
    employerEin: getInputConfig(updateData.employerEin),
    advisorAddress: getInputConfig(updateData.advisorAddress)
  },
  {
    dob: updateData.dob,
    gender: updateData.gender,
    strategy: updateData.strategy
  }
];

const ProfileModal = ({ modalIsOpen, openModal, closeModal }) => {
  const user = useSelector((state) => state.userReducer.userData);
  const [isEditing, setIsEditing] = useState(true);
  const dispatch = useDispatch();

  const [updateData, setUpdateData] = useState({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    mobileNo: user.mobileNo,
    gender: user.gender,
    dob: user.dob,
    zipcode: user.zipcode,
    strategy: user.strategy,
    company: user.company,
    sponsor_name: user.sponsor_name,
    employerEin: user.employerEin,
    advisorAddress: user.advisorAddress
  });

  /* Date Validation */
  const { minDate, maxDate } = useDateValidation();

  const [initFields, initOtherFields] = getInitialState(updateData);
  const [fields, setFields] = useState(initFields);
  const [otherFields, setOtherFields] = useState(initOtherFields);
  const submitted = useRef(false);

  /* Function for validating TextInput Fields */
  const handleTextChange = (field, value) => {
    let newFields;

    if (submitted.current) {
      newFields = validate(field, value, fields, submitted.current);
    } else {
      newFields = { ...fields };
      const newField = { ...newFields[field], value };
      newFields[field] = newField;
    }
    setFields(newFields);
  };

  const {
    firstName,
    lastName,
    mobileNo,
    email,
    zipcode,
    company,
    advisorAddress,
    sponsor_name,
    employerEin
  } = fields;
  const { dob, gender, strategy } = otherFields;
  const [loading, setLoading] = useState(false);

  const props = useEmployerSearch(sponsor_name?.value);

  /* Function on pressing Next */
  const handlePress = async () => {
    submitted.current = true;

    let flag = false;
    const newFields = validate('all', '', fields, true);
    Object.keys(newFields).map((field) => {
      if (newFields[field].err.err) {
        flag = true;
      }
    });
    if (flag) {
      setFields(newFields);
    } else {
      setIsEditing(false);
      setLoading(true);
      setUpdateData((prevState) => ({
        ...prevState,
        firstName: fields.firstName?.value,
        lastName: fields.lastName?.value,
        mobileNo: fields.mobileNo?.value,
        email: fields.email?.value,
        zipcode: fields.zipcode?.value,
        sponsor_name: props.value === ' ' ? ' ' : fields.sponsor_name?.value,
        employerEin: props.value === '' ? null : fields.employerEin?.value,
        dob: otherFields.dob,
        gender: otherFields.gender,
        strategy: otherFields.strategy,
        company: otherFields.company,
        advisorAddress: fields.advisorAddress?.value
      }));
      //  console.log("hello");

      const code = await dispatch(
        updateProfile({
          dob,
          firstName: firstName?.value,
          lastName: lastName?.value,
          mobileNo: mobileNo?.value,
          zipcode: zipcode?.value,
          gender,
          strategy,
          sponsor_name: props.value === ' ' ? ' ' : sponsor_name?.value,
          employerEin: props.value === '' ? null : employerEin?.value,
          company: company?.value,
          advisorAddress: advisorAddress?.value
        })
      );
      await dispatch(getUser());
      setLoading(false);
    }
  };

  const handleEditPress = () => {
    setIsEditing(true);
  };

  const handleCancelPress = () => {
    setUpdateData((prev) => ({
      ...prev,
      employerEin: {
        ...prev.employerEin,
        value: initFields?.employerEin?.value
      },
      sponsor_name: {
        ...prev.sponsor_name,
        value: initFields?.sponsor_name?.value
      }
    }));

    props.setLocalData((prev) => ({
      ...prev,
      value: initFields?.sponsor_name?.value
    }));

    setFields(initFields);
    setOtherFields(initOtherFields);
    setIsEditing(false);
  };

  const state = useSelector((state) => state);

  return (
    <>
      <div
        className={styles.listItem}
        onClick={() => {
          openModal();
        }}
      >
        <img src='/a1.svg' className={styles.icon} />
        <div className={styles.text}>My Profile</div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.container}>
          <button onClick={() => closeModal()} className={styles.closeButton}>
            <img src='/cross-thin.png' />
          </button>
          <div className={styles.header}>
            <div className={styles.actualHead}>Profile Settings</div>
          </div>
          <div className={styles.image}>
            <div className={styles.avatar}>
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                size='80px'
                round='50%'
              />
            </div>
            <div className={styles.imgBtn}>
              {isEditing ? (
                <>
                  <button
                    className={styles.cancelBtn}
                    onClick={handleCancelPress}
                  >
                    Cancel
                  </button>
                  <button
                    className={styles.saveBtn}
                    onClick={handlePress}
                    disabled={loading}
                  >
                    Apply
                  </button>
                </>
              ) : loading ? (
                <MuiThemeProvider theme={theme}>
                  <CircularProgress size={30} />
                </MuiThemeProvider>
              ) : (
                <button className={styles.btn} onClick={handleEditPress}>
                  Edit Profile
                </button>
              )}
            </div>
          </div>
          <div className={styles.main}>
            {isAdvisorSelector(state) ? (
              <AdvisorProfileModal
                fields={fields}
                handleTextChange={handleTextChange}
                otherFields={otherFields}
                setOtherFields={setOtherFields}
                isEditing={isEditing}
              />
            ) : (
              <>
                <div className={styles.myRow}>
                  <div className={styles.innerRow}>
                    <InputText
                      type='text'
                      containerStyle={{ width: '45%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{ height: '28px', fontSize: '14px' }}
                      config={{
                        value: firstName?.value,
                        onChange: (e) =>
                          handleTextChange('firstName', e.target.value),
                        disabled: !isEditing
                      }}
                      title='First Name'
                      err={firstName?.err}
                      typed={firstName?.typed}
                    />
                    <InputText
                      type='text'
                      containerStyle={{ width: '45%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{ height: '28px', fontSize: '14px' }}
                      config={{
                        value: lastName?.value,
                        onChange: (e) =>
                          handleTextChange('lastName', e.target.value),
                        disabled: !isEditing
                      }}
                      title='Last Name'
                      err={lastName?.err}
                      typed={lastName?.typed}
                    />
                  </div>
                </div>
                <div className={styles.myRow}>
                  <div className={styles.innerRow}>
                    <InputText
                      type='text'
                      containerStyle={{ width: '95%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{ height: '28px', fontSize: '14px' }}
                      config={{
                        maxLength: 10,
                        value: mobileNo?.value,
                        onChange: (e) =>
                          handleTextChange('mobileNo', e.target.value),

                        disabled: !isEditing
                      }}
                      title='Phone Number'
                      err={mobileNo?.err}
                      typed={mobileNo?.typed}
                    />
                  </div>
                </div>
                <div className={styles.myRow}>
                  <div className={styles.innerRow}>
                    <InputText
                      type='text'
                      containerStyle={{ width: '95%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{ height: '28px', fontSize: '14px' }}
                      config={{
                        value: email?.value,
                        onChange: (e) =>
                          handleTextChange('email', e.target.value),
                        disabled: true
                      }}
                      title='Email ID'
                      err={email?.err}
                      typed={email?.typed}
                    />
                  </div>
                </div>
                <div className={styles.myRow}>
                  <div className={styles.innerRow}>
                    <InputText
                      type='date'
                      containerStyle={{ width: '45%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{ height: '28px', fontSize: '14px' }}
                      config={{
                        value: dob,
                        onChange: (e) =>
                          setOtherFields({
                            ...otherFields,
                            dob: e.target.value
                          }),

                        disabled: !isEditing,
                        min: minDate,
                        max: maxDate
                      }}
                      title='Date of Birth'
                      err={null}
                      typed={null}
                    />
                    <InputText
                      type='text'
                      containerStyle={{ width: '45%' }}
                      titleStyle={{ 'font-size': '15px', marginBottom: '5px' }}
                      textInputStyle={{
                        height: '28px',
                        fontSize: '14px',
                        backgroundColor: '#fff'
                      }}
                      config={{
                        maxLength: 5,
                        value: zipcode?.value,
                        onChange: (e) =>
                          handleTextChange('zipcode', e.target.value),
                        disabled: !isEditing
                      }}
                      title='Zip Code'
                      err={zipcode?.err}
                      typed={zipcode?.typed}
                    />
                  </div>
                </div>
                <div className={styles.myRow}>
                  <div style={{ marginLeft: '13px' }}>
                    <MyText>Strategy</MyText>
                  </div>

                  <div className={styles.innerRow}>
                    <select
                      className={styles.selectBox}
                      value={strategy}
                      disabled={!isEditing}
                      onChange={(e) => {
                        setOtherFields({
                          ...otherFields,
                          strategy: parseInt(e.target.value)
                        });
                      }}
                    >
                      <option className={styles.optionVal}>
                        Select Strategy
                      </option>
                      <option className={styles.optionVal} value={0}>
                        Super Conservative
                      </option>
                      <option className={styles.optionVal} value={1}>
                        Conservative
                      </option>
                      <option className={styles.optionVal} value={2}>
                        Moderate
                      </option>
                      <option className={styles.optionVal} value={3}>
                        Growth
                      </option>
                      <option className={styles.optionVal} value={4}>
                        Super Growth
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.searchRow}>
                  <div className={styles.searchDiv}>
                    <MyText
                      className={styles.title}
                      style={{
                        marginTop: '10px',
                        fontSize: '16px'
                      }}
                    >
                      Employer
                    </MyText>
                    <SearchBox401k
                      initVal={sponsor_name?.value}
                      setUpdateData={setFields}
                      style1={{ width: '100%' }}
                      myStyles={{
                        root: {
                          height: '35px',
                          fontSize: '13px'
                        },

                        innerDiv: {
                          textAlign: 'left',
                          padding: '10px 0px',
                          paddingLeft: '20px'
                        },

                        icon: {
                          fontSize: '25px',
                          color: !isEditing ? GREY_1 : '#416ce1'
                        },
                        inputStyle: { fontSize: '13px' }
                      }}
                      {...props}
                    />
                  </div>
                </div>
                <div className={styles.myRow}>
                  <MyText
                    style={{
                      marginLeft: '17px',
                      marginBottom: '5px',
                      marginTop: '20px'
                    }}
                  >
                    Gender
                  </MyText>
                  <div className={styles.actualRow}>
                    <div>
                      <input
                        name='gender'
                        type='radio'
                        value={0}
                        className={styles.radio}
                        disabled={!isEditing}
                        checked={gender === 0 ? true : false}
                        onChange={(e) =>
                          setOtherFields({
                            ...otherFields,
                            gender: parseInt(e.target.value)
                          })
                        }
                      />
                      <label className={styles.genderLabel}>Male</label>
                    </div>
                    <div>
                      <input
                        name='gender'
                        type='radio'
                        value={1}
                        className={styles.radio}
                        checked={gender === 1 ? true : false}
                        disabled={!isEditing}
                        onChange={(e) =>
                          setOtherFields({
                            ...otherFields,
                            gender: parseInt(e.target.value)
                          })
                        }
                      />
                      <label className={styles.genderLabel}>Female</label>
                    </div>
                    <div>
                      <input
                        name='gender'
                        type='radio'
                        value={2}
                        className={styles.radio}
                        checked={gender === 2 ? true : false}
                        disabled={!isEditing}
                        onChange={(e) =>
                          setOtherFields({
                            ...otherFields,
                            gender: parseInt(e.target.value)
                          })
                        }
                      />
                      <label className={styles.genderLabel}>Other</label>
                    </div>
                    <div>
                      <input
                        name='gender'
                        type='radio'
                        value={3}
                        className={styles.radio}
                        checked={gender === 3 ? true : false}
                        disabled={!isEditing}
                        onChange={(e) =>
                          setOtherFields({
                            ...otherFields,
                            gender: parseInt(e.target.value)
                          })
                        }
                      />
                      <label className={styles.genderLabel}>
                        Rather Not Say
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileModal;
