"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thumbsUpSelector = exports.homeScreenSelector = exports.editAssumtionsSelector = void 0;
var functions_1 = require("../../functions");
var constants_1 = require("../../constants");
var editAssumtionsSelector = function (_a) {
    var graphReducer = _a.graphReducer;
    return ({
        sliderOptions: graphReducer.sliderOptions,
        openingBalance: graphReducer.openingBalance,
        strategyIndex: graphReducer.strategyIndex,
        expense_home: graphReducer.expense_home,
        socialSecurity: graphReducer.socialSecurity,
    });
};
exports.editAssumtionsSelector = editAssumtionsSelector;
var homeScreenSelector = function (_a) {
    var graphReducer = _a.graphReducer, userReducer = _a.userReducer;
    return ({
        assets: graphReducer.graph.assets,
        retirementAssests: graphReducer.graph.results.retirementAssests,
        userData: userReducer.userData,
        token: userReducer.token,
    });
};
exports.homeScreenSelector = homeScreenSelector;
var thumbsUpSelector = function (_a) {
    var _b;
    var graphReducer = _a.graphReducer, userReducer = _a.userReducer;
    var strategyIndex = graphReducer.strategyIndex, sliderOptions = graphReducer.sliderOptions;
    var r_before = getStrategyBefore(strategyIndex);
    var r_after = getStrategyAfter(strategyIndex);
    var currentAge = (0, functions_1.getCurrentAge)(((_b = userReducer.userData) === null || _b === void 0 ? void 0 : _b.dob) || '1991-01-01');
    var lifeExpVal = constants_1.LIFE_EXP.VALUE;
    var retireAgeVal = constants_1.RETIRE_AGE.VALUE;
    var contributionVal = constants_1.CONTRIBUTION.VALUE;
    if (sliderOptions) {
        var lifeExp = sliderOptions.lifeExp, retireAge = sliderOptions.retireAge, contribution = sliderOptions.contribution;
        lifeExpVal = +lifeExp.value;
        retireAgeVal = +retireAge.value;
        contributionVal = +contribution.value;
    }
    var earnYears = retireAgeVal - currentAge;
    var retireYears = lifeExpVal - retireAgeVal - 1;
    var r_n_before = Math.pow(r_before, earnYears);
    var r_n_after = Math.pow(r_after, (retireYears - 1));
    // Max values
    var r_before_max = getStrategyBefore(3);
    var r_after_max = getStrategyAfter(3);
    var r_n_before_max = Math.pow(r_before_max, earnYears);
    var r_n_after_max = Math.pow(r_after_max, (retireYears - 1));
    return {
        openingBalance: graphReducer.openingBalance,
        strategyIndex: graphReducer.strategyIndex,
        expense_home: graphReducer.expense_home,
        contributionVal: contributionVal,
        currentAge: currentAge,
        r_before: r_before,
        r_before_max: r_before_max,
        r_after: r_after,
        r_after_max: r_after_max,
        r_n_before: r_n_before,
        r_n_before_max: r_n_before_max,
        r_n_after: r_n_after,
        r_n_after_max: r_n_after_max,
        earnYears: earnYears,
        retireYears: retireYears,
        retireAgeVal: retireAgeVal,
        lifeExpVal: lifeExpVal,
    };
};
exports.thumbsUpSelector = thumbsUpSelector;
var getStrategyBefore = function (index) {
    var _a;
    return ((_a = constants_1.strategyOptions[index]) === null || _a === void 0 ? void 0 : _a.value) + 1;
};
var getStrategyAfter = function (index) {
    var _a;
    return ((_a = constants_1.strategyOptions[index ? index - 1 : index]) === null || _a === void 0 ? void 0 : _a.value) + 1;
};
