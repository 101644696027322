"use strict";
/**
 ********************* NEEDS DOCUMENTATION *********************
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThumbsup = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var redux_1 = require("../redux");
var constants_1 = require("../constants");
var binomial_no_coeff = function (x, y, n) {
    if (n === 0) {
        return 1;
    }
    var total = 0;
    for (var i = 0; i <= n; ++i) {
        total += Math.pow(x, i) * Math.pow(y, n - i);
    }
    return total;
};
var useThumbsup = function () {
    var thumbsUpValues = (0, react_redux_1.useSelector)(redux_1.thumbsUpSelector);
    var expense_home = thumbsUpValues.expense_home, r_after = thumbsUpValues.r_after, r_before = thumbsUpValues.r_before, retireYears = thumbsUpValues.retireYears, openingBalance = thumbsUpValues.openingBalance, currentAge = thumbsUpValues.currentAge, contributionVal = thumbsUpValues.contributionVal, lifeExpVal = thumbsUpValues.lifeExpVal, earnYears = thumbsUpValues.earnYears, r_after_max = thumbsUpValues.r_after_max, r_before_max = thumbsUpValues.r_before_max;
    var _a = (0, react_1.useState)({
        values: null,
        loading: true,
    }), _b = _a[0], values = _b.values, loading = _b.loading, setValues = _a[1];
    var calculateContribution = function (maxRetire, maxStrategy) {
        var retireYearsVal = maxRetire
            ? lifeExpVal - constants_1.RETIRE_AGE.MAX - 1
            : retireYears;
        var earnYearsVal = maxRetire ? constants_1.RETIRE_AGE.MAX - currentAge : earnYears;
        var r_b = maxStrategy ? r_before_max : r_before;
        var r_a = maxStrategy ? r_after_max : r_after;
        var r_n_b = Math.pow(r_b, earnYearsVal);
        var r_n_a = Math.pow(r_a, retireYearsVal);
        var returnVal = {
            contribution: constants_1.CONTRIBUTION.MAX + 0.00001,
        };
        var contri = ((expense_home *
            // inflation *
            binomial_no_coeff(r_a, constants_1.inflation, retireYearsVal) -
            openingBalance * r_n_b * r_n_a) *
            (r_b - 1)) /
            (r_n_a * r_b * (r_n_b - 1));
        if (contri >= constants_1.CONTRIBUTION.MAX) {
            var retireMaxContri = !maxRetire
                ? calculateRetireAge(true).retireAge
                : constants_1.RETIRE_AGE.MAX;
            returnVal.contribution = constants_1.CONTRIBUTION.MAX;
            if (retireMaxContri >= constants_1.RETIRE_AGE.MAX) {
                var stratMaxRetire = !maxStrategy
                    ? calculateStrategy(true, true).strategy
                    : 3;
                returnVal.retireAge = constants_1.RETIRE_AGE.MAX;
                returnVal.strategy = stratMaxRetire;
            }
            else {
                returnVal.retireAge = retireMaxContri;
            }
        }
        else {
            returnVal.contribution = contri + 0.00001;
        }
        return returnVal;
    };
    var calculateRetireAge = function (maxContri, maxStrategy) {
        var contri = maxContri ? constants_1.CONTRIBUTION.MAX : contributionVal;
        var r_b = maxStrategy ? r_before_max : r_before;
        var r_a = maxStrategy ? r_after_max : r_after;
        var returnVal = {
            retireAge: constants_1.RETIRE_AGE.MAX,
        };
        for (var i = currentAge + 1; i < constants_1.LIFE_EXP.MAX; ++i) {
            var assets = (contri * r_b * (Math.pow(r_b, (i - currentAge)) - 1)) / (r_b - 1) +
                openingBalance * Math.pow(r_b, (i - currentAge));
            var LHS = assets * Math.pow(r_a, (lifeExpVal - i));
            var RHS = expense_home *
                // inflation *
                r_a *
                binomial_no_coeff(constants_1.inflation, r_a, lifeExpVal - i - 1);
            if (LHS > RHS) {
                returnVal.retireAge = i;
                break;
            }
        }
        if (returnVal.retireAge >= constants_1.RETIRE_AGE.MAX) {
            var contriMaxRetire = !maxContri
                ? calculateContribution(true, false).contribution
                : constants_1.CONTRIBUTION.MAX;
            returnVal.retireAge = constants_1.RETIRE_AGE.MAX;
            if (contriMaxRetire >= constants_1.CONTRIBUTION.MAX) {
                var stratMaxContri = !maxStrategy
                    ? calculateStrategy(true, true).strategy
                    : 3;
                returnVal.contribution = constants_1.CONTRIBUTION.MAX;
                returnVal.strategy = stratMaxContri;
            }
            else {
                returnVal.contribution = contriMaxRetire;
            }
        }
        return returnVal;
    };
    var calculateStrategy = function (maxContri, maxRetire) {
        var contri = maxContri ? constants_1.CONTRIBUTION.MAX : contributionVal;
        var retireYearsVal = maxRetire
            ? lifeExpVal - constants_1.RETIRE_AGE.MAX - 1
            : retireYears;
        var returnVal = { strategy: 3 }; // 3 -> Growth
        for (var i = 1; i <= 3; ++i) {
            var return_rate1 = 1 + +constants_1.strategyOptions[i].value;
            var return_rate2 = 1 + +constants_1.strategyOptions[i - 1].value;
            var assets = contri *
                return_rate1 *
                ((Math.pow(return_rate1, earnYears) - 1) / (return_rate1 - 1)) +
                openingBalance * Math.pow(return_rate1, earnYears);
            var LHS = assets * Math.pow(return_rate2, retireYearsVal);
            var RHS = expense_home *
                // inflation *
                return_rate2 *
                binomial_no_coeff(constants_1.inflation, return_rate2, retireYearsVal);
            if (LHS >= RHS) {
                returnVal.strategy = i;
                break;
            }
        }
        if (returnVal.strategy >= 3) {
            var contriMaxStrat = !maxContri
                ? calculateContribution(false, true).contribution
                : constants_1.CONTRIBUTION.MAX;
            returnVal.strategy = 3;
            if (contriMaxStrat >= constants_1.CONTRIBUTION.MAX) {
                var retireMaxContri = !maxRetire
                    ? calculateRetireAge(true, true).retireAge
                    : constants_1.RETIRE_AGE.MAX;
                returnVal.contribution = constants_1.CONTRIBUTION.MAX;
                returnVal.retireAge = retireMaxContri;
            }
            else {
                returnVal.contribution = contriMaxStrat;
            }
        }
        return returnVal;
    };
    // const isFocused = useIsFocused();
    (0, react_1.useEffect)(function () {
        setValues(function (prev) { return (__assign(__assign({}, prev), { loading: true })); });
        var updatedValues = __assign({}, values);
        var updatedContibution = __assign(__assign({}, updatedValues.contribution), calculateContribution());
        var updatedRetireAge = __assign(__assign({}, updatedValues.retireAge), calculateRetireAge());
        var updatedStrategy = __assign(__assign({}, updatedValues.strategy), calculateStrategy());
        updatedValues.contribution = updatedContibution;
        updatedValues.retireAge = updatedRetireAge;
        updatedValues.strategy = updatedStrategy;
        setValues(function (prev) { return (__assign(__assign({}, prev), { values: updatedValues, loading: false })); });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thumbsUpValues]);
    return { values: values, loading: loading };
};
exports.useThumbsup = useThumbsup;
