import {
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  roundNum,
  TEXT_BLACK_2,
  TEXT_BLACK_3,
  toTitleCase,
} from '@plootus/common';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import styles from './DropDownItem.module.css';

const DropDownItem = ({
  rightValue,
  leftTopValue,
  leftBottom,
  leftBottomKey,
  param4,
  rightBottom,
  first,
  last,
}) => {
  const amount = `$${formatCommaString(roundNum(rightValue, 0, 2))}`;

  return leftTopValue ? (
    <div
      className={styles.dropDownContainer}
      style={{
        borderBottom: last ? '0px' : '1px solid #ddd',
        borderTop: first ? '1px solid #ddd' : '0px',
        borderLeft: '0px',
        borderRight: '0px',
        backgroundColor: 'LIGHT_GREY',
        width: '100%',
      }}
    >
      <Row noGutters>
        <Col xs="1" className={styles.centerCol}></Col>
        <Col xs="7">
          <Row noGutters>
            <Col xs="12">
              <Row noGutters>
                <Col
                  xs="12"
                  className={styles.topLeft}
                  style={{ color: TEXT_BLACK_3 }}
                >
                  {toTitleCase(leftTopValue)}
                </Col>
                <Col
                  xs="12"
                  className={styles.bottomLeft}
                  style={{ color: TEXT_BLACK_2 }}
                >
                  {_.startCase(leftBottomKey)} : {leftBottom.toUpperCase()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs="4">
          <Row noGutters style={{ height: '100%' }}>
            <Col xs="8">
              <Row noGutters style={{ height: '100%' }}>
                <Col
                  xs="12"
                  className={styles.topRight}
                  style={
                    param4 === 'INCOME'
                      ? { color: DRAK_GREEN_PLOOT }
                      : { color: DARK_RED_PLOOT }
                  }
                >
                  {amount.slice(0, -2)}
                  <span className={styles.afterDecimal}>
                    {amount.slice(-2)}
                  </span>
                </Col>
                <Col
                  xs="12"
                  className={styles.bottomRight}
                  style={{ color: TEXT_BLACK_2 }}
                >
                  {rightBottom && moment(rightBottom).format('DD MMM YYYY')}
                </Col>
              </Row>
            </Col>
            <Col xs="4"></Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : null;
};
export default DropDownItem;
