"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feesSavedDefault = exports.inflation = exports.monthNames = void 0;
exports.monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
exports.inflation = 1.02;
exports.feesSavedDefault = [
    {
        data: [
            // super conservatiev
            140.174999999999,
            291.108375000003,
            503.819386874995,
            784.792398234371,
            1141.00788819641,
            1579.9753756757,
            2109.76836176129,
            2739.06140907486,
            3477.16948265725,
            4334.08968395932,
            5320.54551692685,
            6448.03383299272,
            7728.87461004217,
            9176.26372912491,
            10804.3289218725,
            12628.1890712697,
            14664.0170586491,
            16929.1063605601,
            19441.9416105344,
            22222.273352764,
            25291.1972273571,
            28671.2378401778,
            32386.4375843435,
            36462.450695291,
            40926.6428369646,
            45808.1965331787,
            51138.2227755989,
            56949.8791581271,
            63278.4949068138,
        ],
        total: 63278.4949068138,
    },
    {
        data: [
            // conservative
            140.355,
            291.858164999994,
            505.771475714995,
            788.858350035524,
            1148.41835557112,
            1592.32414071415,
            2129.06071916953,
            2767.76725083721,
            3518.28147033033,
            4391.18692329197,
            5397.8631800611,
            6550.53920616518,
            7862.35007961188,
            9347.39725604779,
            11020.8125945808,
            12898.826369464,
            14998.8395059431,
            17339.5002924275,
            19940.7858357852,
            22824.0885420396,
            26012.307921106,
            29529.9480314881,
            33403.2208991274,
            37660.1562639005,
            42330.7180276595,
            47446.9277992676,
            53042.9959548542,
            59155.4606555802,
            65823.3352906279,
        ],
        total: 65823.3352906279,
    },
    {
        data: [
            // moderate
            141.948,
            298.540879649998,
            523.294075043632,
            825.617250132716,
            1215.8964543699,
            1705.5832972284,
            2307.29171231965,
            3034.90292916291,
            3903.67931950494,
            4930.3878377084,
            6133.43383141371,
            7533.00605973927,
            9151.23382205042,
            11012.3571711571,
            13142.9112610766,
            15571.925961635,
            18331.1419606183,
            21455.2446694085,
            24982.1173505497,
            28953.1149960465,
            33413.3606039907,
            38412.0656289726,
            44002.8765193544,
            50244.2494025902,
            57199.8551391608,
            64939.017137209,
            73537.1845045066,
            83076.4433129552,
            93646.068964453,
        ],
        total: 93646.068964453,
    },
    {
        data: [
            // growth
            143.451000000001,
            304.923594600001,
            540.236765428912,
            861.600418079048,
            1282.77306422609,
            1819.23618798715,
            2488.38698730976,
            3309.75134756314,
            4305.21908690877,
            5499.30371297899,
            6919.42914955207,
            8596.24613209622,
            10563.9812342501,
            12860.8217756859,
            15529.3401777381,
            18616.9616792682,
            22176.4797043223,
            26266.6235883096,
            30952.6838240924,
            36307.2004872268,
            42410.7210446832,
            49352.6343481271,
            57232.088266099,
            66158.9991244681,
            76255.1619071304,
            87655.4710253868,
            100509.262401649,
            114981.788638624,
            131255.840167125,
        ],
        total: 131255.840167125,
    },
    {
        data: [
            // super growth
            144.567000000003,
            309.711569400002,
            553.077576867952,
            889.155091187524,
            1334.51915765007,
            1908.09746072191,
            2631.47022378723,
            3529.20607443264,
            4629.23802308962,
            5963.28416841527,
            7567.31825958367,
            9482.0958192327,
            11753.742167309,
            14434.4093922836,
            17583.0100997019,
            21266.0366371196,
            25558.4754583892,
            30544.8273592039,
            36320.2455010774,
            42991.8044550328,
            50679.914953029,
            59519.9006498454,
            69663.7549877248,
            81282.0982392279,
            94566.357001198,
            109731.190847363,
            127017.193544222,
            146693.899222466,
            169063.127205265,
        ],
        total: 169063.127205265,
    },
];
