import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import { 
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon 
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6)
  },
  navigationButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8
    }
  },
  navigationButtonDisabled: {
    color: '#94A3B8'
  },
  navigationButtonEnabled: {
    color: '#1E293B'
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  pageButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    color: '#64748B',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(67, 97, 238, 0.1)'
    }
  },
  activePageButton: {
    color: 'white',
    backgroundColor: '#4361EE',
    '&:hover': {
      backgroundColor: '#4361EE'
    }
  }
}));

const BlogPagination = ({ currentPage, totalPages, onPageChange }) => {
  const classes = useStyles();

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className={classes.root}>
      <Button
        startIcon={<ArrowBackIcon />}
        disabled={currentPage === 1}
        onClick={handlePrevious}
        className={`${classes.navigationButton} ${
          currentPage === 1 
            ? classes.navigationButtonDisabled 
            : classes.navigationButtonEnabled
        }`}
        disableRipple
      >
        Previous
      </Button>

      <div className={classes.paginationContainer}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <IconButton
            key={page}
            onClick={() => onPageChange(page)}
            className={`${classes.pageButton} ${
              page === currentPage ? classes.activePageButton : ''
            }`}
          >
            {page}
          </IconButton>
        ))}
      </div>

      <Button
        endIcon={<ArrowForwardIcon />}
        disabled={currentPage === totalPages}
        onClick={handleNext}
        className={`${classes.navigationButton} ${
          currentPage === totalPages 
            ? classes.navigationButtonDisabled 
            : classes.navigationButtonEnabled
        }`}
        disableRipple
      >
        Next
      </Button>
    </div>
  );
};

export default BlogPagination;