export default function dateValidator() {
  /* Date of Birth Validation */
  let d = new Date();
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  let yyyy = d.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  const minDate = `${yyyy - 200}-${mm}-${dd}`;
  const maxDate = `${yyyy - 13}-${mm}-${dd}`;

  return { minDate, maxDate };
}
