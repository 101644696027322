import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import { Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import MainModal from "./components/AdvisorRegister/MainModal";
import AuthPages from "./components/AuthPages/AuthPages";
import PrivateRoute from "./components/AuthPages/PrivateRoute";
import PublicRoute from "./components/AuthPages/PublicRoute";
import DialogAlert from "./components/DialogAlert/DialogAlert";
import ForgotAfterEmailScreen from "./components/ForgotPasswordNew/ForgotAfterEmailScreen";
import StaticPages from "./components/StaticPages/StaticPages";
import AppBanner from "./Utils/AppBanner/AppBanner";
import AdminPages from "./components/AdminDashboard/AdminPages";
import ConfirmUnsubscribe from "./components/unsubscribe/ConfirmUnsubscribe";
import Unsubscribe from "./components/unsubscribe/Unsubscribe";
import { SendAlert, store } from "@plootus/common";
import Loader from "./components/Loader";

Modal.setAppElement("#root");

// RouteWrapper component to handle scroll before rendering
const RouteWrapper = ({ component: Component, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Reset states on mount
    setIsReady(false);
    setIsLoading(true);

    // Scroll to top
    window.scrollTo(0, 0);
    
    // First delay to ensure scroll completes
    const scrollTimer = setTimeout(() => {
      setIsReady(true);
      
      // Second delay to simulate minimal loading time and prevent flashing
      const loadingTimer = setTimeout(() => {
        setIsLoading(false);
      }, 300); // Adjust this time as needed

      return () => clearTimeout(loadingTimer);
    }, 100);

    return () => clearTimeout(scrollTimer);
  }, []);

  if (!isReady || isLoading) {
    return <Loader />;
  }

  return <Component {...rest} />;
};

function App(props) {
  const location = useLocation();
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  // Handle route changes
  useEffect(() => {
    setIsRouteChanging(true);
    window.scrollTo(0, 0);
    
    // Reset route change state after a delay
    const timer = setTimeout(() => {
      setIsRouteChanging(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <div className="App">
      {isRouteChanging && <Loader />}
      <SendAlert render={(props) => <DialogAlert {...props} />} />
      {isMobile && <AppBanner />}
      <Switch>
        <PrivateRoute 
          path="/auth" 
          Component={(props) => <RouteWrapper component={AuthPages} {...props} />} 
        />
        {/* // Developed and managed by Mejona technology */}

        <Route
          path="/forgot"
          component={(props) => (
            <RouteWrapper component={ForgotAfterEmailScreen} {...props} />
          )}
        />
        <Route 
          path="/confirm-unsubscribe" 
          component={(props) => (
            <RouteWrapper component={ConfirmUnsubscribe} {...props} />
          )}
        />
        <Route 
          path="/unsubscribe" 
          component={(props) => (
            <RouteWrapper component={Unsubscribe} {...props} />
          )}
        />
        <Route
          path="/advisor"
          component={(props) => (
            <RouteWrapper component={MainModal} {...props} />
          )}
        />
        <Route 
          path="/admin" 
          component={(props) => (
            <RouteWrapper component={AdminPages} {...props} />
          )}
        />
       
        <PublicRoute 
          path="/" 
          Component={(props) => (
            <RouteWrapper component={StaticPages} {...props} />
          )}
        />
      </Switch>
    </div>
  );
}

export default App;