import {
  AppState,
  get_income,
  get_income_transactions,
  INCOMEXP_SCREEN,
  INCOME_API,
  HIGHLIGHT_PIE,
  IncomeScreenType,
} from "@plootus/common";
import React from "react";
import { connect } from "react-redux";
import VisualData from "../../../Reusables/VisualAndAccord/Container/VisualData";

const IncomeData = (props) => {
  return (
    <VisualData
      api_name={INCOME_API}
      screen={INCOMEXP_SCREEN}
      dispatchAction={get_income}
      dispatchTransaction={get_income_transactions}
      title="Total Incomes"
      type="pie"
      _key="cat_id"
      actionType={HIGHLIGHT_PIE}
      _key_accord="id"
      {...props}
    />
  );
};

export default connect(({ portfolioReducer: { incomexp } }) => ({
  income_data: incomexp.income_data,
  income_pie: incomexp.income_pie,
  income_total: incomexp.income_total,
  income_trans_data: incomexp.income_trans_data,
}))(IncomeData);
