import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  LIGHT_GREY,
  roundNum,
  toTitleCase,
} from '@plootus/common';
import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'reactstrap';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import styles from './CanExpandNested.module.css';

const CanExpandItemNested = ({
  index,
  rightValue,
  leftValue,
  leftBottom,
  color,
  renderItem,
  data,
  selected,
  dispatchAction,
  value,
  expandSearched,
  valueColor,
  paddingLeft,
  textStyle,
  circleSize = 10,
  first,
  last,
  fontSize,
  level,
}) => {
  const amount = `$${formatCommaString(roundNum(rightValue, 0, 2))}`;
  let lastEle = data.length - 1;
  let bgColor = index % 2 ? LIGHT_GREY : 'white';
  const { width } = useWindowDimensions();
  return (
    <div>
      <div
        className={styles.expandHeader}
        onClick={() => {
          if (value) {
            expandSearched(index);
          } else {
            dispatchAction(index);
          }
        }}
        style={{
          backgroundColor: 'white',
          paddingLeft: paddingLeft,
          fontSize: fontSize,
          borderTop: level === 1 && first ? '1px solid #ddd' : '0px',
          borderBottom: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          height: '8vh',
          borderTopLeftRadius:
            first && level === 1 && width > 576 ? '10px' : '0px',
          borderTopRightRadius:
            first && level === 1 && width > 576 ? '10px' : '0px',
          borderBottomLeftRadius:
            last && level === 1 && !selected && width > 576 ? '10px' : '0px',
          borderBottomRightRadius:
            last && level === 1 && !selected && width > 576 ? '10px' : '0px',
        }}
      >
        <Row noGutters style={{ height: '100%' }}>
          <Col xs="1" className={styles.centerCol} style={{ height: '100%' }}>
            <div
              className={styles.circle}
              style={{
                backgroundColor: color,
                height: circleSize,
                width: circleSize,
                borderRadius: circleSize / 2,
              }}
            ></div>
          </Col>
          <Col xs="7" className={styles.left} style={{ height: '100%' }}>
            <Row noGutters>
              <Col
                xs="12"
                className={styles.topLeft}
                style={{
                  height: leftBottom
                    ? width > 576
                      ? '4vh'
                      : '4vh'
                    : width > 576
                    ? '8vh'
                    : '8vh',
                  maxHeight: leftBottom ? '35px' : '70px',
                }}
              >
                <Row noGutters>
                  <Col xs="12" style={{ fontSize: fontSize + 1 }}>
                    {toTitleCase(_.startCase(leftValue))}
                  </Col>
                </Row>
              </Col>
              {leftBottom ? (
                <Col
                  xs="12"
                  className={styles.bottomLeft}
                  style={{
                    fontSize: fontSize - 2,
                    color: '#919191',
                    paddingLeft: '10px',
                  }}
                >
                  {leftBottom.split(' ')[0]}
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            xs="4"
            className={styles.right}
            style={{ height: '8vh', maxHeight: '70px' }}
          >
            <Row noGutters>
              <Col
                xs="9"
                className={styles.moneyLeft}
                style={
                  valueColor === 'INCOME'
                    ? {
                        color: DRAK_GREEN_PLOOT,
                        fontSize: fontSize + 1,
                        height: '8vh',
                        maxHeight: '70px',
                      }
                    : {
                        color: DARK_RED_PLOOT,
                        fontSize: fontSize + 1,
                        height: '8vh',
                        maxHeight: '70px',
                      }
                }
              >
                {amount.slice(0, -2)}
                <span style={{ fontSize: fontSize - 5, paddingTop: '3px' }}>
                  {amount.slice(-2)}
                </span>
              </Col>
              <Col xs="3" className={styles.dropIcon}>
                {selected ? (
                  <KeyboardArrowUpIcon
                    fontSize={width > 576 ? 'large' : 'default'}
                    color="#404a57"
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize={width > 576 ? 'large' : 'default'}
                    color="#404a57"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {selected ? (
        <div
          className={styles.expandedItems}
          style={{
            borderBottom: level === 3 ? '2px solid #ddd' : '0px',
          }}
        >
          <div className={styles.expandedContainer}>
            {data.map((item, index) => {
              let first = index === 0;
              let last = index === lastEle;
              let childColor = bgColor === LIGHT_GREY ? 'white' : LIGHT_GREY;
              return renderItem(item, index, first, last, childColor);
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CanExpandItemNested;
