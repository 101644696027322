import React from 'react';
import { getColorFromType, roundNum } from '@plootus/common';
import CanExpandItem from '../../AccordItem/CanExpandItem';
import CannotExpandItem from '../../AccordItem/CannotExpandItem';
import styles from './AccordPart.module.css';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import CannotExpandTable from '../../AccordItem/CannotExpandTable';
import DataNotAvailable from '../../../../Utils/Texts/DataNotAvailable';

const AccordPart = ({
  HeaderPart,
  data,
  canExpand,
  expandSearchedAccord,
  mappingData,
  highlight_pie,
  api_name,
  ...rest
}) => {
  const { level1, metaData } = mappingData;
  const { width } = useWindowDimensions();
  if (!data?.length) return <DataNotAvailable box />;

  return (
    <div className={styles.wrapper}>
      {data.map((item, index) => {
        let flag;
        const first = index === 0;
        const last = index === data.length - 1;
        if (canExpand) {
          flag =
            item[metaData.transactionParams.param5] ===
            data[index - 1]?.[metaData.transactionParams.param5];
        }
        return canExpand ? (
          width > 1024 ? (
            <CannotExpandTable
              valueColor={item[metaData.transactionParams.param3]}
              rightValue={item[metaData.transactionParams.param1]}
              leftTop={item[metaData.transactionParams.param2]}
              leftBottomKey={metaData.transactionParams.param4}
              leftBottomValue={item[metaData.transactionParams.param4]}
              index={index}
              rightBottom={item[metaData.transactionParams.param5]}
              shouldShowDate={!flag}
              first={first}
              last={last}
            />
          ) : (
            <CannotExpandItem
              valueColor={item[metaData.transactionParams.param3]}
              rightValue={item[metaData.transactionParams.param1]}
              leftTop={item[metaData.transactionParams.param2]}
              leftBottomKey={metaData.transactionParams.param4}
              leftBottomValue={item[metaData.transactionParams.param4]}
              index={index}
              rightBottom={item[metaData.transactionParams.param5]}
              shouldShowDate={!flag}
              first={first}
              last={last}
            />
          )
        ) : (
          <CanExpandItem
            {...rest}
            dispatchAction={highlight_pie}
            valueColor={item[level1.param3]}
            rightValue={roundNum(item[level1.param1], 0, 2)}
            leftValue={item[level1.param2]}
            color={
              item.color
                ? item.color
                : getColorFromType(item[level1.param3]).slice(1, 7)
            }
            data={item[level1.param5]}
            selected={item.selected}
            expandSearched={(idx) => expandSearchedAccord(idx)}
            index={index}
            mappingData={mappingData}
            first={first}
            last={last}
            api_name={api_name}
          />
        );
      })}
    </div>
  );
};

export default AccordPart;
