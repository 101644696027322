import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F9FAFB',
    overflow: 'hidden',
    position: 'relative'
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8),
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(12)
    }
  },
  leftContent: {
    flex: '1',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '40%'
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  titleText: {
    fontSize: '2rem',
    fontWeight: 500,
    color: '#1E293B'
  },
  titleHighlight: {
    fontSize: '2rem',
    fontWeight: 500,
    color: '#4361EE'
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 600,
    color: '#1E293B',
    lineHeight: 1.2,
    marginBottom: theme.spacing(4),
    letterSpacing: '-0.02em',
    [theme.breakpoints.up('md')]: {
      fontSize: '3.5rem'
    }
  },
  description: {
    color: '#64748B',
    fontSize: '1.125rem',
    lineHeight: 1.7,
    marginBottom: theme.spacing(6),
    maxWidth: '95%'
  },
  appButtons: {
    display: 'flex',
    gap: theme.spacing(3)
  },
  appImage: {
    height: 48,
    width: 'auto'
  },
  rightContent: {
    flex: '1',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%'
    }
  },
  gridPattern: {
    position: 'absolute',
    top: '-10%',
    right: '-20%',
    bottom: '-10%',
    left: '-20%',
    backgroundImage: `url('/images/grid-pattern.png')`,
    backgroundSize: '40px 25px',
    backgroundRepeat: 'repeat',
    opacity: 0.5,
    zIndex: 0
  },
  heroImage: {
    width: '100%',
    height: 'auto',
    display: 'block',
    position: 'relative',
    zIndex: 2,
    filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.12))',
    marginBottom: '-115px',
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.1)'
    }
  },
  gradientBlue: {
    position: 'absolute',
    top: '10%',
    right: '-20%',
    width: 300,
    height: 300,
    background: 'radial-gradient(circle, rgba(67, 97, 238, 0.08) 0%, rgba(67, 97, 238, 0) 70%)',
    borderRadius: '50%',
    filter: 'blur(40px)',
    zIndex: 1
  },
  gradientGreen: {
    position: 'absolute',
    bottom: '10%',
    left: '-20%',
    width: 300,
    height: 300,
    background: 'radial-gradient(circle, rgba(54, 179, 126, 0.08) 0%, rgba(54, 179, 126, 0) 70%)',
    borderRadius: '50%',
    filter: 'blur(40px)',
    zIndex: 1
  }
}));

const HeroSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.contentWrapper}>
          {/* Left Content */}
          <div className={classes.leftContent}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className={classes.titleWrapper}>
                <Typography className={classes.titleText}>
                  Plootus for
                </Typography>
                <Typography className={classes.titleHighlight}>
                  Financial Advisors
                </Typography>
              </div>
              
              <Typography variant="h1" className={classes.mainTitle}>
                Your Trusted Partner for Client Financial Planning
              </Typography>

              <Typography className={classes.description}>
                Streamline retirement planning with easy-to-use tools that your clients will engage with! 
                Plootus helps you deliver smarter, more effective advisory services.
              </Typography>

              <div className={classes.appButtons}>
                <motion.a
                  href="#"
                  whileHover={{ y: -2 }}
                  transition={{ duration: 0.2 }}
                  style={{ display: 'block' }}
                >
                  <img
                    src="/images/app-store-badge.png"
                    alt="Download on App Store"
                    className={classes.appImage}
                  />
                </motion.a>
                <motion.a
                  href="#"
                  whileHover={{ y: -2 }}
                  transition={{ duration: 0.2 }}
                  style={{ display: 'block' }}
                >
                  {/* <img
                    src="/images/google-play-png-logo-3802.png"
                    alt="Get it on Google Play"
                    className={classes.appImage}
                  /> */}
                </motion.a>
              </div>
            </motion.div>
          </div>

          {/* Right iPhone Mockup */}
          <div className={classes.rightContent}>
            <div className={classes.gridPattern} />

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              style={{
                position: 'relative',
                width: '100%',
                maxWidth: '600px'
              }}
            >
              <img
                src="/images/advisor-dashboard-iphone.png"
                alt="Financial Advisor Dashboard"
                className={classes.heroImage}
              />

              {/* Subtle Gradient Effects */}
              <div className={classes.gradientBlue} />
              <div className={classes.gradientGreen} />
            </motion.div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;