import React from "react";
import styles from "./TableRow.module.css";
import { roundNum } from "@plootus/common";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

export const getStratData = (strategy, item) => {
  // console.log(strategy, "new strat");
  switch (strategy) {
    case 0:
      return item.proposed_5;
    case 1:
      return item.proposed_1;
    case 2:
      return item.proposed_2;
    case 3:
      return item.proposed_3;
    case 4:
      return item.proposed_4;
    default:
      return undefined;
  }
};

const TableRow = ({ data, index, strategy }) => {
  const color = index % 2 ? "#f2f2f2" : "#fff";
  // console.log(data, "table data");
  return (
    <div className={styles.container} style={{ backgroundColor: color }}>
      <div className={styles.srno}>{index + 1}.</div>
      <div className={styles.name}>{data.ticker_name}</div>
      <div className={styles.ticker}>{data.ticker}</div>
      <div className={styles.strat}>
        {`${roundNum(getStratData(strategy, data), 1, 1).slice(0, -2)}`}
        {`${roundNum(getStratData(strategy, data), 1, 1).slice(-2)} %`}
      </div>
      <div className={styles.rating}>
        {data.rating ? (
          <Rating
            name="customized-empty"
            value={data.rating}
            precision={0.1}
            readOnly={true}
            emptyIcon={
              <StarBorderIcon fontSize="inherit" style={{ color: "#fcce41" }} />
            }
            style={{ color: "#fcce41" }}
            size="small"
          />
        ) : (
          "--"
        )}
      </div>
    </div>
  );
};

export default TableRow;
