"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetGeneralStore = exports.generalFutureExpTable = exports.generalEmployerNew = exports.generalEmployer = exports.resetFutureExpenses = exports.saveFutureExpenses = exports.get_future_expense_planner = exports.get_fees_saved = exports.fetch_allocations = exports.get_employer_list_beg = exports.get_employer_list = void 0;
var selectors_1 = require("../user/selectors");
var helperfunctions_1 = require("./../Utils/helperfunctions");
var types_1 = require("./types");
var constants_1 = require("../../constants");
var functions_1 = require("../../functions");
var actions_1 = require("../global/actions");
var get_employer_list = function (value) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, employees;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('employee/getemployee', { ent_key: value }, [getState, dispatch], 'get', false)];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                employees = responseData.employees;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch);
                return [2 /*return*/, employees.slice(0, 10)];
        }
    });
}); }; };
exports.get_employer_list = get_employer_list;
// Remove later
var get_employer_list_beg = function (value) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, employees;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('employee/getemployee', { ent_key: value }, [getState, dispatch], 'get')];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                employees = responseData.employees;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, (0, exports.generalEmployer)({
                    employer_list: employees === null || employees === void 0 ? void 0 : employees.splice(0, 10),
                    search_loading: false,
                }));
                return [2 /*return*/];
        }
    });
}); }; };
exports.get_employer_list_beg = get_employer_list_beg;
var fetch_allocations = function (ent_params, newEmp) {
    if (newEmp === void 0) { newEmp = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var isToken, _a, responseData, errorCode, allocations;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    isToken = getState().userReducer.token;
                    return [4 /*yield*/, (0, helperfunctions_1.async_func_data)(isToken ? 'employee/getallocation' : 'employee/getallocationunauth', { ein: String(ent_params.ein) }, [getState, dispatch], 'get', isToken ? true : false)];
                case 1:
                    _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                    allocations = responseData.allocations;
                    (0, helperfunctions_1.handleResponse)(ent_params.showLimitExceedError ? errorCode : null, dispatch, !newEmp
                        ? (0, exports.generalEmployer)({
                            funds: allocations || [],
                            employer_list: [],
                            value: ent_params.name,
                            showFunds: true,
                            funds_load: false,
                            employer_selected: true,
                        })
                        : (0, exports.generalEmployerNew)({
                            employer_selected: ent_params.name,
                            funds: allocations || [],
                            selected_ein: +ent_params.ein,
                        }));
                    return [2 /*return*/];
            }
        });
    }); };
};
exports.fetch_allocations = fetch_allocations;
var get_fees_saved = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, responseData, errorCode, fees;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('employee/getfeessaved', { ein: "".concat(getState().generalReducer.employerNew.selected_ein) }, [getState, dispatch], 'get')];
            case 1:
                _a = _b.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                fees = responseData.fees;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch);
                return [2 /*return*/, (fees === null || fees === void 0 ? void 0 : fees.length) ? fees[0] : []];
        }
    });
}); }; };
exports.get_fees_saved = get_fees_saved;
var get_future_expense_planner = function (retireDate) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var isAdvisor, userId, dob, _a, responseData, errorCode, futureExpenses, socialSecurity;
    var _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                isAdvisor = (0, selectors_1.isAdvisorSelector)(getState());
                userId = isAdvisor
                    ? (_b = (0, selectors_1.currentClientSelector)(getState())) === null || _b === void 0 ? void 0 : _b.uuid
                    : (_c = getState().userReducer.userData) === null || _c === void 0 ? void 0 : _c.uuid;
                dob = isAdvisor
                    ? ((_d = (0, selectors_1.currentClientSelector)(getState())) === null || _d === void 0 ? void 0 : _d.dob) || '1993-01-01'
                    : ((_e = getState().userReducer.userData) === null || _e === void 0 ? void 0 : _e.dob) || '1993-01-01';
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('transaction/getFutureExpenses', { retireDate: "".concat(retireDate, "-01-01"), userId: userId, dob: dob || '1993-01-01', }, [getState, dispatch], 'get')];
            case 1:
                _a = _f.sent(), responseData = _a.responseData, errorCode = _a.errorCode;
                futureExpenses = responseData.futureExpenses, socialSecurity = responseData.socialSecurity;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null);
                return [2 /*return*/, { futureExpenses: futureExpenses, socialSecurity: socialSecurity }];
        }
    });
}); }; };
exports.get_future_expense_planner = get_future_expense_planner;
var saveFutureExpenses = function (listData) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var userId, sendResonse, errorCode;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                userId = (_a = getState().userReducer.userData) === null || _a === void 0 ? void 0 : _a.uuid;
                sendResonse = [];
                listData.map(function (item) {
                    if (item.new_future) {
                        sendResonse.push({
                            userId: userId,
                            category: item.category,
                            futureExpense: (0, functions_1.removeCommaString)(item.new_future),
                        });
                    }
                });
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('transaction/editFutureExpenses', { data: sendResonse }, [getState, dispatch], 'post')];
            case 1:
                errorCode = (_b.sent()).errorCode;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null);
                if (errorCode !== constants_1.SUCCESS_CODE) {
                    dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Retirement expenses update failed.' }));
                    return [2 /*return*/, errorCode];
                }
                else {
                    return [2 /*return*/, constants_1.SUCCESS_CODE];
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.saveFutureExpenses = saveFutureExpenses;
var resetFutureExpenses = function (category) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var userId, errorCode;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                userId = (_a = getState().userReducer.userData) === null || _a === void 0 ? void 0 : _a.uuid;
                return [4 /*yield*/, (0, helperfunctions_1.async_func_data)('transaction/deleteFutureExpenses', { userId: userId, category: category }, [getState, dispatch], 'delete')];
            case 1:
                errorCode = (_b.sent()).errorCode;
                (0, helperfunctions_1.handleResponse)(errorCode, dispatch, null);
                if (errorCode !== constants_1.SUCCESS_CODE) {
                    dispatch((0, actions_1.sendAlert)({ title: 'Error', textContent: 'Reset Expenses failed.' }));
                    return [2 /*return*/, errorCode];
                }
                else {
                    return [2 /*return*/, constants_1.SUCCESS_CODE];
                }
                return [2 /*return*/];
        }
    });
}); }; };
exports.resetFutureExpenses = resetFutureExpenses;
var generalEmployer = function (payload) { return ({
    type: types_1.GENERAL_EMPLOYER,
    payload: payload,
}); };
exports.generalEmployer = generalEmployer;
var generalEmployerNew = function (payload) { return ({
    payload: payload,
    type: types_1.GENERAL_EMPLOYER_NEW,
}); };
exports.generalEmployerNew = generalEmployerNew;
var generalFutureExpTable = function (payload) { return ({
    type: types_1.GENERAL_FUTURE_EXPENSES_TABLE,
    payload: payload,
}); };
exports.generalFutureExpTable = generalFutureExpTable;
var resetGeneralStore = function () { return ({
    type: types_1.RESET_GENERAL,
}); };
exports.resetGeneralStore = resetGeneralStore;
