"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generalGraphResultsActions = exports.generalGraphActions = void 0;
var types_1 = require("./types");
var generalGraphActions = function (payload) { return ({ type: types_1.GENERAL_GRAPH, payload: payload }); };
exports.generalGraphActions = generalGraphActions;
var generalGraphResultsActions = function (payload) { return ({
    type: types_1.GRAPH_GRAPH_RESULTS,
    payload: payload,
}); };
exports.generalGraphResultsActions = generalGraphResultsActions;
