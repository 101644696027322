/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
import { validate, notNull } from './validate';
import PasswordInput from '../../../Utils/PasswordInput/PasswordInput';
import InputText from '../../../Utils/InputText/InputText';
import Modal from 'react-modal';
import styles from './Register.module.css';
import {
  DANGER_RED,
  user_signup,
  TEXT_BLACK,
  BLUE_PLOOT,
} from '@plootus/common';
import { useDispatch } from 'react-redux';
import QueryString from 'query-string';
import PasswordRow from '../../SignUp/screens/PasswordRow';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const getInputConfig = (value = '') => ({
  value,
  err: { err: false, value: '' },
  typed: false,
});

const getInitialState = (signupData) => [
  {
    firstName: getInputConfig(signupData.name),
    lastName: getInputConfig(signupData.name),
    mobileNo: getInputConfig(signupData.mobileNo),
    email: getInputConfig(signupData.email),
    company: getInputConfig(signupData.company),
    // address: getInputConfig(signupData.address),
  },
];

const Register = (props) => {
  const tp = QueryString.parse(props.location.search);

  const [signupData, setSignupData] = useState({
    email: tp?.emailTo,
    firstName: '',
    lastName: '',
    mobileNo: '',
    company: '',
    // address: '',
  });

  const [validations, setValidations] = useState({
    validationArr: null,
    isMatched: null,
  });

  const { closeModal, setIsOpenOne } = props;
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [initFields] = getInitialState(signupData);
  const [passfields, setpassFields] = useState({
    password: '',
    confPassword: '',
  });
  const [fields, setFields] = useState(initFields);
  const submitted = useRef(false);
  const { validationArr, isMatched } = validations;
  const [loading, setLoading] = useState(false);
  const handleTextChange = (field, value) => {
    let newFields;

    if (submitted.current) {
      newFields = validate(field, value, fields, submitted.current);
    } else {
      newFields = { ...fields };
      const newField = { ...newFields[field], value };
      newFields[field] = newField;
    }
    setFields(newFields);
  };

  const handlePress = async () => {
    submitted.current = true;

    let flag = false;
    const newFields = validate('all', '', fields, true);
    Object.keys(newFields).map((field) => {
      if (newFields[field].err.err) {
        flag = true;
      }
    });
    let isInvalid = false;

    if (validationArr != null && isMatched !== null) {
      isInvalid = validationArr.includes(false) || !isMatched;
    } else {
      isInvalid = true;
      if (validationArr != null)
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
        }));
      else
        setValidations((prevState) => ({
          ...prevState,
          isMatched: false,
          validationArr: Array(5).fill(false),
        }));
    }
    if (flag || isInvalid) {
      setFields(newFields);
    } else {
      setLoading(true);
      setSignupData((prevState) => ({
        ...prevState,
        firstName: fields.firstName?.value,
        lastName: fields.lastName?.value,
        mobileNo: fields.mobileNo?.value,
        email: fields.email?.value,
        company: fields.company?.value,
        // address: fields.address?.value,
      }));

      await dispatch(
        user_signup(
          {
            email: email.value,
            firstName: firstName.value,
            lastName: lastName.value,
            mobileNo: mobileNo.value,
            company: company.value,
            password,
          },
          true,
          parseInt(tp.userAdvisorId)
        )
      );
      setLoading(false);
      closeModal();
    }
  };

  const handlePassword = (value, field) => {
    setpassFields((prevState) => ({ ...prevState, [field]: value }));
    if (field === 'password') {
      let val_arr = [false, false, false, false];
      if (value.length >= 8) {
        val_arr[0] = true;
      }

      if (/(?=.*[A-Z])/.test(value)) {
        val_arr[1] = true;
      }

      if (/(?=.*\d)/.test(value)) {
        val_arr[2] = true;
      }

      if (/(?=.*[a-z])/.test(value)) {
        val_arr[3] = true;
      }

      setValidations({
        validationArr: val_arr,
        isMatched: notNull(isMatched)
          ? value === passfields.confPassword
          : null,
      });
    } else {
      setValidations((prevState) => ({
        ...prevState,
        isMatched: value === passfields.password,
      }));
    }
  };

  const { firstName, lastName, email, mobileNo, company } = fields;
  const { password, confPassword } = passfields;

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.header}>Register as an Advisor</div>
        <div
          style={{
            padding: '4%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <InputText
              type="text"
              containerStyle={{ width: '40%' }}
              titleStyle={{ 'font-size': '15px' }}
              textInputStyle={null}
              config={{
                maxLength: 20,
                value: firstName?.value,
                onChange: (e) => handleTextChange('firstName', e.target.value),
              }}
              title="First Name"
              err={firstName?.err}
              typed={firstName?.typed}
            />
            <InputText
              type="text"
              containerStyle={{ width: '40%' }}
              titleStyle={{ 'font-size': '15px' }}
              textInputStyle={null}
              config={{
                maxLength: 20,
                value: lastName?.value,
                onChange: (e) => handleTextChange('lastName', e.target.value),
              }}
              title="Last Name"
              err={lastName?.err}
              typed={lastName?.typed}
            />
          </div>
          <InputText
            type="text"
            containerStyle={{ width: '90%' }}
            titleStyle={{ 'font-size': '15px' }}
            textInputStyle={null}
            config={{
              maxLength: 10,
              value: mobileNo?.value,
              onChange: (e) => handleTextChange('mobileNo', e.target.value),
            }}
            title="Phone Number"
            err={mobileNo?.err}
            typed={mobileNo?.typed}
          />
          <InputText
            type="text"
            containerStyle={{ width: '90%' }}
            titleStyle={{ 'font-size': '15px' }}
            textInputStyle={null}
            config={{
              value: email?.value,
              onChange: (e) => handleTextChange('email', e.target.value),
              disabled: true,
            }}
            title="Email ID"
            err={email?.err}
            typed={email?.typed}
          />
          <InputText
            type="text"
            containerStyle={{ width: '90%' }}
            titleStyle={{ 'font-size': '15px' }}
            textInputStyle={null}
            config={{
              maxLength: 20,
              value: company?.value,
              onChange: (e) => handleTextChange('company', e.target.value),
            }}
            title="Company Name"
            err={company?.err}
            typed={company?.typed}
          />

          <PasswordInput
            textInputStyle={{
              borderRadius: '5px',
            }}
            msg=" "
            title="Password"
            config={{
              onChangeText: (value) => handlePassword(value, 'password'),
              value: password,
            }}
            msgStyle={{ color: DANGER_RED, fontSize: '11.5px' }}
            titleStyle={{ fontSize: '16px', padding: '0' }}
          />
          <PasswordRow val_arr={validationArr} />
          <PasswordInput
            title="Confirm Password"
            textInputStyle={{
              borderRadius: '5px',
              height: width > 576 ? '50px' : '40px',
            }}
            config={{
              onChangeText: (value) => handlePassword(value, 'confPassword'),
              value: confPassword,
            }}
            msg={
              notNull(isMatched)
                ? isMatched
                  ? 'Passwords match'
                  : "Passwords don't match"
                : null
            }
            titleStyle={{
              fontSize: width > 576 ? '18px' : '14px',
              color: TEXT_BLACK,
            }}
            msgStyle={{
              color: isMatched ? '#52da9c' : DANGER_RED,
              height: '3vh',
              fontSize: '11.5px',
            }}
          />
        </div>
        <div className={styles.btnDiv}>
          {loading ? (
            <MuiThemeProvider theme={theme}>
              <CircularProgress size={40} />
            </MuiThemeProvider>
          ) : (
            <button onClick={handlePress} className={styles.registerBtn}>
              Register
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Register;
