import { Card } from "@material-ui/core";
import { color_pallete, isNull, roundNum, useDidUpdate } from "@plootus/common";
import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import styles from "./NewPie.module.css";

export const getStratData = (strategy, item) => {
  switch (strategy) {
    case 0:
      return item.proposed_5;
    case 1:
      return item.proposed_1;
    case 2:
      return item.proposed_2;
    case 3:
      return item.proposed_3;
    case 4:
      return item.proposed_4;
    default:
      return undefined;
  }
};

const func = (data, strategy) => {
  const cats = {};

  data.map((item, index) => {
    if (!cats[item.asset_class]) {
      cats[item.asset_class] = 0;
    }

    cats[item.asset_class] += parseFloat(getStratData(strategy, item));
  });

  const pie_chart = [];

  Object.keys(cats)
    .sort()
    .map((item, index) => {
      if (cats[item]) {
        pie_chart.push({
          x: item,
          value: parseInt(roundNum(cats[item], 0, 0)),
          color: color_pallete[index],
          //label: `${item}: ${roundNum(cats[item], 0, 0)}%`,
          selected: false,
        });
      }
    });

  return { pie_chart };
};

const NewPie = (props) => {
  const { funds, strategy, total, loading } = props;
  const [{ data, selected_index }, setState] = useState({
    data: func(funds, strategy).pie_chart,
    selected_index: null,
  });

  useDidUpdate(() => {
    setState({
      selected_index: null,
      data: func(funds, strategy).pie_chart,
    });
  }, [strategy]);

  const itemData = !isNull(selected_index) ? data?.[selected_index] : null;
  return (
    <Card className={styles.pieCard}>
      <div className={styles.Outer}>
        {!loading ? (
          <>
            <PieChart
              animate
              animationDuration={900}
              startAngle={270}
              style={{ padding: "20px" }}
              animationDuration={500}
              animationEasing="ease-in-out"
              data={data}
              // label={({ dataEntry }) => dataEntry.value}
              // labelStyle={{
              //   fontSize: "2px",
              // }}
              // labelPosition={90}

              onClick={(e, index) => {
                const updatedData = data.map((itm, idx) => {
                  if (idx === index) {
                    return { ...itm, selected: !itm.selected };
                  }
                  return { ...itm, selected: false };
                });

                setState({
                  selected_index: data[index].selected ? null : index,
                  data: updatedData,
                });
              }}
              lineWidth={23}
              radius={45}
              segmentsShift={(index) => (data[index].selected ? 1 : 0)}
              segmentsStyle={(index) =>
                data[index].selected
                  ? {
                      strokeWidth: 13,
                      transition: "all 0.5s",
                      cursor: "pointer",
                    }
                  : {
                      cursor: "pointer",
                    }
              }
            ></PieChart>
            <div className={styles.innerText}>
              {!isNull(selected_index) ? (
                <div>
                  <div
                    style={{ color: `${itemData.color}` }}
                  >{`${itemData.x} : ${itemData.value}%`}</div>
                </div>
              ) : (
                <div>
                  {data.map((item, idx) => {
                    return (
                      <div
                        style={{ color: `${item.color}` }}
                      >{`${item.x} : ${item.value}%`}</div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </Card>
  );
};

export default NewPie;
