import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(3)
  },
  post: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    textDecoration: 'none'
  },
  postImage: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2)
  },
  postContent: {
    flex: 1
  },
  postDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(0.5)
  },
  postTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.4,
    marginBottom: theme.spacing(1)
  },
  postExcerpt: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }
}));

const RecentPosts = () => {
  const classes = useStyles();
  const recentPosts = [
    {
      id: 1,
      date: '1 Jan 2023',
      title: 'You Make these Required Minimum Distribution mistakes too?',
      excerpt: 'So many types of accounts and too many plans! Today, an individual has so myriad...',
      image: '/images/blog/rmd.png'
    },
    {
      id: 2,
      date: '1 Jan 2023',
      title: 'Cheat Sheet for Retirement Planning',
      excerpt: 'Combining family life with spouse - Most couples jointly own their finances. Though its true for most cases, retirem...',
      image: '/images/blog/retirement-planning.jpg'
    },
    {
      id: 3,
      date: '1 Jan 2023',
      title: 'Putting money where your mouth is',
      excerpt: 'Investment options – There are myriad investment options available with no individuals to choose from when decid...',
      image: '/images/blog/stop-investing.jpg'
    }
  ];

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Recent Posts
      </Typography>
      {recentPosts.map((post) => (
        <Link to={`/blog/${post.id}`} key={post.id} className={classes.post}>
          <img src={post.image} alt={post.title} className={classes.postImage} />
          <Box className={classes.postContent}>
            <Typography className={classes.postDate}>{post.date}</Typography>
            <Typography className={classes.postTitle}>{post.title}</Typography>
            <Typography className={classes.postExcerpt}>{post.excerpt}</Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default RecentPosts;