"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePreviousRender = void 0;
var react_1 = require("react");
// To get the value in previous render
var usePreviousRender = function (value) {
    var ref = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        ref.current = value;
    });
    // Return happens first
    return ref.current;
};
exports.usePreviousRender = usePreviousRender;
exports.default = exports.usePreviousRender;
