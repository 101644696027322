import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RetirementHero from './components/RetirementHero';
import StatsSlider from './components/StatsSlider';
import SmartRetirementPlanning from './components/SmartRetirementPlanning';
import RetirementCalculatorSection from './components/RetirementCalculatorSection';
import CalculatorStatic from "../../401kCalculatorStatic/CalculatorStatic";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  }
}));

const RetirementSolutions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RetirementHero />
      <StatsSlider />
      <SmartRetirementPlanning />
      <RetirementCalculatorSection />
      {/* <FeaturesSection />
      <CalculatorSection /> */}
    </div>
  );
};

export default RetirementSolutions;