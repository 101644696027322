import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import App from "../../../App";
import CaresActModal from "../../../components/CaresAct/CaresActModal";

export const CaresActContext = React.createContext(null);

export default function CaresActProvider(props) {
  const location = useLocation();
  const [showCaresAct, setShowCaresAct] = useState(
    location.hash.includes("stimulus")
  );

  console.log(props);

  const closeCaresAct = () => {
    setShowCaresAct(false);
  };

  const openCaresAct = () => {
    setShowCaresAct(true);
  };

  return (
    <CaresActContext.Provider
      value={{ showCaresAct, closeCaresAct, openCaresAct }}
    >
      <>
        {props.children}
        {/* {showCaresAct && <CaresActModal />} */}
        <CaresActModal />
      </>
    </CaresActContext.Provider>
  );
}
