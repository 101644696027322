import React, { useEffect } from 'react';
import styles from './LinkedPopup.module.css';
import ModalImage from '../../Assets/linkLogo.jpeg';
const LinkedPopup = (props) => {
  return (
    <div className={styles.main}>
      <a className={styles.title}>
        <img src={ModalImage} />
        <span>Plootus makes linking your accounts easier and safer!</span>
      </a>
      <a className={styles.secondText}>
        <span className={styles.firstSpan}>Secure Your Data</span>
        <span className={styles.secondSpan}>
          Encryption helps protect your personal and financial data
        </span>
      </a>
      <a className={styles.secondText}>
        <span className={styles.firstSpan}>Privacy is our Priority</span>
        <span className={styles.secondSpan}>
          Your credentials will never be made accessible to Plootus
        </span>
      </a>
      <button
        type='submit'
        onClick={props.onClick}
        className={styles.loginButton}
      >
        Continue
      </button>
    </div>
  );
};

export default LinkedPopup;
