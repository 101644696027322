import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Slider, Typography } from "@material-ui/core";
import { connect } from "react-redux";
//import { useDispatch } from "react-redux";
import {
  GENERAL_GRAPH,
  formatCommaString,
  getFormattedValue,
} from "@plootus/common";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";

import styles from "./CustomSlider.module.css";

const getConfig = (title, min, max, type) => ({
  title,
  min,
  max,
  type,
});

const CustomSlider = (props) => {
  const {
    initialValue,
    minValue,
    maxValue,
    label,
    stepValue,
    marksArray,
    unit,
    displayLabel,
    sliderOptions,
    strategyIndex,
    dispatch,
    slidername,
    flag,
    changeHandler = null,
    WIDTH = null,
    setShowText = null,
    myDispatch,
    state,
    setEditMode = null,
    page = null,
  } = props;

  const { height, width } = useWindowDimensions();

  const useStyles = makeStyles({
    root: {
      width: "95%",
      marginLeft: "auto",
    },
    // background color for the Slider component
    mainSlider: {
      color: "#416ce1",
    },
    // the circle slider (slider ball)
    thumb: {
      height: 26,
      width: 26,
      fontSize: 11,
      fontWeight: 600,
      backgroundColor: "#416ce1",
      color: "white",
      border: "2px solid whitesmoke",
      boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.31)",
      marginTop: -9,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },

    disabled: {
      height: 25.5,
      width: 25.5,
      fontSize: 13,
      backgroundColor: "#416ce1",
      color: "white",
      border: "2px solid whitesmoke",
      marginTop: -9,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },

    // the styling of the label which hovers above the slider ball
    valueLabel: {
      left: "calc(-50% + 2px)",
    },

    // the part of the slider which has been traversed
    track: {
      height: 5,
      borderRadius: 4,
      color: "#416ce1",
    },

    // the part of the slider which is not traversed
    rail: {
      height: 5,
      borderRadius: 4,
      color: "e5e5e5",
    },

    labelSpacing: {
      color: "#416ce1",
      whiteSpace: "nowrap",
      fontSize: "16px",
    },

    Label: {
      marginBottom: 0,
      fontSize: "14px",
      display: "flex",
      justifyContent: "space-between",
    },

    mark: {
      fontSize: width <= 1280 ? "11px" : "14px",
      marginTop: "5px",
      textAlign: "center",
      whiteSpace: width <= 1280 ? "initial" : "",
    },

    actualMark: {
      height: 0,
      width: 0,
    },
  });

  // const dispatch = useDispatch();
  const classes = useStyles();

  function valuetext(value) {
    return `${initialValue} k`;
  }

  const MyThumbComponent = (props) => {
    return <span {...props}>{displayLabel === "yes" ? initialValue : ""}</span>;
  };

  const handleSliderChange = (event, newValue) => {
    if (setShowText) setShowText(true);

    if (setEditMode) setEditMode(true);

    if (changeHandler) {
      changeHandler(newValue);
    }

    let updatedSliderOptions;
    if (page === "home") updatedSliderOptions = { ...sliderOptions };
    else updatedSliderOptions = { ...state.sliderOptions };
    // Switch case to check which slider is changed depending on the prop - slidername
    switch (slidername) {
      // Case for retireAge -

      case "retireAge": {
        const updatedRetireAge = { ...updatedSliderOptions[slidername] };
        updatedRetireAge.value = newValue;
        updatedSliderOptions[slidername] = updatedRetireAge;

        // Change min of Life Exp age
        const updatedLifeAge = { ...updatedSliderOptions.lifeExp };
        updatedLifeAge.config = getConfig(
          "Life Expectancy",
          newValue + 1,
          120,
          "num"
        );

        if (updatedLifeAge.value <= newValue) {
          updatedLifeAge.value = newValue + 1;
        }

        updatedSliderOptions.lifeExp = updatedLifeAge;

        if (page === "home") {
          dispatch({
            type: GENERAL_GRAPH,
            payload: { sliderOptions: updatedSliderOptions },
          });
        } else {
          myDispatch({
            type: "GENERAL_SLIDER",
            payload: updatedSliderOptions,
          });
        }

        break;
      }

      // Case for strategyIndex -

      case "strategyIndex": {
        myDispatch({
          type: "STRAT_INDEX",
          payload: newValue,
        });
        break;
      }

      // Default case - In this all other cases are considered

      default: {
        const updatedSliderKey = { ...updatedSliderOptions[slidername] };
        updatedSliderKey.value = newValue;

        updatedSliderOptions[slidername] = updatedSliderKey;

        if (page === "home") {
          dispatch({
            type: GENERAL_GRAPH,
            payload: { sliderOptions: updatedSliderOptions },
          });
        } else {
          myDispatch({
            type: "GENERAL_SLIDER",
            payload: updatedSliderOptions,
          });
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography gutterBottom align="left" className={classes.Label}>
        {label}
        {marksArray ? null : (
          <span className={`${classes.labelSpacing} ${styles.tp}`}>
            {displayLabel === "no"
              ? getFormattedValue(initialValue, "money")
              : ""}
          </span>
        )}
      </Typography>

      <Slider
        ThumbComponent={MyThumbComponent}
        classes={{
          root: classes.root,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
          valueLabel: classes.valueLabel,
          disabled: classes.root,
          markLabel: classes.mark,
          mark: classes.actualMark,
        }}
        style={{ width: WIDTH }}
        value={initialValue}
        onChange={handleSliderChange}
        getAriaValueText={valuetext}
        aria-labelledby="input-slider"
        // auto , on , off
        valueLabelDisplay={displayLabel}
        marks={marksArray}
        step={stepValue}
        min={minValue}
        max={maxValue}
        disabled={flag ? true : false}
      />
    </div>
  );
};

export default connect(({ graphReducer }) => ({
  sliderOptions: graphReducer.sliderOptions,
  strategyIndex: graphReducer.strategyIndex,
}))(CustomSlider);
