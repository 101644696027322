import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
import InputText from "../InputText/InputText";
import MyText from "../Texts/MyText";

function DobInput({
  maxDate,
  minDate,
  selectedDate,
  handleDateChange,
  dob,
  setDob,
}) {
  const { width } = useWindowDimensions();

  return (
    <div>
      {width <= 1024 ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              width: "100%",
            }}
          >
            <MyText
              className="title"
              style={{
                marginBottom: "5px",
                fontSize: width > 576 ? "16px" : "12px",
              }}
            >
              Date of Birth
            </MyText>
            <KeyboardDatePicker
              // disableToolbar
              variant="inline"
              maxDate={maxDate}
              minDate={minDate}
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-dialog"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disableUnderline: true,
              }}
              autoOk={true}
            />
          </div>
        </MuiPickersUtilsProvider>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InputText
            type="date"
            containerStyle={{ width: "40%" }}
            titleStyle={{
              fontSize: width > 576 ? "16px" : "12px",
              marginBottom: "5px",
            }}
            textInputStyle={null}
            config={{
              value: dob,
              onChange: (e) => {
                setDob(e.target.value);
              },
              defaultValue: "1993-01-01",
              disabled: false,
              min: minDate,
              max: maxDate,
            }}
            title="Date of Birth"
            err={null}
            typed={null}
          />
        </div>
      )}
    </div>
  );
}

export default DobInput;
