import React from 'react';
import { 
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(8, 0),
    backgroundColor: '#F5F7FD',
    fontFamily: 'DM Sans, sans-serif',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '33px',
    fontWeight: 600,
    lineHeight: 1.2,
    '& .highlight': {
      color: '#4285f4',
    }
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 400
  },
  description: {
    maxWidth: 800,
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  },
  noFees: {
    marginBottom: theme.spacing(6),
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '18px',
    lineHeight: '28px'
  },
  storeButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  storeButton: {
    height: 48,
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: 0.9
    }
  }
}));

const RetirementLanding = () => {
  const classes = useStyles();

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/us/app/plootus-401k-403b-simplified/id1311669590', '_blank');
  };

  const handlePlayStoreClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.plootus.android', '_blank');
  };

  return (
    <Box className={classes.root}>
      <Container>
        <Typography variant="h1" className={classes.title}>
          Retirement Planning & Budgeting <span className="highlight">Simplified!</span>
        </Typography>
        
        <Typography variant="body1" className={classes.subtitle}>
          Try Plootus free for 30 days and take control of your financial future!
        </Typography>
        
        <Typography variant="body1" className={classes.description}>
          Stop paying excessive fund fees, optimize your employer-sponsored retirement plan
          investments and gain a complete view of your finances with our powerful budgeting tool.
        </Typography>
        
        <Typography variant="body1" className={classes.noFees}>
          No hidden fees, cancel anytime.
        </Typography>
        
        <Box className={classes.storeButtons}>
          <img 
            src="/images/app-store-badge.png" 
            alt="Download on the App Store"
            className={classes.storeButton}
            onClick={handleAppStoreClick}
            role="button"
            tabIndex={0}
          />
          {/* <img 
            src="/images/google-play-png-logo-3802.png" 
            alt="Get it on Google Play"
            className={classes.storeButton}
            onClick={handlePlayStoreClick}
            role="button"
            tabIndex={0}
          /> */}
        </Box>
      </Container>
    </Box>
  );
};

export default RetirementLanding;