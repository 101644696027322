"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var initialState = {
    alert: {
        title: null,
        textContent: null,
        buttonLeftText: null,
        buttonLeftPress: null,
        buttonRightText: null,
        buttonRightPress: null,
        otherContent: null,
    },
};
exports.default = (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types_1.GENERAL_GLOBAL_REDUCER:
            return __assign(__assign({}, state), action.payload);
        case types_1.SEND_ALERT:
            return __assign(__assign({}, state), { alert: __assign(__assign({}, state.alert), action.payload) });
        case types_1.RESET_ALERT:
            return __assign(__assign({}, state), { alert: initialState.alert });
        default:
            return state;
    }
});
