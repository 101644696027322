import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { newsletterData } from './newsletterData';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: '0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)'
  },
  headerSection: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      paddingBottom: 0
    }
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#1E293B',
    marginBottom: theme.spacing(1),
    lineHeight: 1.2
  },
  subtitle: {
    fontSize: '16px',
    color: '#64748B',
    lineHeight: 1.6
  },
  subscribeButton: {
    backgroundColor: '#10B981',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: theme.spacing(1.5, 4),
    minWidth: 'auto',
    color: 'white',
    '&:hover': {
      backgroundColor: '#059669'
    }
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(226, 232, 240, 0.8)'
  },
  contentSection: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4)
    }
  },
  contentWrapper: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  imageContainer: {
    flex: 1,
    height: '320px',
    borderRadius: '12px',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  contentContainer: {
    flex: 1
  },
  date: {
    color: '#4361EE',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  storiesTitle: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#1E293B',
    marginBottom: theme.spacing(3)
  },
  headlinesList: {
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  headlineItem: {
    display: 'flex',
    alignItems: 'flex-start',
    color: '#64748B',
    fontSize: '16px',
    lineHeight: 1.6,
    marginBottom: theme.spacing(2),
    '&:before': {
      content: '"•"',
      color: '#94A3B8',
      marginRight: theme.spacing(2),
      fontSize: '16px'
    }
  },
  description: {
    color: '#64748B',
    fontSize: '16px',
    lineHeight: 1.6,
    marginTop: theme.spacing(3)
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3)
  },
  footerText: {
    color: '#1E293B',
    fontSize: '16px'
  },
  readMore: {
    color: '#4361EE',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': { 
      opacity: 0.8 
    }
  },
  readMoreText: {
    fontSize: '16px',
    fontWeight: 500
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3)
  },
  dot: {
    width: 8,
    height: 8,
    marginBottom: theme.spacing(3),
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  }
}));

const NewsletterContent = () => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);
  const currentData = newsletterData[activeSlide];

  return (
    <div className={classes.root}>
      {/* Header Section */}
      <div className={classes.headerSection}>
        <div className={classes.headerContent}>
          <div>
            <Typography variant="h2" className={classes.title}>
              Plootus Weekly
            </Typography>
            <Typography className={classes.subtitle}>
              Your weekly dose of financial tips, strategies, and insights—delivered fresh every week! 
              Subscribe now and never miss a tip!
            </Typography>
          </div>
          <Button className={classes.subscribeButton}>
            SUBSCRIBE
          </Button>
        </div>
        <div className={classes.divider} />
      </div>

      {/* Newsletter Content */}
      <div className={classes.contentSection}>
        <div className={classes.contentWrapper}>
          {/* Image */}
          <div className={classes.imageContainer}>
            <img
              src={currentData.image}
              alt="Newsletter"
              className={classes.image}
            />
          </div>

          {/* Content */}
          <div className={classes.contentContainer}>
            <Typography className={classes.date}>
              {currentData.date}
            </Typography>
            
            <Typography variant="h3" className={classes.storiesTitle}>
              Top Stories This Week
            </Typography>

            <ul className={classes.headlinesList}>
              {currentData.headlines.map((headline, index) => (
                <li key={index} className={classes.headlineItem}>
                  {headline}
                </li>
              ))}
            </ul>

            <Typography className={classes.description}>
              {currentData.description}
            </Typography>

            <div className={classes.footer}>
              <Typography className={classes.footerText}>
                Read more in this week's newsletter!
              </Typography>
              <div className={classes.readMore}>
                <Typography className={classes.readMoreText}>
                  Read More
                </Typography>
                <ArrowForwardIcon fontSize="small" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Dots */}
      <div className={classes.navigation}>
        {[0, 1].map((index) => (
          <div
            key={index}
            onClick={() => setActiveSlide(index)}
            className={classes.dot}
            style={{
              backgroundColor: index === activeSlide ? '#4361EE' : '#E2E8F0'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NewsletterContent;