import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  metadata: {
    color: '#64748B',
    fontSize: '14px',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 24,
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
    },
    fontWeight: 600,
    color: '#1E293B',
    marginBottom: theme.spacing(4),
    lineHeight: 1.3
  },
  content: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      marginBottom: theme.spacing(2)
    },
    '& p': {
      marginBottom: theme.spacing(2),
      lineHeight: 1.6
    }
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4)
  }
}));

const BlogContent = () => {
  const classes = useStyles();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch('https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=20');
        const data = await response.json();
        const currentPost = data.find(p => p.id.toString() === id);
        setPost(currentPost);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">Error: {error}</Typography>
    );
  }

  if (!post) {
    return (
      <Typography>Post not found</Typography>
    );
  }

  return (
    <Box>
      <Typography className={classes.metadata}>
        Posted on {new Date(post.date).toLocaleDateString()}
      </Typography>

      <Typography variant="h1" className={classes.title}>
        {post.title.rendered}
      </Typography>

      <Box 
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: post.content.rendered }}
      />
    </Box>
  );
};

export default BlogContent;