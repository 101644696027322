"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var initialState = {
    token: null,
    userData: null,
    client: {
        advisors: [],
    },
    advisor: {
        clients: [],
        selectedClient: 0,
    },
    accounts: [],
    register_success: false,
    session_expired: false,
    viaEmail: null,
    userAdvisorId: null,
};
exports.default = (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types_1.GENERAL_USER_REDUCER:
            return __assign(__assign({}, state), action.payload);
        case types_1.CHANGE_TOKEN_STATE:
            return __assign(__assign({}, state), { token: action.payload.token });
        case types_1.CHANGE_CLIENT:
            return __assign(__assign({}, state), { advisor: __assign(__assign({}, state.advisor), { selectedClient: action.payload }) });
        case types_1.RESET_USER_DATA:
            return __assign(__assign({}, initialState), { client: __assign(__assign({}, initialState.client), { advisors: [] }), advisor: __assign(__assign({}, initialState.advisor), { clients: [] }), accounts: [] });
        default:
            return state;
    }
});
