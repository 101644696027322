import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(4, 0),
    // maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    color: '#1A202C',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  text: {
    fontSize: '1rem',
    lineHeight: 1.6,
    marginBottom: theme.spacing(2),
    color: '#4A5568',
  },
  disclaimer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: '#F7FAFC',
    borderRadius: theme.spacing(1),
  },
  disclaimerTitle: {
    fontSize: '0.875rem', // Reduced from previous size
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: '#2D3748',
  },
  disclaimerText: {
    fontSize: '0.75rem', // Reduced disclaimer text
    lineHeight: 1.5,
    marginBottom: theme.spacing(1.5),
    color: '#4A5568',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const MainContent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContent}>
      <Typography variant="h2" className={classes.title}>
        Find the Right Financial Advisor for Your Goals
      </Typography>
      <Typography className={classes.text}>
        The right Financial Advisor can simplify life's complexities and guide you toward your financial goals. Beyond investment advice, advisors help with financial planning, tax strategies, debt repayment, insurance solutions, and estate planning. They are also valuable during major life events, such as marriage, the birth of a child, or setting up trusts and college funds.
      </Typography>
      <Typography className={classes.text}>
        Not all advisors offer the same services, so understanding your needs is key. Whether you're tackling tricky tax issues or securing your family's future, the best advisor will help you chart a personalized course to success.
      </Typography>
      <Typography className={classes.text}>
        Start your search today and take control of your financial future!
      </Typography>

      <Box className={classes.disclaimer}>
        <Typography className={classes.disclaimerTitle}>
          Disclosure of Compensation for Featured Advisors
        </Typography>
        <Typography className={classes.disclaimerText}>
          The financial advisors featured on this website pay Analyze Future LLC a fee to be listed or featured on this platform. This fee may be a fixed monthly amount, a per-client fee, or a combination of both. The inclusion of financial advisors on this website does not constitute an endorsement or recommendation of their services by Analyze Future LLC.
        </Typography>
        <Typography className={classes.disclaimerText}>
          Selection Criteria: The advisors featured on this website are not selected based on performance or client outcomes. Their inclusion is based on payment and/or participation in the Analyze Future LLC network.
        </Typography>
        <Typography className={classes.disclaimerText}>
          Conflict of Interest Disclosure: Because Analyze Future LLC receives compensation from financial advisors featured on this site, a potential conflict of interest exists. Users should carefully evaluate the qualifications and services of any advisor before engaging their services.
        </Typography>
      </Box>
    </Box>
  );
};

export default MainContent;