import {
  DANGER_RED,
  deleteAccount,
  getAccounts,
  SEND_ALERT,
  TEXT_BLACK_2,
} from '@plootus/common';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TableRow.module.css';

const TableRow = ({
  name,
  number,
  idx,
  last,
  active,
  accountId,
  setloading,
  lastUpdated,
  providerName,
}) => {
  const color = idx % 2 ? '#f8f8f8' : '#fff';
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userReducer);

  const handleClick = async () => {
    setloading(true);
    await dispatch(deleteAccount(accountId));
    await dispatch(getAccounts(userData.uuid));
    setloading(false);
  };

  return (
    <div
      className={`${styles.container} ${idx === 0 ? styles.first : ''} ${
        idx === last - 1 ? styles.last : ''
      }`}
      style={{
        backgroundColor: color,
        border: 'solid 1px #c6cbde',
        borderTop: idx === 0 ? 'solid 1px #c6cbde' : '0px',
      }}
    >
      <div className={styles.left}>
        <div className={styles.leftop}>
          {providerName} - {name}
        </div>
        <div className={styles.leftbottom} style={{ color: TEXT_BLACK_2 }}>
          Ending in <span className={styles.number}>{number}</span>
        </div>
        <div className={styles.updated} style={{ color: TEXT_BLACK_2 }}>
          {'Last Updated - '}
          <span className={styles.date}>
            {moment(lastUpdated).format('DD MMM YYYY')}
          </span>
        </div>
      </div>
      <div className={styles.right}>
        {!active ? <div className={styles.edit}>EDIT</div> : null}

        <div
          className={styles.cross}
          onClick={() => {
            dispatch({
              type: SEND_ALERT,
              payload: {
                title: 'Alert',
                textContent: 'This will delete your linked account',
                buttonRightText: 'Delete',
                buttonRightContStyle: {
                  backgroundColor: DANGER_RED,
                  color: 'white',
                },
                buttonRightPress: () => handleClick(),
                buttonLeftText: 'Cancel',
              },
            });
          }}
        >
          <img src="/delete.svg" className={styles.trash} alt="Delete" />
        </div>
      </div>
    </div>
  );
};

export default TableRow;
