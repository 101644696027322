import React from 'react';
import styles from './Picker.module.css';
import { BLUE_PLOOT, TEXT_BLACK_2 } from '@plootus/common';

import { Scrollbars } from 'react-custom-scrollbars';

const Picker = ({
  rows,
  index,
  handlePress,
  handleClick,
  visible,
  contStyle,
  headerStyle,
  dropdownStyle,
  itemStyle,
  isLogin,
  titleStyle,
  showIndex,
}) => {
  return (
    <div className={styles.container} style={contStyle}>
      <div
        className={styles.header}
        onClick={() => handlePress()}
        style={headerStyle}
      >
        <span style={titleStyle} className={styles.title}>
          {showIndex ? rows[0]?.label : rows[index]?.label}
        </span>
        <span className={styles.icon}>
          {visible ? (
            <i
              class="fa fa-caret-up"
              style={{ fontSize: '25px' }}
              aria-hidden="true"
            ></i>
          ) : (
            <i
              class="fa fa-caret-down"
              style={{ fontSize: '25px' }}
              aria-hidden="true"
            ></i>
          )}
        </span>
      </div>
      {visible ? (
        <div className={styles.dropdown} style={dropdownStyle}>
          <Scrollbars style={{ width: '100%', height: '100%' }}>
            {rows.map((item, idx) => {
              if (idx === 0 && isLogin) return null;
              return (
                <div className={styles.option}>
                  <input
                    type="radio"
                    className={styles.radio}
                    id={item.label}
                    name="category"
                    onClick={() => handleClick(idx)}
                    key={idx}
                    checked={index === idx ? true : false}
                  ></input>
                  <label
                    htmlFor={item.label}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {item.label}
                  </label>
                </div>
              );
            })}
          </Scrollbars>
        </div>
      ) : null}
    </div>
  );
};

export default Picker;
