import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Select, 
  MenuItem,
  Typography,
  Container,
  Radio
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AdvisorCard from '../AdvisorCard';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 0',
  },
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    }
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#111827',
    marginBottom: 24
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  resultsCount: {
    fontSize: 14,
    color: '#6B7280',
    '& span': {
      fontWeight: 500
    }
  },
  selectContainer: {
    position: 'relative',
    minWidth: 120
  },
  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      backgroundColor: '#FFFFFF',
      fontSize: 16,
      height: 44,
      '& fieldset': {
        borderColor: '#E5E7EB',
      },
      '&:hover fieldset': {
        borderColor: '#D1D5DB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4F46E5',
        borderWidth: 1
      }
    },
    '& .MuiSelect-select': {
      paddingRight: 32,
      color: '#111827'
    }
  },
  menuItem: {
    fontSize: 16,
    color: '#111827',
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#F3F4F6'
    },
    '&.Mui-selected': {
      backgroundColor: '#EEF2FF',
      '&:hover': {
        backgroundColor: '#E0E7FF'
      }
    }
  },
  radio: {
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
      marginRight: 8,
      color: '#4F46E5'
    }
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: 16
    }
  }
}));

const MILE_OPTIONS = [
  { value: 1, label: '1 Mile' },
  { value: 5, label: '5 Miles' },
  { value: 10, label: '10 Miles' },
  { value: 25, label: '25 Miles' },
  { value: 50, label: '50 Miles' },
  { value: 100, label: '100 Miles' },
  { value: 200, label: '200 Miles' }
];

const SearchResults = ({ zipCode = "56763", advisors = [] }) => {
  const classes = useStyles();
  const [distance, setDistance] = useState(1); // default to 1 mile
  const [sortBy, setSortBy] = useState('distance');
  
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography className={classes.title}>
          Advisors near you
        </Typography>
        
        <div className={classes.header}>
          <Typography className={classes.resultsCount}>
            Showing <span>06</span> results for "{zipCode}"
          </Typography>
          
          <div className={classes.selectContainer}>
            <Select
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              variant="outlined"
              className={classes.select}
              IconComponent={KeyboardArrowDownIcon}
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    borderRadius: 12,
                    marginTop: 8,
                    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                  }
                }
              }}
            >
              {MILE_OPTIONS.map((option) => (
                <MenuItem 
                  key={option.value} 
                  value={option.value} 
                  className={classes.menuItem}
                >
                  <Radio
                    checked={distance === option.value}
                    className={classes.radio}
                    size="small"
                    color="primary"
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={classes.cardsContainer}>
          {advisors.map((advisor) => (
            <AdvisorCard key={advisor.id} advisor={advisor} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default SearchResults;