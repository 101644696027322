import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BLUE_PLOOT, get_advisors, TEXT_BLACK_1 } from '@plootus/common';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import styles from './Advisor.module.css';
import AddModal from './Modals/AddModal';
import DeleteModal from './Modals/DeleteModal';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const Advisor = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  const advisors = useSelector((state) => state.userReducer.client.advisors);

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(get_advisors());
      setLoading(false);
    })();
  }, [dispatch]);

  if (loading) {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MuiThemeProvider theme={theme}>
          <CircularProgress size={50} />
        </MuiThemeProvider>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.upper}>
        <div className={styles.header}>YOUR ADVISORS</div>
        <div className={styles.btnSection}>
          <button
            onClick={toggleEdit}
            className={edit ? styles.greyEdit : styles.blueEdit}
          >
            {edit ? 'BACK' : 'EDIT'}
          </button>
        </div>
      </div>
      <div className={styles.advisorSection}>
        {advisors.map(
          ({ id, advisorEmail, firstName, lastName, company }, index) => {
            return (
              <div className={styles.details}>
                <div className={styles.item} key={index}>
                  <Avatar
                    name={`${firstName} ${lastName}`}
                    size={width <= 576 ? '80px' : '95px'}
                    round="50%"
                  />
                  <div className={styles.delDiv}>
                    <DeleteModal
                      id={id}
                      show={edit}
                      firstName={firstName}
                      lastName={lastName}
                    />
                  </div>
                </div>
                <div className={styles.belowText}>
                  {`${firstName} ${lastName}`}
                  <br />
                  {width < 868 ? (
                    <></>
                  ) : (
                    <>
                      <span
                        className={styles.advisorEmail}
                        style={{ color: TEXT_BLACK_1 }}
                      >
                        {advisorEmail}
                      </span>
                      <br />
                    </>
                  )}
                  <span
                    className={styles.advisorCompany}
                    style={{ color: TEXT_BLACK_1 }}
                  >
                    {company}
                  </span>
                </div>
              </div>
            );
          }
        )}
        <div className={styles.details}>
          <div className={`${styles.item} ${styles.add}`}>
            <AddModal />
          </div>
          <div className={styles.belowText}>Add Advisor</div>
        </div>
      </div>
    </div>
  );
};

export default Advisor;
