import {
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  LIGHT_GREY,
  roundNum,
  TEXT_BLACK_2,
  TEXT_BLACK_3,
  toTitleCase,
} from '@plootus/common';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import styles from './DropDownNested.module.css';

const DropDownItemNested = ({
  rightValue,
  leftTopValue,
  leftBottom,
  leftBottomKey,
  param4,
  paddingLeft,
  rightBottom,
  last,
  fontSize,
}) => {
  const amount = `$${formatCommaString(roundNum(rightValue, 0, 2))}`;
  const amount2 =
    leftBottomKey === 'costBasis'
      ? `$${formatCommaString(roundNum(leftBottom, 0, 2))}`
      : null;
  const left_key =
    leftBottomKey === 'costBasis' ? (
      <div style={{ color: DRAK_GREEN_PLOOT }}>
        <span style={{ color: TEXT_BLACK_2, fontSize: fontSize - 3 }}>
          {_.startCase(leftBottomKey)} {': '}
        </span>{' '}
        <span
          className={styles.leftBottomMoney}
          style={{ fontSize: fontSize - 2 }}
        >
          {amount2?.slice(0, -2)}
          <span style={{ fontSize: fontSize - 5, paddingBottom: '1.5px' }}>
            {amount2?.slice(-2)}
          </span>
        </span>
      </div>
    ) : (
      <div style={{ color: TEXT_BLACK_2, fontSize: fontSize - 3 }}>
        {' '}
        {`${toTitleCase(leftBottomKey)} : `} {leftBottom}
      </div>
    );
  const { width } = useWindowDimensions();
  return leftTopValue ? (
    <div
      className={styles.dropDownContainer}
      style={{
        borderBottom: last ? '0px' : '1px solid #ddd',
        backgroundColor: LIGHT_GREY,
        paddingLeft: paddingLeft,
        height: 'auto',
        minHeight: width > 576 ? '8vh' : '6vh',
        borderLeft: '1px solid #ddd',
        borderRight: '1px solid #ddd',
      }}
    >
      <Row noGutters>
        <Col xs="8" style={{ paddingLeft: '7%' }}>
          <Row noGutters>
            <Col xs="10">
              <Row noGutters>
                <Col
                  xs="12"
                  className={styles.topLeft}
                  style={{
                    fontSize: fontSize,
                    height: 'auto',
                    minHeight: width > 576 ? '4vh' : '3vh',
                    color: TEXT_BLACK_3,
                  }}
                >
                  {toTitleCase(leftTopValue)}
                </Col>
                <Col
                  xs="12"
                  className={styles.bottomLeft}
                  style={{
                    height: 'auto',
                    minHeight: width > 576 ? '4vh' : '3vh',
                    color: TEXT_BLACK_2,
                  }}
                >
                  {leftBottomKey && left_key ? left_key : '-'}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs="4">
          <Row noGutters style={{ height: '100%' }}>
            <Col
              xs="10"
              className={styles.topRight}
              style={
                param4 === 'INCOME'
                  ? {
                      color: DRAK_GREEN_PLOOT,
                      fontSize: width > 576 ? fontSize : fontSize + 1,
                      height: 'auto',
                      minHeight: width > 576 ? '4vh' : '3vh',
                    }
                  : {
                      color: DARK_RED_PLOOT,
                      fontSize: width > 576 ? fontSize : fontSize + 1,
                      height: 'auto',
                      minHeight: width > 576 ? '4vh' : '3vh',
                    }
              }
            >
              {amount.slice(0, -2)}{' '}
              <span
                style={
                  width > 576
                    ? {
                        fontSize: fontSize - 4,
                        paddingBottom: width > 576 ? '1.5px' : '0px',
                      }
                    : { fontSize: fontSize - 2, paddingBottom: '1.5px' }
                }
              >
                {`  ${amount.slice(-2)}`}
              </span>
            </Col>
            <Col xs="2"></Col>
            <Col
              xs="10"
              className={styles.bottomRight}
              style={{
                fontSize: width > 576 ? 14 : 13,
                height: 'auto',
                minHeight: width > 576 ? '4vh' : '3vh',
                color: TEXT_BLACK_2,
              }}
            >
              {rightBottom && moment(rightBottom).format('DD MMM YYYY')}
            </Col>
            <Col xs="2"></Col>
          </Row>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default DropDownItemNested;
