import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box, 
  Container, 
  Typography, 
  IconButton,
  CircularProgress
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { BLUE_PLOOT } from '@plootus/common';
import useWindowDimensions from '../../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5)
    }
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 700,
    color: '#1A202C',
    [theme.breakpoints.up('md')]: {
      fontSize: 36
    }
  },
  viewAllLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: BLUE_PLOOT,
    fontWeight: 600,
    fontSize: 16,
    '&:hover': {
      color: '#2D3A8C'
    }
  },
  viewAllIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 20
  },
  sliderContainer: {
    position: 'relative',
    '& .swiper-container': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  navigationButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    backgroundColor: 'white',
    width: 40,
    height: 40,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  prevButton: {
    left: -16,
    [theme.breakpoints.up('md')]: {
      left: -20
    }
  },
  nextButton: {
    right: -16,
    [theme.breakpoints.up('md')]: {
      right: -20
    }
  },
  blogCard: {
    backgroundColor: '#F8FAFC',
    borderRadius: 16,
    overflow: 'hidden',
    height: '100%',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  blogImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover'
  },
  blogContent: {
    padding: theme.spacing(3)
  },
  blogDate: {
    color: '#424242',
    fontSize: 14,
    marginBottom: theme.spacing(2)
  },
  blogTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#2D3748',
    marginBottom: theme.spacing(2),
    lineHeight: 1.4
  },
  blogDescription: {
    color: '#424242',
    fontSize: 14,
    lineHeight: 1.6
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400
  }
}));

const BlogSection = () => {
  const classes = useStyles();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const result = await axios.get(
          'https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=6'
        );
        setBlogs(result.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // Calculate slides per view based on screen width
  let slidesPerView = 3;
  if (width <= 576) slidesPerView = 1;
  else if (width <= 1024) slidesPerView = 2;

  const extractFirstImage = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const img = div.querySelector('img');
    return img ? img.src : '/placeholder.png';
  };

  const extractExcerpt = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const paragraphs = div.getElementsByTagName('p');
    for (let p of paragraphs) {
      const text = p.textContent.trim();
      if (text && !text.startsWith('Please Share') && text !== 'Until next time!' && !text.includes('Plootus') && text.length > 50) {
        return text.slice(0, 150) + '...';
      }
    }
    return '';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (loading) {
    return (
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.headerTitle}>
            Read Our Blogs
          </Typography>
          <Box className={classes.loaderContainer}>
            <CircularProgress style={{ color: BLUE_PLOOT }} size={40} />
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.headerTitle}>
            Read Our Blogs
          </Typography>

          <Box
            component="a"
            href="https://www.plootus.com/blog/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.viewAllLink}
          >
            VIEW ALL
            <ArrowForwardIcon className={classes.viewAllIcon} />
          </Box>
        </Box>

        {/* Blog Slider */}
        <Box className={classes.sliderContainer}>
          <IconButton className={`swiper-button-prev ${classes.navigationButton} ${classes.prevButton}`}>
            <ChevronLeftIcon />
          </IconButton>
          
          <IconButton className={`swiper-button-next ${classes.navigationButton} ${classes.nextButton}`}>
            <ChevronRightIcon />
          </IconButton>

          <Swiper
            spaceBetween={24}
            slidesPerView={slidesPerView}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            loop={true}
          >
            {blogs.map((blog) => (
              <SwiperSlide key={blog.id}>
                <Box 
                  className={classes.blogCard}
                  onClick={() => window.open(blog.link, '_blank')}
                >
                  <Box
                    component="img"
                    src={extractFirstImage(blog.content.rendered)}
                    alt={blog.title.rendered}
                    className={classes.blogImage}
                  />
                  
                  <Box className={classes.blogContent}>
                    <Typography className={classes.blogDate}>
                      {formatDate(blog.date)}
                    </Typography>

                    <Typography 
                      className={classes.blogTitle}
                      dangerouslySetInnerHTML={{ __html: blog.title.rendered }}
                    />

                    <Typography className={classes.blogDescription}>
                      {extractExcerpt(blog.content.rendered)}
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default BlogSection;