import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Axios from 'axios';

const socialIcons = [
  {
    icon: '/images/social-icon/ig.png',
    link: 'https://www.instagram.com/weareplootus/?hl=en',
    name: 'Instagram'
  },
  {
    icon: '/images/social-icon/x.png',
    link: 'https://x.com/weareplootus',
    name: 'X'
  },
  {
    icon: '/images/social-icon/thread.png',
    link: 'https://www.threads.net/@weareplootus',
    name: 'Threads'
  },
  {
    icon: '/images/social-icon/yt.png',
    link: 'https://www.youtube.com/@plootus401ksimplified7',
    name: 'Youtube'
  },
  {
    icon: '/images/social-icon/fb.png',
    link: 'https://www.facebook.com/weareplootus/',
    name: 'Facebook'
  },
  {
    icon: '/images/social-icon/in.png',
    link: 'https://www.linkedin.com/company/plootus/?viewAsMember=true',
    name: 'LinkedIn'
  },
  {
    icon: '/images/social-icon/bs.png',
    link: 'https://bsky.app/profile/weareplootus.bsky.social',
    name: 'Bluesky'
  }
];

const messages = [
  {
    percentage: "60%",
    prefix: "Over",
    mainText: "of Americans say they lack control over their finances.",
    subText: "Plootus gives you a full financial picture to take back control."
  },
  {
    percentage: "40%",
    prefix: "Nearly",
    mainText: "of Americans are not saving enough for retirement?",
    subText: "Don't become part of this statistic. Take control of your financial future today!"
  },
  {
    percentage: "65%",
    prefix: "Nearly",
    mainText: "of clients wish their advisors had a better view of their full financial picture.",
    subText: "Plootus provides a comprehensive picture for advisors, making holistic planning easier."
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  downloadBanner: {
    backgroundColor: '#3ACB89',
    color: 'white',
    padding: theme.spacing(4),
    position: 'relative',
    overflow: 'hidden'
  },
  bannerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  bannerText: {
    flex: 1,
    marginRight: theme.spacing(4)
  },
  bannerButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  appStoreButton: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  subscribeSection: {
    backgroundColor: '#4361EE',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6)
  },
  subscribeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(3)
    }
  },
  subscribeInput: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent'
      },
      '&:hover fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent'
      }
    }
  },
  subscribeButton: {
    backgroundColor: '#4361EE',
    '&:hover': {
      backgroundColor: '#3651DE'
    },
    textTransform: 'none',
    borderRadius: 4,
    height: 35,
    minWidth: 100
  },
  footerSection: {
    backgroundColor: 'black',
    color: 'white',
    padding: theme.spacing(5, 0)
  },
  footerLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: 16,
    display: 'block',
    marginBottom: theme.spacing(2),
    transition: 'color 0.2s ease',
    '&:hover': {
      color: '#36B37E'
    }
  },
  activeLink: {
    color: '#36B37E !important',
    fontWeight: 500
  },
  socialIcons: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center'
  },
  socialIcon: {
    width: '24px',
    height: '24px',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.8
    }
  },
  bottomDivider: {
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(3)
  }
}));

const Footer = () => {
  const classes = useStyles();
  const [currentMessage, setCurrentMessage] = useState(0);
  const [email, setEmail] = useState('');
  const location = useLocation();

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/us/app/plootus-401k-403b-simplified/id1311669590', '_blank');
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) return;

    try {
      await Axios.post('https://www.plootus.com/api/user/mailingList', { email });
      alert('Successfully subscribed to our mailing list!');
      setEmail('');
    } catch (error) {
      console.error('Subscription failed:', error);
      alert('Subscription failed. Please try again.');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.downloadBanner}>
        <Container>
          <div className={classes.bannerContent}>
            <div className={classes.bannerText}>
              <Typography variant="h6" gutterBottom>
                <span>{messages[currentMessage].prefix} </span>
                <span style={{ color: 'blue', fontWeight: 700 }}>
                  {messages[currentMessage].percentage}
                </span>
                {' '}{messages[currentMessage].mainText}
              </Typography>
              <Typography variant="body1">
                {messages[currentMessage].subText}
              </Typography>
            </div>
            <div className={classes.bannerButtons}>
              <img
                src="/images/ios-black.png"
                alt="App Store"
                height="40"
                className={classes.appStoreButton}
                onClick={handleAppStoreClick}
              />
            </div>
          </div>
        </Container>
      </div>

      <div className={classes.footerSection}>
        <Container>
          <div className={classes.subscribeSection}>
            <form onSubmit={handleSubscribe} className={classes.subscribeContent}>
              <div>
                <Typography variant="h5" gutterBottom>
                  SUBSCRIBE FOR WEEKLY INSIGHTS!
                </Typography>
                <Typography>
                  Stay informed with the top 3 things investors need to know this week, plus updates on new features and expert tips.
                </Typography>
              </div>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Your email ID"
                className={classes.subscribeInput}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        className={classes.subscribeButton}
                        type="submit"
                      >
                        Subscribe
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </form>
          </div>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Link
                to="/"
                className={`${classes.footerLink} ${isActiveLink('/') ? classes.activeLink : ''}`}
              >
                Overview
              </Link>
              <Link
                to="/retirement-solutions"
                className={`${classes.footerLink} ${isActiveLink('/retirement-solutions') ? classes.activeLink : ''}`}
              >
                Retirement Solutions
              </Link>
              <Link
                to="/financial-advisors"
                className={`${classes.footerLink} ${isActiveLink('/financial-advisors') ? classes.activeLink : ''}`}
              >
                For Financial Advisors
              </Link>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Link
                to="/finance-101"
                className={`${classes.footerLink} ${isActiveLink('/finance-101') ? classes.activeLink : ''}`}
              >
                Finance 101
              </Link>
              <Link
                to="/philosophy"
                className={`${classes.footerLink} ${isActiveLink('/philosophy') ? classes.activeLink : ''}`}
              >
                Philosophy
              </Link>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Link
                to="/security"
                className={`${classes.footerLink} ${isActiveLink('/security') ? classes.activeLink : ''}`}
              >
                Security
              </Link>
              <Link
                to="/faqs"
                className={`${classes.footerLink} ${isActiveLink('/faqs') ? classes.activeLink : ''}`}
              >
                FAQs
              </Link>
              <Link
                to="/privacy"
                className={`${classes.footerLink} ${isActiveLink('/privacy') ? classes.activeLink : ''}`}
              >
                Privacy Policy
              </Link>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant="h5" style={{ color: 'white' }} gutterBottom>Install App</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <img
                  src="/images/app-store.png"
                  alt="App Store"
                  width="125"
                  className={classes.appStoreButton}
                  onClick={handleAppStoreClick}
                />
              </div>
            </Grid>
          </Grid>

          <div className={classes.bottomDivider}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              ©2018-{new Date().getFullYear()} Analyze Future LLC | All rights reserved.
              </Typography>
              <div className={classes.socialIcons}>
                {socialIcons.map((social, index) => (
                  <a
                    key={index}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={social.name}
                  >
                    <img
                      src={social.icon}
                      alt={social.name}
                      className={classes.socialIcon}
                    />
                  </a>
                ))}
              </div>
            </div>
            
          </div>
          <div className={classes.bottomDivider} style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
              Savings estimates are based on historical data and assumptions that may not apply to all investors. Individual results will vary. Plootus does not guarantee specific financial outcomes.
              Past performance does not guarantee future results.
              </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;