// MainScreen.jsx
import React, { useState } from "react";
import Modal from "react-modal";
import PersonalInfo from "./screens/PersonalInfo";
import Strategy from "./screens/Strategy";
import Start from "./screens/Start";
import Preferences from "./screens/Preferences";
import styles from './MainScreen.module.css';

// Make sure to bind modal to your root app element
Modal.setAppElement('#root');

const MainSignupScreen = ({
  modalIsOpen,
  openModal,
  closeModal,
  loginopenModal,
  isInEmployeer,
  show,
  fromFees,
}) => {
  const [signupData, setSignupData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    gender: 0,
    dob: "1993-01-01",
    zipcode: "",
    strategy: -1,
    approx_401k_bal: 0,
    sponser_name: "",
    employerEin: null,
    socialLogin: null,
    socialId: null,
    preferences: [],
    termsAccepted: false
  });

  const [currentStep, setCurrentStep] = useState(0);

  const resetForm = () => {
    setSignupData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      mobileNo: "",
      gender: 0,
      dob: "1993-01-01",
      zipcode: "",
      strategy: -1,
      approx_401k_bal: 0,
      sponser_name: "",
      employerEin: null,
      socialLogin: null,
      socialId: null,
      preferences: [],
      termsAccepted: false
    });
    setCurrentStep(0);
  };

  const handleCloseModal = () => {
    resetForm();
    closeModal();
  };

  const nextStep = () => {
    setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep(prev => prev - 1);
  };

  const renderStep = () => {
    const commonProps = {
      signupData,
      setSignupData,
      closeModal: handleCloseModal,
      loginopenModal
    };

    switch (currentStep) {
      case 0:
        return (
          <Start
            {...commonProps}
            nextPress={nextStep}
          />
        );
      case 1:
        return (
          <PersonalInfo
            {...commonProps}
            nextPress={nextStep}
            prevStep={prevStep}
          />
        );
      case 2:
        return (
          <Strategy
            {...commonProps}
            nextPress={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <Preferences
            {...commonProps}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  const renderButton = () => {
    if (fromFees) {
      return (
        <div className={styles.feesButton} onClick={openModal}>
          Signup
        </div>
      );
    }
    
    if (isInEmployeer) {
      return (
        <button className={styles.employerButton} onClick={openModal}>
          SIGN UP TO VIEW FULL ALLOCATION
        </button>
      );
    }
    
    return (
      <button
        onClick={openModal}
        className={styles.signupButton}
        style={show ? { display: "none" } : {}}
      >
        Get Started
      </button>
    );
  };

  return (
    <div>
      {renderButton()}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className={currentStep === 0 ? styles.startModal : styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Signup Modal"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        {renderStep()}
      </Modal>
    </div>
  );
};

export default MainSignupScreen;