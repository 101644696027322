import React, { useState, useEffect } from "react";
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Toolbar, CssBaseline } from "@material-ui/core";
import { Route, Switch, useLocation } from "react-router-dom";
import LoginSignupContext from "../../Reusables/VisualAndAccord/Context/LoginSignupContext";

// Import your components
import Navbar from "../NewUI/MainComponents/Navbar";
import Footer from "../NewUI/MainComponents/Footer";
import Home from "../NewUI/Home";
import RetirementSolutions from "../NewUI/RetirementSolutions";
import FinancialAdvisorsPage from "../NewUI/FinancialAdvisors";
import Pricing from "../NewUI/Pricing";
import BlogPage from "../NewUI/Blogs";
import Philosophy from "../NewUI/Philosophy";
import Security from "../NewUI/Security";
import FAQ from "../NewUI/FAQs";
import Privacy from "../NewUI/Privacy";
import BlogDetails from "../NewUI/Blogs/BlogDetails";
import Algorithm from "../Algorithm/Algorithm";
import Faqs from "../FAQs/Faqs";
import StaticEmployer from "../Employer2/StaticEmployer";
import CalculatorStatic from "../401kCalculatorStatic/CalculatorStatic";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import Error404 from "../404/Error404";
import AdvisorSearch from "../NewUI/Advisor";
import ComingSoon from "../NewUI/ComingSoon";
import ComingSoonComponent from "../NewUI/ComingSoon";

// Create theme instance
const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    }
  }
});

const useStyles = makeStyles(() => ({
  "@global": {
    "html, body": {
      backgroundColor: "#FFFFFF !important",
      margin: 0,
      padding: 0
    },
    "#root": {
      backgroundColor: "#FFFFFF !important"
    }
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#FFFFFF !important",
    width: "100%",
    position: "relative",
    overflow: "hidden"
  },
  toolbar: {
    minHeight: "64px !important",
    backgroundColor: "#FFFFFF",
    "@media (min-width: 960px)": {
      minHeight: "80px !important",
    },
  },
  mainContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 1,
    backgroundColor: "#FFFFFF !important",
    width: "100%"
  }
}));

const StaticPages = () => {
  const classes = useStyles();
  const location = useLocation();
  const [signupModal, setSignupModal] = useState(false);
  const [loginModal, setLoginModal] = useState(location.query?.viaEmail || false);
  const [idx, setIdx] = useState(0);

  // Tawk chat integration
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5a57a3a2d7591465c706a90d/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoginSignupContext.Provider
        value={{
          signupModal,
          setSignupModal,
          loginModal,
          setLoginModal,
          idx,
          setIdx,
        }}
      >
        <div className={classes.root}>
          <Navbar />
          <Toolbar className={classes.toolbar} />
          <main className={classes.mainContent}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/retirement-solutions" component={RetirementSolutions} />
              <Route exact path="/individual-users" component={RetirementSolutions} />
              <Route exact path="/financial-advisors" component={FinancialAdvisorsPage} />
              <Route exact path="/partners" component={ComingSoon} />
              <Route exact path="/blogs" component={BlogPage} />
              <Route exact path="/finance-101" component={BlogPage} />
              <Route exact path="/blogs/:slug" component={BlogDetails} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/philosophy" component={Philosophy} />
              <Route exact path="/algorithm" component={Algorithm} />
              <Route exact path="/security" component={Security} />
              <Route exact path="/faqs" component={FAQ} />
              <Route exact path="/employer" component={StaticEmployer} />
              <Route exact path="/find-an-advisor" component={ComingSoonComponent} />
              <Route exact path="/401kCalculator" component={CalculatorStatic} />
              <Route exact path="/termsandcondition" component={TermsAndCondition} />
              <Route exact path="/privacy" component={Privacy} />
              <Route component={Error404} />
            </Switch>
          </main>
          <Footer />
        </div>
      </LoginSignupContext.Provider>
    </ThemeProvider>
  );
};

export default StaticPages;