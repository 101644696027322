import React from 'react';
import styles from './ManageTable.module.css';
import TableRow from './TableRow';

const ManageTable = ({ data, loading, setloading }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => {
        return (
          <TableRow
            key={index}
            name={item.accountName}
            number={item.accountNumber}
            idx={index}
            last={data.length}
            active={item.accountStatus === 'ACTIVE'}
            accountId={item.acc_Id}
            loading={loading}
            setloading={setloading}
            lastUpdated={item.lastUpdated}
            providerName={item.providerName}
          />
        );
      })}
    </div>
  );
};

export default ManageTable;
