import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    display: 'block'
  },
  root: {
    borderRadius: '12px',
    overflow: 'hidden',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
    }
  },
  imageWrapper: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    backgroundColor: '#F8FAFC'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
  },
  content: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  },
  date: {
    fontSize: '0.875rem',
    color: '#4361EE',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#1E293B',
    marginBottom: theme.spacing(2),
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  excerpt: {
    fontSize: '0.875rem',
    color: '#64748B',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: 1.6
  }
}));

const BlogCard = ({ post }) => {
  const classes = useStyles();

  return (
    <Link 
      to={`/blogs/${post.slug}`}
      className={classes.link}
    >
      <div className={classes.root}>
        {/* Image */}
        <div className={classes.imageWrapper}>
          <img
            src={post.image}
            alt={post.title}
            className={classes.image}
          />
        </div>

        {/* Content */}
        <div className={classes.content}>
          <Typography className={classes.date}>
            {post.date}
          </Typography>

          <Typography
            variant="h3"
            className={classes.title}
          >
            {post.title}
          </Typography>

          <Typography className={classes.excerpt}>
            {post.excerpt}
          </Typography>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;