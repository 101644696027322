import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  heroSection: {
    backgroundColor: '#F8F9FF',
    position: 'relative',
    paddingTop: '48px',
    paddingBottom: '48px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '32px',
      paddingBottom: '32px'
    }
  },
  heroContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  leftContent: {
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  rightContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: 400
    }
  },
  title: {
    '& .highlight': {
      color: '#4361EE'
    },
    fontSize: '2.5rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: '#111827',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    }
  },
  subtitle: {
    color: '#6B7280',
    fontSize: '1.125rem',
    marginBottom: theme.spacing(4)
  }
}));