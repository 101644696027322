import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const TP = withStyles({
  tooltip: {
    fontSize: "15px",
  },
})(Tooltip);

const MyTooltip = ({ render, title, placement }) => {
  return (
    <>
      <TP
        title={title}
        enterTouchDelay={0}
        placement={placement ? placement : "bottom"}
        arrow
      >
        {render()}
      </TP>
    </>
  );
};

export default MyTooltip;
