import { Slider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "./Slider.module.css";

const useStyles = makeStyles({
  Label: {
    marginBottom: 0,
    fontSize: "15px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between",

    width: "95%",
  },
  root: {
    width: "95%",
    marginLeft: "auto",
  },
  mainSlider: {
    color: "#416ce1",
  },
  thumb: {
    height: 23,
    width: 23,
    backgroundColor: "#416ce1",
    border: "2px solid #ffffff",
    color: "#fff",
    fontSize: 11,
    fontWeight: 600,
    marginTop: -9,
    marginLeft: -12,
    padding: 2,
    boxShadow: "0 3px 7px 0 rgba(0, 0, 0, 0.35)",
    "&:focus, &:hover, &$active": {},
  },
  track: {
    height: 5,
    borderRadius: 4,
  },

  // the part of the slider which is not traversed
  rail: {
    height: 5,
    borderRadius: 4,
    color: "e5e5e5",
  },
});
const MySlider = ({
  min,
  max,
  value,
  handleSliderChange,
  label,
  displayLabel,
  step,
}) => {
  const classes = useStyles();
  const MyThumbComponent = (props) => {
    return <span {...props}>{displayLabel === "yes" ? `${value}%` : ""}</span>;
  };
  return (
    <div className={classes.root}>
      <Typography gutterBottom align="left" className={classes.Label}>
        {label}
        <span className={styles.title}>{value}%</span>
      </Typography>
      <Slider
        classes={{
          root: classes.root,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
        }}
        value={value}
        onChange={handleSliderChange}
        defaultValue={value}
        min={min}
        max={max}
        step={step}
      />
    </div>
  );
};

export default MySlider;
