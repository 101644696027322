"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var initialState = {
    sliderOptions: null,
    openingBalance: null,
    strategyIndex: null,
    expense_home: null,
    socialSecurity: [],
    graph: {
        assets: [],
        gap: [],
        future: [],
        results: {
            retirementAssests: 0,
            retirementGap: 0,
            futureExpenses: 0,
            retireReturns: 0,
        },
        flag: null,
    },
    editVisible: false,
};
exports.default = (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types_1.GENERAL_GRAPH:
            var updated = __assign(__assign({}, state), action.payload);
            return updated;
        case types_1.GRAPH_GRAPH_RESULTS:
            return __assign(__assign({}, state), { graph: __assign(__assign(__assign({}, state.graph), action.payload.graph), { results: __assign(__assign({}, state.graph.results), action.payload.results) }) });
        default:
            return state;
    }
});
