import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  DARK_RED_PLOOT,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  getDate,
  LIGHT_GREY,
  roundNum,
  toTitleCase,
} from '@plootus/common';
import React from 'react';
import { Col, Row } from 'reactstrap';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import styles from './CanExpandItem.module.css';
import DropDownItem from './DropDownItem';

const CanExpandItem = ({
  index,
  rightValue,
  leftValue,
  leftBottom,
  color,
  renderItem,
  data,
  selected,
  dispatchAction,
  value,
  expandSearched,
  valueColor,
  api_name,
  mappingData,
  first,
  last,
}) => {
  const { level2 } = mappingData;
  const amount = `$${formatCommaString(roundNum(Math.abs(rightValue), 0, 2))}`;
  const bgcolor = index % 2 ? LIGHT_GREY : 'white';
  const childColor = index % 2 ? 'white' : LIGHT_GREY;
  const { width } = useWindowDimensions();

  return (
    <div>
      <div
        className={styles.expandHeader}
        onClick={() => {
          if (value) {
            expandSearched(index);
          } else {
            dispatchAction(index);
          }
        }}
        style={{
          backgroundColor: 'white',
          borderTopLeftRadius: width > 576 && first ? '8px' : '0px',
          borderTopRightRadius: width > 576 && first ? '8px' : '0px',
          borderBottomLeftRadius:
            width > 576 && last && !selected ? '8px' : '0px',
          borderBottomRightRadius:
            width > 576 && last && !selected ? '8px' : '0px',
          borderLeft: width > 576 ? '1px solid #ddd' : '0px',
          borderRight: width > 576 ? '1px solid #ddd' : '0px',
          borderTop: '1px solid #ddd',
          borderBottom: !selected && last ? '1px solid #ddd' : '0px',
        }}
      >
        <Row noGutters>
          <Col xs="1" className={styles.centerCol}>
            <div
              className={styles.circle}
              style={{
                backgroundColor: color[0] === '#' ? color : `#${color}`,
              }}
            ></div>
          </Col>
          <Col xs="7" className={styles.left}>
            <Row noGutters>
              <Col
                xs="12"
                className={styles.topLeft}
                style={{ height: leftBottom ? '4vh' : '8vh' }}
              >
                <Row noGutters>
                  <Col xs="12">
                    {api_name === 'cashflow'
                      ? `${toTitleCase(leftValue)} ${getDate().getFullYear()}`
                      : `${toTitleCase(leftValue)}`}
                  </Col>
                </Row>
              </Col>
              {leftBottom ? (
                <Col xs="12">{leftBottom.split(' ')[0]}</Col>
              ) : null}
            </Row>
          </Col>
          <Col xs="4" className={styles.right}>
            <Row noGutters>
              <Col
                xs="8"
                className={styles.moneyLeft}
                style={
                  valueColor === 'INCOME'
                    ? { color: DRAK_GREEN_PLOOT }
                    : { color: DARK_RED_PLOOT }
                }
              >
                {amount.slice(0, -2)}
                <span className={styles.afterDecimal}>{amount.slice(-2)}</span>
              </Col>
              <Col xs="4" className={styles.dropIcon}>
                {selected ? (
                  <KeyboardArrowUpIcon fontSize="default" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="default" />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {selected ? (
        <div
          className={styles.expandedItems}
          style={{
            backgroundColor: bgcolor,
            borderLeft: width > 576 ? '1px solid #ddd' : '0px',
            borderRight: width > 576 ? '1px solid #ddd' : '0px',
            borderBottom: last ? '1px solid #ddd' : '0px',
            borderBottomLeftRadius: last && selected ? '8px' : '0px',
            borderBottomRightRadius: last && selected ? '8px' : '0px',
          }}
        >
          <div className={styles.expandedContainer}>
            {data.map((item, index) => {
              return (
                <DropDownItem
                  first={index === 0 ? 1 : 0}
                  last={index === data.length - 1 ? 1 : 0}
                  index={index}
                  paddingLeft={24}
                  rightValue={roundNum(item[level2.param1], 0, 2)}
                  leftTopValue={item[level2.param2]}
                  rightBottom={item[level2.param4]}
                  leftBottom={item[level2.param3]}
                  leftBottomKey={level2.param3}
                  param4={item[level2.param3]}
                  childColor={childColor}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CanExpandItem;
