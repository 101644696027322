import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import ShowChart from '@material-ui/icons/ShowChart';
import PieChart from '@material-ui/icons/PieChart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white'
  },
  titleSection: {
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8)
    }
  },
  mainTitle: {
    fontWeight: 600,
    color: '#1E293B',
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: '#64748B',
    fontSize: '1rem',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: 1.6
  },
  featureContainerBase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(6),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(10),
      marginBottom: theme.spacing(12),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  featureContainerRegular: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  featureContainerReversed: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse'
    }
  },
  featureImageWrapper: {
    flex: 1,
    animation: '$fadeIn 0.6s ease-out'
  },
  featureImageWrapperReversed: {
    flex: 1,
    animation: '$fadeInReverse 0.6s ease-out'
  },
  imageContainer: {
    backgroundColor: '#F8FAFC',
    borderRadius: '24px',
    border: '1px solid rgba(226, 232, 240, 0.8)',
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4)
    }
  },
  featureImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    display: 'block'
  },
  featureContent: {
    flex: 1
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  iconBox: {
    width: 48,
    height: 48,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    '& svg': {
      color: 'white',
      fontSize: 24
    }
  },
  blueIcon: {
    backgroundColor: '#4361EE',
  },
  greenIcon: {
    backgroundColor: '#36B37E',
  },
  featureTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#1E293B',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.75rem'
    }
  },
  featureDescription: {
    color: '#64748B',
    fontSize: '1rem',
    lineHeight: 1.6
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  '@keyframes fadeInReverse': {
    '0%': {
      opacity: 0,
      transform: 'translateX(20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  }
}));

const FeatureImage = ({ src, alt, isReversed = false }) => {
  const classes = useStyles();
  return (
    <div className={isReversed ? classes.featureImageWrapperReversed : classes.featureImageWrapper}>
      <div className={classes.imageContainer}>
        <img src={src} alt={alt} className={classes.featureImage} />
      </div>
    </div>
  );
};

const IconBox = ({ Icon, isAlternate }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.iconBox} ${isAlternate ? classes.greenIcon : classes.blueIcon}`}>
      <Icon />
    </div>
  );
};

const FeatureSection = () => {
  const classes = useStyles();

  const features = [
    {
      icon: AccountBalanceWallet,
      title: 'Monitor Client Financial Accounts',
      description: "Access all your clients' financial information—income, expenses, assets, liabilities, and retirement goals—in one easy-to-use app. With Plootus, your clients can connect to 14,000+ financial institutions, allowing you to see all their data in one place.",
      image: '/images/track-accounts2.png',
      alt: 'Monitor Client Financial Accounts'
    },

    {
      icon: PieChart,
      title: 'Personalized Retirement Projections',
      description: "Leverage AI-driven, customized savings projections and retirement strategies tailored to each client's goals. Provide comprehensive advice, supported by real-time data, to ensure your clients are always on track for retirement success.",
      image: '/images/retirement-projections.png',
      alt: 'Retirement Projections'
    }, {
      icon: ShowChart,
      title: 'Effortless 401k and 403b Planning',
      description: "Plootus provides instant results to enhance your clients' 401k and 403b planning process, saving them time and money. There's no need to request data or manually enter information—Plootus takes care of everything for you.",
      image: '/images/401k-planning.png',
      alt: '401k Planning'
    },
  ];

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.titleSection}>
          <Typography variant="h2" className={classes.mainTitle}>
            Empowering Advisors for Client Success!
          </Typography>
          <Typography className={classes.subtitle}>
            Plootus can help you transform how you manage client finances with real-time
            insights, automated tools, and seamless data connectivity!
          </Typography>
        </div>
      </Container>

      <Container maxWidth="lg">
        {features.map((feature, index) => (
          <div
            key={feature.title}
            className={`${classes.featureContainerBase} ${index % 2 === 0 ? classes.featureContainerRegular : classes.featureContainerReversed
              }`}
          >
            <FeatureImage
              src={feature.image}
              alt={feature.alt}
              isReversed={index % 2 !== 0}
            />
            <div className={classes.featureContent}>
              <div className={classes.titleContainer}>
                <IconBox Icon={feature.icon} isAlternate={index % 2 !== 0} />
                <Typography variant="h3" className={classes.featureTitle}>
                  {feature.title}
                </Typography>
              </div>
              <Typography className={classes.featureDescription}>
                {feature.description}
              </Typography>
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default FeatureSection;