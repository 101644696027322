/* eslint-disable react-hooks/exhaustive-deps */
import { CHANGE_CLIENT, get_clients } from '@plootus/common';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from './Adash.module.css';

const Adash = () => {
  const clients = useSelector((state) => state.userReducer.advisor.clients);
  const [route, setRoute] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_clients());
  }, []);

  const handleClick = (idx) => {
    localStorage.setItem('selectedClient', idx);
    dispatch({
      type: CHANGE_CLIENT,
      payload: idx,
    });

    setRoute(true);
  };

  if (route) {
    return <Redirect to="/auth/dashboard" />;
  }

  return (
    <div>
      <Modal
        isOpen={true}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <div className={styles.header}>
          <div className={styles.h1}>Select a Client</div>
          <div className={styles.h2}>
            Click on a client to manage their account
          </div>
        </div>
        <div className={styles.root}>
          {clients.map((item, index) => (
            <div className={styles.details}>
              <div
                key={index}
                className={styles.item}
                onClick={() => handleClick(index)}
              >
                <Avatar
                  name={`${item.firstName} ${item.lastName}`}
                  size="80px"
                  round="50%"
                />
              </div>
              <div className={styles.belowText}>
                {`${item.firstName} ${item.lastName}`}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Adash;
