"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentUserDataSelector = exports.currentAgeSelector = exports.isLinkedSelector = exports.currentClientSelector = exports.isAdvisorSelector = exports.tokenSelector = void 0;
var functions_1 = require("../../functions");
var tokenSelector = function (_a) {
    var userReducer = _a.userReducer;
    return ({
        token: userReducer.token,
    });
};
exports.tokenSelector = tokenSelector;
var isAdvisorSelector = function (_a) {
    var _b;
    var userReducer = _a.userReducer;
    return ((_b = userReducer.userData) === null || _b === void 0 ? void 0 : _b.userType) === 'advisor';
};
exports.isAdvisorSelector = isAdvisorSelector;
var currentClientSelector = function (_a) {
    var userReducer = _a.userReducer;
    return userReducer.advisor.clients[userReducer.advisor.selectedClient];
};
exports.currentClientSelector = currentClientSelector;
var isLinkedSelector = function (_a) {
    var _b, _c, _d, _e, _f;
    var userReducer = _a.userReducer;
    if (((_b = userReducer.userData) === null || _b === void 0 ? void 0 : _b.userType) === 'advisor') {
        return (_e = (_c = userReducer.advisor) === null || _c === void 0 ? void 0 : _c.clients[(_d = userReducer.advisor) === null || _d === void 0 ? void 0 : _d.selectedClient]) === null || _e === void 0 ? void 0 : _e.isLinked;
    }
    else {
        return (_f = userReducer.userData) === null || _f === void 0 ? void 0 : _f.isLinked;
    }
};
exports.isLinkedSelector = isLinkedSelector;
var currentAgeSelector = function (_a) {
    var userData = _a.userReducer.userData;
    return (0, functions_1.getCurrentAge)((userData === null || userData === void 0 ? void 0 : userData.dob) || '1991-01-01');
};
exports.currentAgeSelector = currentAgeSelector;
var currentUserDataSelector = function (state) {
    var _a;
    var isAdvisor = ((_a = state.userReducer.userData) === null || _a === void 0 ? void 0 : _a.userType) === 'advisor';
    return isAdvisor ? (0, exports.currentClientSelector)(state) : state.userReducer.userData;
};
exports.currentUserDataSelector = currentUserDataSelector;
