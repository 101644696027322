import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BlogHeader from './components/BlogHeader';
import NewsletterSection from './components/NewsletterSection';
import AllBlogPosts from './components/AllBlogPosts';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const BlogPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BlogHeader />
      <NewsletterSection />
      <AllBlogPosts />
    </div>
  );
};

export default BlogPage;