import React, { useEffect, useState } from "react";
import {
  get_clients,
  tokenSelector,
  CHANGE_CLIENT,
  RESET_DATA,
} from "@plootus/common";
import styles from "./Client.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Avatar from "react-avatar";
import useWindowDimensions from "../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";
const Client = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.userReducer.advisor.clients);
  const [route, setRoute] = useState(false);
  const { token } = useSelector(tokenSelector);
  const user = useSelector((state) => state.userReducer);

  const { width, height } = useWindowDimensions();
  useEffect(() => {
    async function f() {
      await dispatch(get_clients());
    }
    f();
  }, []);

  const handleClick = (idx) => {
    localStorage.setItem("selectedClient", idx);
    dispatch({
      type: RESET_DATA,
    });
    dispatch({
      type: CHANGE_CLIENT,
      payload: idx,
    });
    setRoute(true);
  };

  if (route) return <Redirect to="/auth/dashboard" />;

  return (
    <>
      <div className={styles.header}>Your Clients</div>
      <div className={styles.root}>
        {client.map((item, index) => (
          <div className={styles.details}>
            <div
              key={index}
              className={styles.item}
              onClick={() => handleClick(index)}
            >
              {/* <img src="/profile-1.png" className={styles.img} /> */}
              <Avatar
                name={`${item.firstName} ${item.lastName}`}
                size={width <= 576 ? "80px" : "95px"}
                round="50%"
                //style={{ width: "90%", height: "90%", borderRadius: "50%" }}
              />
            </div>
            <div
              className={styles.belowText}
              style={
                user.advisor.selectedClient === index
                  ? { fontWeight: "bold" }
                  : {}
              }
            >{`${item.firstName} ${item.lastName}`}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Client;
