import React from "react";
import styles from "./TableFooter.module.css";
import { Row, Col } from "reactstrap";
import useWindowDimensions from "../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions";

const TableFooter = ({ total, current }) => {
  const { width } = useWindowDimensions();

  if (width > 576) {
    return (
      <div className={styles.container}>
        <Row noGutters>
          <Col xs='1' className={styles.columnText}></Col>
          <Col xs='4' className={styles.columnText}>
            <div className={styles.textWrapper}>TOTAL</div>
          </Col>
          {current ? (
            <Col xs='2' className={styles.columnTextNum}>
              <div className={styles.textWrapper}>0.0 %</div>
            </Col>
          ) : null}
          <Col xs={current ? "2" : "3"} className={styles.columnTextNum}>
            <div className={styles.textWrapper}>{parseInt(total)} %</div>
          </Col>
          <Col xs={current ? "3" : "4"} className={styles.columnText}></Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Row noGutters>
          <Col xs='4' className={styles.columnText}>
            <div className={styles.textWrapper}>TOTAL</div>
          </Col>
          {current ? (
            <Col xs='2' className={styles.columnTextNum}>
              <div className={styles.textWrapper}>0.0 %</div>
            </Col>
          ) : null}
          <Col xs={current ? "2" : "3"} className={styles.columnTextNum}>
            <div className={styles.textWrapper}>{parseInt(total)} %</div>
          </Col>
          <Col xs={current ? "4" : "5"} className={styles.columnTextNum}></Col>
        </Row>
      </div>
    );
  }
};

export default TableFooter;
