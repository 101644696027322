import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Card,
  Container, 
  Typography,
  Box
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    // backgroundColor: '#f8f9fa'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(6),
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  leftSection: {
    flex: 0.4,  // Changed from 1 to 0.4
    position: 'relative',
    height: 372
  },
  imageCard: {
    background: 'linear-gradient(to right, #3359C0, #416CE1)',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2),
    height: 372,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartWrapper: {
    // backgroundColor: '#fff',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    width: '100%',
    height: 340,
    display: 'flex',
    flexDirection: 'column'
  },
  feesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  feesLabel: {
    color: '#64748b',
    fontSize: '1rem'
  },
  feesValue: {
    color: '#36B37E',
    fontWeight: 600,
    fontSize: '2rem'
  },
  rightSection: {
    flex: 0.6,  // Changed from 1 to 0.6
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: 372
  },
  featureButton: {
    padding: theme.spacing(2.5),
    height: 116,
    border: 'none',
    backgroundColor: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    transition: 'all 0.3s ease',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#F5F7FD',
      transform: 'translateX(8px)',
      '& $featureTitle': {
        color: '#4361EE'
      }
    },
    '&.active': {
      backgroundColor: '#F5F7FD',
      transform: 'translateX(8px)',
      '& $featureTitle': {
        color: '#4361EE'
      }
    },
    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   left: -4,
    //   top: '50%',
    //   transform: 'translateY(-50%)',
    //   width: 4,
    //   height: '0%',
    //   background: 'linear-gradient(180deg, #4361EE 0%, rgba(67, 97, 238, 0.3) 100%)',
    //   transition: 'height 0.3s ease',
    // },
    '&:hover::before, &.active::before': {
      height: '100%'
    }
  },
  featureTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    color: '#1E293B',
    transition: 'color 0.3s ease'
  },
  featureDesc: {
    fontSize: '1rem',
    color: '#64748B',
    lineHeight: 1.6
  },
  highlight: {
    color: '#4361EE',
    fontWeight: 500
  },
  sectionHeader: {
    marginBottom: theme.spacing(6)
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 700,
    color: '#1E293B',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    fontSize: '1.5rem',
    color: '#64748B',
    fontWeight: 500
  }
}));

const features = [
  {
    id: 1,
    title: 'Save on hidden fees',
    description: "Stop losing money to hidden fees. Plootus optimizes your 401k and investment plans, potentially saving you thousands of dollars in fees over time.",
    // highlight: '$61,200 over time',
    imageUrl: '/images/home-features/graph1.png',
    value: '$131,255'
  },
  {
    id: 2,
    title: 'Quick Employer Search and Advice',
    description: "Effortlessly locate your 401k, 403b, 457, or TSP plan—just enter your employer's name for tailored investment recommendations. No hassle, just results.",
    imageUrl: '/images/home-features/graph2.png',
    value: '$98,450'
  },
  {
    id: 3,
    title: 'AI Retirement Calculator',
    description: "Take the guesswork out of retirement. Plootus's AI-driven calculator offers savings projections, strategic suggestions, and helps adjust contributions to meet your goals",
    imageUrl: '/images/home-features/graph3.png',
    value: '$145,780'
  }
];
  // Developed and managed by Mejona technology

const WhatPlootusDoes = () => {
  const classes = useStyles();
  const [activeFeature, setActiveFeature] = useState(features[0]);
  const [autoChange, setAutoChange] = useState(true);

  useEffect(() => {
    let interval;
    if (autoChange) {
      interval = setInterval(() => {
        setActiveFeature((current) => {
          const nextIndex = features.findIndex(f => f.id === current.id) + 1;
          return features[nextIndex] || features[0];
        });
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [autoChange]);

  const handleFeatureClick = (feature) => {
    setAutoChange(false);
    if (activeFeature.id === feature.id) {
      return;
    }
    setActiveFeature(feature);
  };

  const handleMouseEnter = (feature) => {
    setAutoChange(false);
    if (activeFeature.id !== feature.id) {
      setActiveFeature(feature);
    }
  };

  const handleMouseLeave = () => {
    setAutoChange(true);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.sectionHeader}>
          <Typography variant="h2" className={classes.title}>
            What Plootus does
          </Typography>
          <Typography variant="h2" className={classes.title}>
            — Better than anyone else!
          </Typography>
        </div>

        <div className={classes.container}>
          <div className={classes.leftSection}>
            <Card elevation={0} className={classes.imageCard}>
              <div className={classes.chartWrapper}>
                {/* <div className={classes.feesHeader}>
                  <Typography className={classes.feesLabel}>
                    Fees Saved
                  </Typography>
                  <Typography className={classes.feesValue}>
                    {activeFeature.value}
                  </Typography>
                </div> */}
                <img 
                  src={activeFeature.imageUrl} 
                  alt={activeFeature.title}
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    transition: 'opacity 0.3s ease'
                  }}
                />
              </div>
            </Card>
          </div>

          <div className={classes.rightSection}>
            {features.map((feature) => (
              <button
                key={feature.id}
                className={`${classes.featureButton} ${
                  activeFeature.id === feature.id ? 'active' : ''
                }`}
                onClick={() => handleFeatureClick(feature)}
                onMouseEnter={() => handleMouseEnter(feature)}
                onMouseLeave={handleMouseLeave}
              >
                <Typography className={classes.featureTitle}>
                  {feature.title}
                </Typography>
                <Typography className={classes.featureDesc}>
                  {feature.description}
                  {feature.highlight && (
                    <span className={classes.highlight}>
                      {' '}{feature.highlight}
                    </span>
                  )}
                </Typography>
              </button>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhatPlootusDoes;