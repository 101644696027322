import React, { Component } from "react";
import { getFormattedValue } from "@plootus/common";
import styles from "./BarGraph.module.css";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryContainer,
  VictoryLabel,
  VictoryTooltip,
} from "victory";

const BarGraph = (props) => {
  const {
    sampleData,
    xLabel,
    yLabel,
    color1,
    color2,
    borderRadius,
    myBarRatio,
    xAxisColor,
    yAxisColor = "none",
    yUnit,
    giveMargin = true,
    showTickX = false,
    showTickY = false,
    yLabelShow = true,
    barWidth = 40,
    fontSize = 15,
    gType = null,
    showTooltip = false,
  } = props;

  console.log(sampleData);

  const arr = [
    "Jan 2020",
    "Feb 2020",
    "Mar 2020",
    "Apr 2020",
    "May 2020",
    "Jun 2020",
    "Jul 2020",
    "Aug 2020",
    "Sep 2020",
    "Oct 2020",
    "Nov 2020",
    "Dec 2020",
  ];

  return (
    <div className={styles.mygraph}>
      <svg
        viewBox={"0 0" + " " + "650" + " " + "450"}
        //preserveAspectRatio="none"
        width="100%"
        height="100%"
        style={{
          paddingLeft: "20px",
          marginTop: "-15px",
        }}
      >
        <VictoryChart
          theme={VictoryTheme.material}
          standalone={false}
          height={450}
          width={650}
          domainPadding={{ x: 40, y: [10, 10] }}
          padding={{
            top: gType === "future" ? 75 : 60,
            left: gType === "cash" ? 33 : 73,
            bottom: 85,
            right: gType === "future" ? 60 : 30,
          }}
          containerComponent={<VictoryContainer />}
        >
          {/* x-axis component */}
          <VictoryAxis
            label={xLabel}
            style={{
              axis: { stroke: xAxisColor },
              axisLabel: { fontSize: 20, padding: 30 },
              tickLabels: {
                fontWeight: 400,
                stroke: "#61dafb ",
                fontSize: fontSize > 20 ? 27 : 15,
                marginBlock: "20px",
              },
              grid: { stroke: "lightgrey", opacity: "0.8" },
            }}
            tickFormat={
              showTickX
                ? (t) => {
                    if (gType === "cash" && sampleData.length >= 8) {
                      if (arr.indexOf(t) % 2 == 0) return t;
                      else return null;
                    } else {
                      if (gType !== "future") return t;
                      return Math.round(t) === t ? t : null;
                    }
                  }
                : null
            }
            tickLabelComponent={<VictoryLabel y={410} data={sampleData} />}
          />

          {/* y-axis component */}
          <VictoryAxis
            dependentAxis
            label={yLabel}
            style={{
              // axis: { stroke: yAxisColor },
              axis: { stroke: yAxisColor },
              axisLabel: { fontSize: 20, padding: 10 },
              tickLabels: {
                fontWeight: 400,
                stroke: "#61dafb ",
                // stroke: "none",
                fontSize: showTickY ? fontSize - 3 : 0,
                marginBlock: "50px",
              },
              grid: { stroke: "lightgrey", opacity: "0.6" },
            }}
            tickFormat={(t) => `${getFormattedValue(t, "money", 1)}`}
            // tickFormat={(t) => `${Math.round(t)}`}
          />

          {/* main bar graph component */}
          <VictoryBar
            //barWidth={({ index }) => index * 2 + 8}
            barWidth={sampleData.length > 20 ? 10 : barWidth}
            data={sampleData}
            cornerRadius={{ top: borderRadius, bottom: borderRadius }}
            barRatio={myBarRatio}
            y0={giveMargin ? (d) => (d.y > 0 ? 50 : -50) : 0}
            style={{
              data: { fill: ({ datum }) => (datum.y > 0 ? color1 : color2) },
              labels: { fontSize: 17 },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            labels={
              showTooltip
                ? ({ datum }) => [
                    `Age : ${datum.x}`,
                    `${
                      datum.y > 0 ? "Expenses" : "Income"
                    } : ${getFormattedValue(Math.abs(datum.y), "money", 1)}`,
                  ]
                : yLabelShow
                ? ({ datum }) =>
                    `${getFormattedValue(Math.abs(datum.y), "money", 1)}`
                : null
            }
            labelComponent={
              showTooltip ? (
                <VictoryTooltip
                  style={{ fontSize: "22px" }}
                  cornerRadius={5}
                  flyoutStyle={{
                    stroke: "#416ce1",
                    strokeWidth: 2,
                  }}
                />
              ) : (
                <VictoryLabel />
              )
            }
          />
        </VictoryChart>
      </svg>
    </div>
  );
};

export default BarGraph;
