import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import NewsletterContent from './NewsletterContent';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(-16),
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-20)
    }
  }
}));

const NewsletterSection = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <NewsletterContent />
    </Container>
  );
};

export default NewsletterSection;