// Preferences.jsx
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { 
  user_signup, 
  BLUE_PLOOT,
  useDidUpdate,
  resendActivationEmail, 
  sendAlert
} from '@plootus/common';
import styles from './Preferences.module.css';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT },
  },
  typography: { useNextVariants: true },
});

const preferenceOptions = [
  {
    id: 'HYS',
    icon: '💰',
    label: 'High-Yield Savings (incl. emergency fund)'
  },
  {
    id: 'HSA',
    icon: '🏥',
    label: 'Health Savings Account (HSA)'
  },
  {
    id: 'Rollover',
    icon: '🔄',
    label: '401(k)/IRA Rollover'
  },
  {
    id: 'AN',
    icon: '📈',
    label: 'Annuities (Guaranteed retirement income)'
  },
  {
    id: 'DM',
    icon: '💳',
    label: 'Debt Management & Consolidation (incl. student loans)'
  },
  {
    id: 'CRY',
    icon: '₿',
    label: 'Crypto Investing (Bitcoin, etc.)'
  },
  {
    id: 'ADV',
    icon: '👥',
    label: 'Personal Financial Advice (1-on-1 coaching & planning)'
  }
];

const Preferences = ({ 
  setSignupData, 
  signupData, 
  prevStep,
  dispatch,
  closeModal,
  loginopenModal
}) => {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const userReducer = useSelector((state) => state.userReducer);

  useDidUpdate(() => {
    if (userReducer.register_success) {
      setLoading(false);
      // Close the modal first
      if (closeModal) {
        closeModal();
      }
      // Show success alert
      dispatch(
        sendAlert({
          title: 'Verify your email',
          textContent:
            'Link for account activation has been sent to your email. Please check your Promotions or Spam folder.',
          buttonLeftText: 'Resend',
          buttonLeftPress: () => {
            dispatch(resendActivationEmail(signupData.email));
          },
          buttonRightPress: () => {
            if (loginopenModal) {
              loginopenModal();
            }
          },
        })
      );
    } else if (userReducer.register_error) {
      setLoading(false);
      dispatch(
        sendAlert({
          title: 'Registration Failed',
          textContent: userReducer.error || 'Please try again',
          buttonRightText: 'OK',
          buttonRightPress: () => {},
        })
      );
    }
  }, [userReducer]);

  const handlePreferenceChange = (id) => {
    setSelectedPreferences(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!termsAccepted) {
      return;
    }

    try {
      setLoading(true);
      
      // Create individual fields for each preference
      const HYS = selectedPreferences.includes('HYS') ? 'Yes' : null;
      const HSA = selectedPreferences.includes('HSA') ? 'Yes' : null;
      const Rollover = selectedPreferences.includes('Rollover') ? 'Yes' : null;
      const AN = selectedPreferences.includes('AN') ? 'Yes' : null;
      const DM = selectedPreferences.includes('DM') ? 'Yes' : null;
      const CRY = selectedPreferences.includes('CRY') ? 'Yes' : null;
      const ADV = selectedPreferences.includes('ADV') ? 'Yes' : null;
      
      // Send signup data with all preferences as separate fields
      await dispatch(
        user_signup({
          ...signupData,
          HYS,
          HSA,
          Rollover,
          AN,
          DM,
          CRY,
          ADV,
          termsAccepted: true
        })
      );
    } catch (error) {
      setLoading(false);
      dispatch(
        sendAlert({
          title: 'Error',
          textContent: 'An unexpected error occurred. Please try again.',
          buttonRightText: 'OK',
          buttonRightPress: () => {},
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Create an Account</h1>

      <div className={styles.stepperContainer}>
        <div className={`${styles.step} ${styles.completedStep}`}>
          <div className={styles.stepDot} />
          <span className={styles.stepLabel}>Basic Info</span>
        </div>
        <div className={`${styles.step} ${styles.completedStep}`}>
          <div className={styles.stepDot} />
          <span className={styles.stepLabel}>Strategy</span>
        </div>
        <div className={`${styles.step} ${styles.activeStep}`}>
          <div className={styles.stepDot} />
          <span className={styles.stepLabel}>Preferences</span>
        </div>
      </div>

      <div className={styles.formContent}>
        <h2 className={styles.subtitle}>
          Which other financial products or services interest you? (Select all that apply)
        </h2>

        <div className={styles.preferencesList}>
          {preferenceOptions.map(preference => (
            <label key={preference.id} className={styles.preferenceItem}>
              <div className={styles.preferenceContent}>
                <span className={styles.iconWrapper}>
                  {preference.icon}
                </span>
                <span className={styles.preferenceLabel}>{preference.label}</span>
              </div>
              
              <div className={styles.checkboxWrapper}>
                <input
                  type="checkbox"
                  checked={selectedPreferences.includes(preference.id)}
                  onChange={() => handlePreferenceChange(preference.id)}
                  className={styles.checkbox}
                />
                <span className={styles.checkmark} />
              </div>
            </label>
          ))}
        </div>

        <div className={styles.termsContainer}>
          <label className={styles.termsWrapper}>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className={styles.checkbox}
              />
              <span className={styles.checkmark} />
            </div>
            <span className={styles.termsText}>
              By creating an account, you agree to the{' '}
              <a 
                href="/termsandcondition" 
                className={styles.link} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Terms of use
              </a>
              {' '}and{' '}
              <a 
                href="/privacy" 
                className={styles.link} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </label>
          {submitted && !termsAccepted && (
            <p className={styles.errorText}>Please accept the terms and conditions</p>
          )}
        </div>

        <div className={styles.buttonGroup}>
          <button 
            type="button" 
            onClick={prevStep} 
            className={styles.backButton}
            disabled={loading}
          >
            Back
          </button>
          {loading ? (
            <div className={styles.loadingButton}>
              <MuiThemeProvider theme={theme}>
                <CircularProgress size={30} />
              </MuiThemeProvider>
            </div>
          ) : (
            <button 
              type="button" 
              onClick={handleSubmit} 
              className={styles.nextButton}
              disabled={loading}
            >
              Create Account
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect()(Preferences);