import React from "react";
import { VictoryPie } from "victory";

import {
  roundNum,
  isNull,
  TEXT_BLACK,
  formatCommaString,
  getFormattedValue,
  toTitleCase,
  generalMoney,
} from "@plootus/common";
import _ from "lodash";
import { PieChart } from "react-minimal-pie-chart";
import styles from "./Pie.module.css";

const Pie = (props) => {
  const {
    data,
    total,
    no_highlight,
    dataTypePick,
    highlight_pie,
    selected_index,
    mappingData,
  } = props;
  const itemData = !isNull(selected_index) ? data?.[selected_index] : null;
  const arr = data.map((item, index) => ({
    value: item.y,
    color: `${data[index].color}`,
  }));
  const { level1 } = mappingData;

  const totVal = formatCommaString(roundNum(total, 0, 2));
  const totalPer = "100.00";
  let percentage;
  if (itemData) {
    percentage = itemData.per.toString();
    if (!percentage.includes(".")) {
      percentage += ".00";
    }
  }
  return (
    <div className={styles.Outer}>
      <PieChart
        animate
        startAngle={270}
        animationDuration={700}
        animationEasing="ease-in-out"
        data={arr}
        // label={({ dataEntry }) => dataEntry.value}
        // labelStyle={{
        //   fontSize: "2px",
        // }}
        // labelPosition={90}
        onClick={(e, idx) => {
          if (no_highlight || dataTypePick.index) {
            return;
          }
          highlight_pie(idx);
        }}
        lineWidth={23}
        radius={45}
        // segmentsShift={(index) => (data[index].selected ? 1 : 0)}
        segmentsStyle={(index) =>
          data[index].selected
            ? {
                strokeWidth: 13,
                transition: "all 0.5s",
                cursor: "pointer",
              }
            : {
                cursor: "pointer",
              }
        }
      ></PieChart>
      <div className={styles.innerText}>
        {!isNull(selected_index) ? (
          <div>
            <div
              style={{ color: `${itemData.color}`, textAlign: "center" }}
              className={styles.number}
            >
              {toTitleCase(_.startCase(itemData[level1.param2]))}
            </div>
            <div
              style={{ color: `${itemData.color}` }}
              className={styles.number}
            >
              Amount : {generalMoney(itemData[level1.param1]).slice(0, -2)}
              <span className={styles.afterDecimal}>
                {itemData[level1.param1].slice(-2)}
              </span>
            </div>
            <div
              style={{ color: `${itemData.color}` }}
              className={styles.number}
            >
              Percentage : {percentage.slice(0, -2)}
              <span className={styles.afterDecimal}>
                {percentage.slice(-2)}
              </span>
              %
            </div>
          </div>
        ) : (
          <div className={styles.totalDiv}>
            <div>
              Total:{" "}
              <span className={styles.number}>${totVal.slice(0, -2)}</span>
              <span className={styles.afterDecimal}>{totVal.slice(-2)}</span>
            </div>
            <div>
              Percentage : {totalPer.slice(0, -2)}
              <span className={styles.afterDecimal}>{totalPer.slice(-2)}</span>%
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pie;
