import React from "react";
import styles from "./PasswordRow.module.css";

const PasswordRow = ({ val_arr }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.one}>
          <div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: val_arr
                  ? val_arr[0]
                    ? "#52da9c"
                    : "red"
                  : {},
              }}
            ></div>
          </div>
          <div className={styles.content}>Have 8 characters minimum</div>
        </div>
        <div className={styles.two}>
          <div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: val_arr
                  ? val_arr[1]
                    ? "#52da9c"
                    : "red"
                  : {},
              }}
            ></div>
          </div>
          <div className={styles.content}>Have one Uppercase character</div>
        </div>
        <div className={styles.three}>
          <div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: val_arr
                  ? val_arr[2]
                    ? "#52da9c"
                    : "red"
                  : {},
              }}
            ></div>
          </div>
          <div className={styles.content}>Have one Number</div>
        </div>
        <div className={styles.four}>
          <div>
            <div
              className={styles.circle}
              style={{
                backgroundColor: val_arr
                  ? val_arr[3]
                    ? "#52da9c"
                    : "red"
                  : {},
              }}
            ></div>
          </div>
          <div className={styles.content}>Have one Lowercase character</div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRow;
