import { SEND_ALERT, TEXT_BLACK_3 } from "@plootus/common";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "./FromToPicker.module.css";
import Picker from "./Picker/Picker";

const FromToPicker = ({
  total,
  rows1,
  rows2,
  fromPicker,
  setFromPicker,
  toPicker,
  setToPicker,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <span className={styles.fromPicker}>
        <Picker
          rows={rows1}
          {...fromPicker}
          handlePress={() => {
            setFromPicker((prevState) => ({
              ...prevState,
              visible: !prevState.visible,
            }));
          }}
          handleClick={(index) => {
            if (index >= toPicker.index) {
              dispatch({
                type: SEND_ALERT,
                payload: {
                  title: "Invalid",
                  textContent: "Please enter valid start year",
                },
              });
            } else {
              setFromPicker({ visible: false, index });
            }
          }}
        />
      </span>
      <span className={styles.text} style={{ color: TEXT_BLACK_3 }}>
        {" "}
        to{" "}
      </span>
      <span className={styles.fromPicker}>
        <Picker
          {...toPicker}
          rows={rows2}
          handlePress={() => {
            setToPicker((prevState) => ({
              ...prevState,
              visible: !prevState.visible,
            }));
          }}
          handleClick={(index) => {
            if (index <= fromPicker.index) {
              dispatch({
                type: SEND_ALERT,
                payload: {
                  title: "Invalid",
                  textContent: "Please enter valid end year",
                },
              });
            } else {
              setToPicker({ visible: false, index });
            }
          }}
        />
      </span>
    </div>
  );
};

export default FromToPicker;
