import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F8FAFC',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(20),
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(24),
    }
  },
  contentWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  title: {
    fontSize: '2rem',
    fontWeight: 700,
    color: '#1E293B',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem'
    }
  },
  highlightedText: {
    color: '#4361EE'
  },
  description: {
    fontSize: '1.125rem',
    color: '#64748B',
    maxWidth: '800px',
    margin: '0 auto',
    lineHeight: 1.6
  }
}));

const BlogHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.contentWrapper}>
          <Typography variant="h1" className={classes.title}>
            Stay Informed,{' '}
            <span className={classes.highlightedText}>
              Stay Empowered!
            </span>
          </Typography>
          <Typography className={classes.description}>
            Get weekly insights with our featured newsletter and explore a library of in-depth 
            articles on smarter financial planning, retirement strategies, and wealth growth.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default BlogHeader;