import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#E2E8F0',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4361EE',
        borderWidth: '1px'
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 16px',
      '&::placeholder': {
        color: '#94A3B8',
        opacity: 1
      }
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: theme.spacing(1)
    }
  },
  searchIcon: {
    color: '#94A3B8'
  }
}));

const BlogSearch = ({ searchQuery, onSearchChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search articles or keywords here"
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        className={classes.searchField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default BlogSearch;