import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from './Start.module.css';
import {
  GENERAL_GRAPH,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  google_login,
  facebook_login,
  tokenSelector,
  BLUE_PLOOT,
  does_user_exist
} from '@plootus/common';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }
  },
  typography: { useNextVariants: true }
});

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const Start = ({ closeModal, nextPress, setSignupData, loginopenModal }) => {
  const { token } = useSelector(tokenSelector);
  const [route, setRoute] = useState(false);
  const [addRoute, setAddRoute] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isEmailTaken, setIsEmailTaken] = useState(false);

  // Handle token-based authentication redirects
  React.useEffect(() => {
    (async () => {
      if (token) {
        console.log('Updated from Login modal');
        const userData = await dispatch(getUser());
        await localStorage.setItem('jwt_token', token);
        if (userData.userType === 'advisor') {
          await dispatch(get_clients());
          setAddRoute(true);
        } else {
          dispatch({
            type: GENERAL_GRAPH,
            payload: await getEditAssumptionsInitialState(token, userData),
          });
          setRoute(true);
        }
      }
    })();
  }, [token, dispatch]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError('');
    setIsEmailTaken(false);

    if (newEmail && !validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
    }
  };

  const handleEmailSignup = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const emailExists = await dispatch(does_user_exist(email));
      if (emailExists) {
        setIsEmailTaken(true);
      } else {
        setSignupData(prevState => ({
          ...prevState,
          email: email,
          socialLogin: null,
          socialId: null
        }));
        nextPress();
      }
    } catch (error) {
      setEmailError('Error checking email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    closeModal();
    loginopenModal();
  };

  const responseGoogle = async (response) => {
    try {
      setLoading(true);
      const { profileObj } = response;
      const emailExists = await dispatch(does_user_exist(profileObj.email));
      
      if (emailExists) {
        // If email exists, try to log in with Google credentials
        await dispatch(google_login(response.tokenId));
        setEmail(profileObj.email);
        setIsEmailTaken(true);
        return;
      }

      setSignupData(prevState => ({
        ...prevState,
        email: profileObj.email,
        firstName: profileObj.givenName,
        lastName: profileObj.familyName,
        socialLogin: 'google',
        socialId: profileObj.googleId
      }));
      nextPress();
    } catch (error) {
      console.error('Google login error:', error);
      setEmailError('Google login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const responseFacebook = async (response) => {
    try {
      setLoading(true);
      const { email, name, id } = response;
      
      // Check if email exists
      if (email) {
        const emailExists = await dispatch(does_user_exist(email));
        
        if (emailExists) {
          // If email exists, try to log in with Facebook credentials
          await dispatch(facebook_login(response.accessToken));
          setEmail(email);
          setIsEmailTaken(true);
          return;
        }
        
        // Extract first and last name from full name
        const nameParts = name.split(' ');
        const firstName = nameParts[0] || '';
        const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
        
        setSignupData(prevState => ({
          ...prevState,
          email: email,
          firstName: firstName,
          lastName: lastName,
          socialLogin: 'facebook',
          socialId: id
        }));
        nextPress();
      } else {
        setEmailError('Facebook login failed. Email access is required.');
      }
    } catch (error) {
      console.error('Facebook login error:', error);
      setEmailError('Facebook login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (route) return <Redirect to='/auth/401k' />;
  if (addRoute) return <Redirect to='/auth/adash' />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Get Started!</h1>
        <button 
          onClick={closeModal} 
          className={styles.closeButton}
          aria-label="Close"
        >
          <img src="/cross-thin.png" alt="close" className={styles.closeIcon} />
        </button>
      </div>

      <div className={styles.formContainer}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>
            Email ID<span className={styles.required}>*</span>
          </label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className={`${styles.input} ${(emailError || isEmailTaken) ? styles.inputError : ''}`}
            placeholder="Enter your email"
            disabled={loading}
          />
          {emailError && (
            <span className={styles.errorMessage}>{emailError}</span>
          )}
          {isEmailTaken && (
            <div className={styles.loginPrompt}>
              <span className={styles.errorMessage}>
                This email is already registered.
              </span>
              <button onClick={handleLogin} className={styles.loginLink}>
                Log in instead
              </button>
            </div>
          )}
        </div>

        <button
          onClick={handleEmailSignup}
          className={styles.emailButton}
          disabled={loading || !email || emailError}
        >
          {loading ? (
            <MuiThemeProvider theme={theme}>
              <CircularProgress size={24} color="inherit" />
            </MuiThemeProvider>
          ) : (
            'Sign up with Email'
          )}
        </button>

        <div className={styles.divider}>
          <span className={styles.dividerText}>OR</span>
        </div>

        <div className={styles.socialButtons}>
          <GoogleLogin
            clientId="489489080349-shsamdnahde9q73s76e7b71mrjm9snvc.apps.googleusercontent.com"
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled || loading}
                className={styles.socialButton}
              >
                <img src="/google-logo.svg" alt="" className={styles.socialIcon} />
                <span>Continue with Google</span>
              </button>
            )}
            onSuccess={responseGoogle}
            onFailure={(error) => {
              console.error('Google login failed:', error);
              setEmailError('Google login failed. Please try again.');
            }}
            cookiePolicy={'single_host_origin'}
          />



          {/* <button className={styles.socialButton} disabled={loading}>
            <img src="/appleblack.png" alt="" className={styles.socialIcon} />
            <span>Continue with Apple</span>
          </button> */}
        </div>
      </div>

      {loading && (
        <div className={styles.loadingOverlay}>
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={40} />
          </MuiThemeProvider>
        </div>
      )}
    </div>
  );
};

export default Start;