import React from "react";
import styles from "./Button.module.css";

const Button = ({ action, text, myStyle }) => {
  return (
    <button onClick={action} className={styles.Btn} style={myStyle}>
      {text}
    </button>
  );
};

export default Button;
