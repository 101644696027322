import { GREEN_PLOOT, RED_PLOOT } from '@plootus/common';
import { Form, Input, Popconfirm, Switch, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import getColumnSearchProps from './getSearchColumnProps';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import AdminNav from './AdminNav';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'switch' ? (
      <Switch defaultChecked={record.isActivated} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AdminDashboard = (props) => {
  const [{ table, loading }, setTable] = React.useState({
    table: [],
    loading: true
  });
  const [searchedData, setSearchText] = React.useState({
    searchText: '',
    searchedColumn: ''
  });
  const [editingKey, setEditingKey] = React.useState('');
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;
  const [seeAll, setSeeAll] = React.useState(false);
  const [totalAsset, setTotalAsset] = useState();

  const colProps = { searchedData, setSearchText };

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...table];
      const index = newData.findIndex((item) => key === item.key);

      try {
        const { status } = await axios.post(
          `https://www.plootus.com/api/user/adminUpdate?userId=${table[index].uuid}`,
          {
            isActivated: row.isActivated
          },
          {
            headers: {
              Authorization: localStorage.getItem('admin_token')
            }
          }
        );

        if (status === 200) {
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setTable((prev) => ({ ...prev, table: newData }));
            setEditingKey('');
          } else {
            newData.push(row);
            setTable((prev) => ({ ...prev, table: newData }));
            setEditingKey('');
          }
        }
      } catch (err) {
        if (err.response?.status === 401) {
          localStorage.removeItem('admin_token');
        }
        console.log(err);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = async (key) => {
    const newTable = [...table];
    const index = newTable.findIndex((item) => key === item.key);

    try {
      const { status } = await axios.get(
        `https://www.plootus.com/api/user/deleteUser?userId=${table[index].uuid}`,
        {
          headers: {
            Authorization: localStorage.getItem('admin_token')
          }
        }
      );

      if (status === 200) {
        setTable((prev) => ({
          ...prev,
          table: newTable.filter((item) => item.key !== key)
        }));
      }
    } catch (err) {
      if (err.response?.status === 401) {
        localStorage.removeItem('admin_token');
      }
    }
  };

  const columns = [
    {
      title: 'Sr. No',
      dataIndex: 'key',
      sorter: (a, b) => +a.key - +b.key
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email', 'Email', colProps)
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      ...getColumnSearchProps('firstName', 'First Name', colProps)
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      ...getColumnSearchProps('lastName', 'Last Name', colProps)
    },
    {
      title: 'DOB',
      dataIndex: 'dob'
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNo'
    },
    {
      title: 'User Type',
      dataIndex: 'userType'
    },
    {
      title: '401k ($)',
      dataIndex: 'approx_401k_balance'
    },
    { title: 'Employer Name', dataIndex: 'employerName' },
    { title: 'Employer EIN', dataIndex: 'employerEin' },
    {
      title: 'Assets ($)',
      dataIndex: 'totalAssets',
      render: (text, record) => {
        return record.totalAssets || '--';
      }
    },
    {
      title: 'Liabilities ($)',
      dataIndex: 'totalLiabilities',
      render: (text, record) => {
        return record.totalLiabilities || '--';
      }
    },
    {
      title: 'Networth ($)',
      dataIndex: 'totalNetworth',
      render: (text, record) => record.totalNetworth || '--'
    },
    {
      title: 'Is Linked',
      dataIndex: 'isLinked',
      render: (data) => {
        return data ? (
          <span style={{ color: GREEN_PLOOT }}>Yes</span>
        ) : (
          <span style={{ color: RED_PLOOT }}>No</span>
        );
      }
    },
    {
      title: 'Is Activated',
      dataIndex: 'isActivated',
      editable: true,
      render: (data) => {
        return <Switch defaultChecked={data} disabled />;
      }
    },
    {
      title: 'Method',
      dataIndex: 'method',
      ...getColumnSearchProps('method', 'Method', colProps)
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('LLL'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      render: (text) => moment(text).format('LLL')
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href='javascript:;'
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}
            >
              Save
            </a>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        );
      }
    },
    {
      title: 'Delete',
      align: 'center',
      render: (_, record) =>
        table.length >= 1 ? (
          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        ) : null
    }
  ];

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          'https://www.plootus.com/api/user/getAllUser',
          {
            headers: {
              Authorization: localStorage.getItem('admin_token')
            }
          }
        );
        console.log(data);

        // console.log(data.result[0]);

        setTable({
          table: data?.result
            ?.map((item, idx) => ({
              ...item,
              key: (idx + 1).toString()
            }))
            .reverse(),
          loading: false
        });
      } catch (err) {
        if (err.response?.status === 401) {
          localStorage.removeItem('admin_token');
        }
        console.log('error in all users');
      }
    })();
  }, []);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'isActivated' ? 'switch' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <>
      <AdminNav {...props} />
      <div style={{ overflowX: 'scroll' }}>
        {!loading && (
          <Typography style={{ padding: 12 }}>
            See All
            <Switch
              checked={seeAll}
              onChange={() => setSeeAll(!seeAll)}
              style={{ marginLeft: 12 }}
            />
          </Typography>
        )}
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell
              }
            }}
            columns={mergedColumns}
            dataSource={table}
            scroll={{ x: 'max-content' }}
            style={{
              border: '1px solid #416ce1'
            }}
            loading={loading}
            pagination={seeAll ? false : { pageSize: 50 }}
          />
        </Form>
      </div>
    </>
  );
};

export default AdminDashboard;
