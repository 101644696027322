import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import HeroSection from './components/HeroSection';
import WhatPlootusDoes from './components/WhatPlootusDoes';
import FinanceFeatures from './components/FinanceFeatures';
import RetirementCalculator from './components/RetirementCalculator';
import TestimonialsSection from './components/TestimonialsSection';
import BlogSection from './components/BlogSection';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    width: "100%",
  },
  container: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <HeroSection />
        <WhatPlootusDoes />
        <FinanceFeatures />
        <RetirementCalculator />
        <TestimonialsSection />
        <BlogSection />
      </Container>
    </div>
  );
};

export default Home;
