import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

// Import components
import FAQSection from './components/FAQSection';
import PricingHero from './components/PricingHero';
import PlanSelection from './components/PlanSelection';
  // Developed and managed by Mejona technology

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#ffffff'
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

const Pricing = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <PricingHero />
        <PlanSelection />
        {/* <FAQSection /> */}
    </div>
  );
};

export default Pricing;