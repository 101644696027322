import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import useEmployerSearch from '../../Reusables/VisualAndAccord/Hooks/useEmployerSearch';

import {
  calculateRetirementAssessts,
  getCurrentAge,
  calculateRetirementGap,
  get_networth,
  BLUE_PLOOT,
  strategy_constants,
  usePickerModal,
  isAdvisorSelector,
  currentClientSelector,
  employerNewDataSelector,
  currentUserDataSelector,
  homeScreenSelector,
  CHANGE_CLIENT,
  CHANGE_TOKEN_STATE,
  GENERAL_GRAPH,
  GENERAL_USER_REDUCER,
  getEditAssumptionsInitialState,
  getUser,
  get_clients,
  logout_user,
  SEND_ALERT,
} from '@plootus/common';
import EditAssumptions from '../EditAssumptions/EditAssumptions';
import { Card } from '@material-ui/core';
import styles from './Dashboard.module.css';
import NetworthGraph from './Graphs/NetworthGraph';
import CashflowGraph from './Graphs/CashflowGraph';
import Graph from '../HomePage/EditAssumptions/Graphs/AssetsGraph';
import GapGraph from '../HomePage/EditAssumptions/Graphs/GapGraph';
import useWindowDimensions from '../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import SmallScreen from '../HomePage/EditAssumptions/SmallScreen';
import FeesGraph from './Graphs/FeesGraph';
import DashboardTable from './Table/DashboardTable';
import NotLinked from './NotLinkedDashboard/NotLinked';
import FutureExpenses from '../FutureExpenses/FutureExpenses';
import SmallFutureGraph from './Graphs/SmallFutureGraph';
import SmallFutureModal from './SmallFutureModal';
import RetirementStatus from '../HomePage/EditAssumptions/RetirementStatus/RetirementStatus';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Picker from '../../Utils/Containers/Picker/Picker';
import { Link, useLocation, Redirect } from 'react-router-dom';
import Calculator from '../401kCalculator/Calculator';
import { Helmet } from 'react-helmet';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

const Dashboard = () => {
  const token = localStorage.getItem('jwt_token');
  const dispatch = useDispatch();
  const [showDobModal, setShowDobModal] = useState(false);
  const [drop, setDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userReducer);
  const general = useSelector((state) => state.generalReducer);

  const { inflation, futureTotal } = general.futureExpenses;

  const [expire, setExpire] = useState(false);
  const props = useEmployerSearch();

  const myL = useRef('/');

  myL.current = useLocation().pathname;

  // useEffect(() => {
  //   async function f() {
  //     setLoading(true);
  //     let userDataTemp;
  //     if (token) {
  //       dispatch({ type: CHANGE_TOKEN_STATE, payload: { token } });
  //       // Get user data and store to reducer
  //       userDataTemp = await dispatch(getUser());
  //       if (userDataTemp?.status === 401) {
  //         // Remove session token

  //         dispatch({
  //           type: SEND_ALERT,
  //           payload: {
  //             title: 'Expiration',
  //             textContent: 'Session Expired'
  //           }
  //         });
  //         dispatch({
  //           type: GENERAL_USER_REDUCER,
  //           payload: { session_expired: false }
  //         });

  //         await localStorage.removeItem('jwt_token');
  //         dispatch(logout_user());

  //         setExpire(true);
  //         // Navigate to onboard
  //         return;
  //       }
  //       console.log('user', user);
  //     }

  //     if (userDataTemp?.userType === 'advisor') {
  //       const temp = await dispatch(get_clients());
  //       if (!temp.length) {
  //         localStorage.removeItem('jwt_token');
  //         dispatch(logout_user());

  //         dispatch({
  //           type: SEND_ALERT,
  //           payload: {
  //             title: 'Alert',
  //             textContent: 'Please contact your client.'
  //           }
  //         });

  //         return <Redirect to='/' />;
  //       } else {
  //         const index = parseInt(await localStorage.getItem('selectedClient'))
  //           ? parseInt(await localStorage.getItem('selectedClient'))
  //           : 0;
  //         await dispatch({
  //           type: CHANGE_CLIENT,
  //           payload: index
  //         });

  //         await dispatch({
  //           type: GENERAL_GRAPH,
  //           payload: await getEditAssumptionsInitialState(token, temp[index])
  //         });
  //         setLoading(false);
  //       }
  //     } else {
  //       if (userDataTemp?.dob === null) {
  //         setShowDobModal(true);
  //       }
  //       await dispatch({
  //         type: GENERAL_GRAPH,
  //         payload: await getEditAssumptionsInitialState(token, userDataTemp)
  //       });
  //       setLoading(false);
  //     }
  //   }

  //   f();
  // }, [
  //   user.advisor.selectedClient,
  //   user.token,
  //   localStorage.getItem('jwt_token')
  // ]);

  const currentAgeRef = useRef();

  const graph = useSelector((state) => state.graphReducer);
  const [modalIsOpen, setModalOpen] = useState(false);

  // initialize graph values using the utility function
  const { width } = useWindowDimensions();
  const [editMode, setEditMode] = useState(false);

  const tp = useSelector(currentUserDataSelector);

  const { assets } = useSelector(homeScreenSelector);

  useEffect(() => {
    (async () => {
      dispatch(get_networth());
      setLoading(false);
    })();
  }, []);

  // Calculate assets chart if any of the dependent values are updated
  useEffect(() => {
    let dob;
    if (user.token) {
      if (user.userData.userType === 'advisor')
        dob = user.advisor?.clients[user.advisor?.selectedClient]?.dob;
      else dob = user?.userData?.dob;

      currentAgeRef.current =
        graph.sliderOptions?.currentAge?.value ||
        getCurrentAge(dob || '1991-01-01');
    } else {
      currentAgeRef.current = graph.sliderOptions.currentAge?.value;
    }
    const { retireAge, contribution } = graph.sliderOptions;
    // calling utility function for calculating assets
    calculateRetirementAssessts(
      retireAge.value,
      currentAgeRef.current,
      graph.openingBalance,
      contribution.value,
      graph.strategyIndex,
      dispatch
    );
  }, [graph.sliderOptions, graph.strategyIndex, graph.openingBalance]);
  // Calculate retirement gap chart only when the assets are calculated
  // so the dependency is graph.graph.results.retirementAssests
  useEffect(() => {
    const { retireAge, lifeExp } = graph.sliderOptions;
    // calling utility function for calculating retirement gap

    const futureTotalWithInf =
      futureTotal *
      (1 + inflation / 100) ** (retireAge.value - currentAgeRef.current);

    calculateRetirementGap({
      retireAge: retireAge.value,
      lifeExp: lifeExp.value,
      strategyIndex: graph.strategyIndex,
      expense_home:
        user.token && tp?.isLinked
          ? futureTotal
            ? futureTotalWithInf
            : graph.expense_home
          : graph.sliderOptions?.expenses?.value,
      retirementAssests: graph.graph.results.retirementAssests,
      socialSecurity: graph.socialSecurity,
      inflation: 1 + inflation / 100,
      dispatch,
    });
  }, [
    // graph.graph.results.retirementAssests,
    // graph.sliderOptions.lifeExp,
    assets,
  ]);

  const state = useSelector((state) => state);
  const User = isAdvisorSelector(state)
    ? currentClientSelector(state)
    : user.userData;

  const { employerEin } = User;
  const [strategy, setStrategy] = useState(User.strategy);
  useEffect(() => {
    setStrategy(User.strategy);
  }, [User.strategy]);

  const { picker, negativeModal, setIndex } = usePickerModal(
    (idx) => {
      setStrategy(idx);
    },
    strategy,
    true
  );
  const { funds: newFunds } = useSelector(employerNewDataSelector);

  /* Modal for exclaim */

  const [amodalIsOpen, setAModal] = useState(false);

  const amodalOpen = () => {
    setAModal(true);
  };
  const amodalClose = () => {
    setAModal(true);
  };

  /*  End for Modal for exclaim */

  if (loading) {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MuiThemeProvider theme={theme}>
          <CircularProgress size={40} />
        </MuiThemeProvider>
      </div>
    );
  }

  if (user.userData?.userType === 'primary') {
    if (user && user.userData && !user.userData?.isLinked) {
      return <NotLinked />;
    }
  } else {
    if (
      user &&
      user.userData &&
      !user.advisor.clients[user.advisor.selectedClient].isLinked
    ) {
      return <NotLinked />;
    }
  }

  return (
    <>
      <Helmet>
        <title>Plootus</title>
      </Helmet>
      <div className={styles.root}>
        <div className={styles.row1}>
          <div className={styles.feesSaved}>
            <FeesGraph coming={false} strategy={strategy} />
          </div>
          <div className={styles.employerTable}>
            <div className={styles.picker}>
              {employerEin || newFunds.length ? (
                <>
                  <div className={styles.tp}>Select A Strategy</div>
                  <div className={styles.tp}>
                    <Picker
                      rows={strategy_constants}
                      {...picker}
                      handlePress={negativeModal}
                      setIndex={setIndex}
                      myStyle={{ width: '100%', padding: '0px', margin: '0px' }} // style for the main conatiner of picker
                      myStyle2={{
                        width: '100%',
                        margin: '0px',
                        fontSize: '13px',
                      }} // style for the actual select box
                      myStyle3={{ width: '100%' }}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className={styles.mainTable}>
              <div className={styles.table}>
                <DashboardTable
                  strategy={strategy}
                  setStrategy={setStrategy}
                  setIndex={setIndex}
                  {...picker}
                  handlePress={negativeModal}
                />
              </div>
              {!employerEin && newFunds.length ? (
                <div className={styles.seeMoreCont}>
                  <Link className={styles.seeMore} to="/auth/401k">
                    See More {'>'}
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Calculator />
        <div className={styles.row3}>
          <div className={styles.networth}>
            <NetworthGraph />
          </div>
          <div className={styles.cashflow}>
            <CashflowGraph />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

/* In this page the structure is done considering all  the margins and paddings.
    The inline styles are temporary.
*/
