import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import SecurityHero from './components/SecurityHero';
import SecurityPillars from './components/SecurityPillars';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(9), // Add top padding to account for navbar
    backgroundColor: '#ffffff'
  }
}));

const Security = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} component="main">
      <Container maxWidth="lg">
        <SecurityHero />
        <SecurityPillars />
      </Container>
    </Box>
  );
};

export default Security;