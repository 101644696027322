import { strategy_constants } from "@plootus/common";
import React from "react";
import { useDispatch } from "react-redux";
import Picker from "../../../Utils/Containers/Picker/Picker";
import MyTooltip from "../../../Utils/Tooltip/Tooltip";
import styles from "./TableHeader.module.css";

const TableHeader = ({
  strategy,
  setStrategy,
  setIndex,
  handlePress,
  visible,
  index,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div className={styles.srno}>S. No</div>
      <div className={styles.name}>Name</div>
      <div className={styles.ticker}>Ticker</div>
      <div className={styles.strat}>
        <div className={styles.tpHead}>Plootus Allocation</div>
        <div className={styles.tp}>
          <Picker
            rows={strategy_constants}
            visible={visible}
            index={index}
            handlePress={handlePress}
            setIndex={setIndex}
            myStyle={{ width: "100%", padding: "0px", margin: "0px" }} // style for the main conatiner of picker
            myStyle2={{ width: "100%", margin: "0px", fontSize: "13px" }} // style for the actual select box
            myStyle3={{ width: "100%", fontSize: "13px" }}
          />
        </div>
      </div>
      <div className={styles.rating}>
        Plootus Rating
        <MyTooltip
          title="Plootus rating is based on our patent-pending algorithm based on various risk and return parameters."
          render={(props) => (
            <img
              style={{
                width: "15px",
                height: "15px",
                alignItems: "center",
                cursor: "pointer",
                fontSize: "15px",
                margin: "0px 5px",
              }}
              src="/question.png"
              alt=""
            />
          )}
        />
      </div>
    </div>
  );
};

export default TableHeader;
