import React from 'react';
import styles from './TableHeader.module.css';
import { Row, Col } from 'reactstrap';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import MyTooltip from '../../../Utils/Tooltip/Tooltip';

const TableHeader = ({ current }) => {
  const { width } = useWindowDimensions();
  if (width > 576) {
    return (
      <div className={styles.container}>
        <Row noGutters>
          <Col xs="1" className={`${styles.columnText} ${styles.first}`}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>S.No</div>
              <div className={styles.text1}></div>
              <div className={styles.tooltip}></div>
            </div>
          </Col>
          <Col xs="4" className={styles.columnText}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>Investment</div>
              <div className={styles.text1}>Options</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="The investment options available in your 401k/403b  account"
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          {current ? (
            <Col xs="2" className={styles.columnText}>
              <div className={styles.textWrapper}>
                <div className={styles.text}>Current</div>
                <div className={styles.text1}>Allocation</div>
                <div className={styles.tooltip}>
                  <MyTooltip
                    title="401k/403b  Current Allocation"
                    render={(props) => (
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          fontSize: '15px',
                          marginLeft: '10px',
                          marginBottom: '5px',
                        }}
                        src="/question.png"
                        alt=""
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          ) : null}
          <Col xs={current ? '2' : '3'} className={styles.columnText}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>Proposed</div>
              <div className={styles.text1}>Allocation</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="The percentage reflects how much the user should invest in a fund/investment option based on their chosen investment strategy."
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col
            xs={current ? '3' : '4'}
            className={`${styles.columnText} ${styles.last}`}
          >
            <div className={styles.textWrapper}>
              <div className={styles.text}>Plootus</div>
              <div className={styles.text1}>Rating</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="Plootus rating is based on our patent-pending algorithm based on various risk and return parameters."
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Row noGutters>
          <Col xs="4" className={styles.columnText}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>Investment</div>
              <div className={styles.text1}>Options</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="The investment options available in your 401k/403b  account"
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          {current ? (
            <Col xs="2" className={styles.columnText}>
              <div className={styles.textWrapper}>
                <div className={styles.text}>Current</div>
                <div className={styles.text1}>Allocation</div>
                <div className={styles.tooltip}>
                  <MyTooltip
                    title="401k/403b  Current Allocation"
                    render={(props) => (
                      <img
                        style={{
                          width: '15px',
                          height: '15px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          fontSize: '15px',
                          marginLeft: '10px',
                          marginBottom: '5px',
                        }}
                        src="/question.png"
                        alt=""
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          ) : null}
          <Col xs={current ? '2' : '3'} className={styles.columnText}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>Proposed</div>
              <div className={styles.text1}>Allocation</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="The percentage reflects how much the user should invest in a fund/investment option based on their chosen investment strategy."
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col xs={current ? '4' : '5'} className={styles.columnText}>
            <div className={styles.textWrapper}>
              <div className={styles.text}>Plootus</div>
              <div className={styles.text1}>Rating</div>
              <div className={styles.tooltip}>
                <MyTooltip
                  title="Plootus rating is based on our patent-pending algorithm based on various risk and return parameters."
                  render={(props) => (
                    <img
                      style={{
                        width: '15px',
                        height: '15px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }}
                      src="/question.png"
                      alt=""
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default TableHeader;
