import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import AdminRoute from '../AuthPages/AdminRoute';
import Admin from '../../components/AdminExcel/Admin';
import EmailUsers from './EmailUsers';
import LoginAdmin from './Login';

const AdminDashboard = React.lazy(() => import('./AdminDashboard'));

function AdminPages(props) {
  return (
    <div>
      <Suspense fallback={<div></div>}>
        <Switch>
          <AdminRoute
            path='/admin/dashboard'
            Component={() => <AdminDashboard {...props} />}
          />
          <AdminRoute
            path='/admin/email-users'
            Component={() => <EmailUsers {...props} />}
          />
          <AdminRoute path='/admin/excel-upload' Component={() => <Admin />} />
          <Route
            path='/admin'
            render={(props) =>
              localStorage.getItem('admin_token') ? (
                <Redirect to='/admin/email-users' />
              ) : (
                <LoginAdmin {...props} />
              )
            }
          />
        </Switch>
      </Suspense>
    </div>
  );
}

export default AdminPages;
