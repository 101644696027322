import {
  BLUE_PLOOT,
  GREEN_PLOOT,
  RED_PLOOT,
  roundNum,
  TEXT_BLACK_3,
} from '@plootus/common';
import React from 'react';
import DataNotAvailable from '../../../../Utils/Texts/DataNotAvailable';
import CanExpandItemNested from '../../AccordItem/CanExpandNested';
import DropDownItemNested from '../../AccordItem/DropDownNested';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import styles from './NestedAccordPart.module.css';

const NestedAccordPart = ({
  data,
  mappingData,
  expandNested,
  highlight_pie,
  ...rest
}) => {
  const { level1, level2, level3, level4 } = mappingData;
  const { width } = useWindowDimensions();
  const { api_name } = rest;

  if (!data.length) return <DataNotAvailable box />;

  const len = data.length - 1;
  return (
    <div
      className={api_name === 'networth' ? styles.netWrapper : styles.wrapper}
    >
      {data.map((item, index) => {
        const valueColor =
          item[level1.param2] === 'creditCard' ? 'EXPENSE' : 'INCOME';
        const first = index === 0;
        const last = index === len;
        return (
          <CanExpandItemNested
            {...rest}
            first={first}
            last={last}
            level={1}
            circleSize={width > 576 ? 12 : 10}
            textStyle={{ fontSize: 15 }}
            fontSize={width > 576 ? 20 : 18}
            valueColor={valueColor}
            dispatchAction={() => highlight_pie(index)}
            rightValue={roundNum(item[level1.param1], 0, 2)}
            leftValue={item[level1.param2]}
            color={GREEN_PLOOT}
            data={item[level1.param4]}
            selected={item.selected}
            index={index}
            renderItem={(item, index, first, last) => {
              return (
                <CanExpandItemNested
                  {...rest}
                  first={first}
                  level={2}
                  last={last}
                  paddingLeft="16px"
                  valueColor={valueColor}
                  dispatchAction={() => expandNested(index, 2)}
                  rightValue={roundNum(item[level2.param1], 0, 2)}
                  leftValue={item[level2.param2]}
                  circleSize={width > 576 ? 11 : 8}
                  textStyle={{ fontSize: 14, color: BLUE_PLOOT }}
                  fontSize={width > 576 ? 18 : 16}
                  color={BLUE_PLOOT}
                  data={item[level2.param3]}
                  selected={item.selected}
                  index={index}
                  renderItem={(item, index) => {
                    return (
                      <CanExpandItemNested
                        circleSize={width > 576 ? 10 : 8}
                        textStyle={{ fontSize: 12, color: TEXT_BLACK_3 }}
                        paddingLeft="24px"
                        {...rest}
                        valueColor={valueColor}
                        level={3}
                        fontSize={width > 576 ? 17 : 14}
                        dispatchAction={() => expandNested(index, 3)}
                        rightValue={roundNum(item[level3.param1], 0, 2)}
                        leftValue={item[level3.param2]}
                        leftBottom={item[level3.param4]}
                        color={RED_PLOOT}
                        data={item[level3.param5]}
                        selected={item.selected}
                        index={index}
                        renderItem={(item, index, first, last, childColor) => {
                          const rightValue =
                            item[level4.param1] || item[level4.param6];
                          const leftTopValue =
                            item[level4.param3] || item[level4.param8];
                          const leftBottom =
                            item[level4.param2] || item[level4.param7];
                          const leftBottomKey =
                            (item[level4.param2] ? level4.param2 : undefined) ||
                            (item[level4.param7] ? level4.param7 : undefined);
                          const rightBottom =
                            item[level4.param5] || item[level4.param9];
                          return (
                            <DropDownItemNested
                              childColor={childColor}
                              first={first}
                              level={4}
                              last={last}
                              paddingLeft="44px"
                              rightValue={roundNum(rightValue, 0, 2)}
                              leftTopValue={leftTopValue}
                              leftBottom={leftBottom}
                              rightBottom={rightBottom}
                              leftBottomKey={leftBottomKey}
                              param4="INCOME"
                              fontSize={width > 576 ? 17 : 14}
                              index={index}
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
        );
      })}
    </div>
  );
};

export default NestedAccordPart;
