import React, { useState } from 'react';
import './Accordion.css';

const Accordion = (props) => {
  const [active, setActive] = useState(false);

  const toogleActive = () => {
    setActive(!active);
  };

  const { first, last, index } = props;
  return (
    <div className="accordion-section" style={props.style}>
      <div
        className="accordion-title"
        onClick={toogleActive}
        style={{
          borderBottom: last ? '0px' : '1.2px solid rgb(141, 141, 141)',
          borderTopLeftRadius: first ? '5px' : '0px',
          borderTopRightRadius: first ? '5px' : '0px',
          borderBottomLeftRadius: last ? '5px' : '0px',
          borderBottomLeftRadius: last ? '5px' : '0px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ padding: '0px 10px', fontSize: '14px' }}>
          {index + 1}.
        </div>
        <div
          className="titleStyle"
          style={{ fontWeight: active ? 'bold' : 'normal' }}
        >
          {props.title}
        </div>
        <div class={`plus-minus-toggle ${active ? '' : 'collapsed'}`}></div>
      </div>
      <div
        className="accordion-content"
        style={{ maxHeight: active ? '100%' : '0px' }}
      >
        <div className="accordion-content-para" style={props.contentStyle}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
