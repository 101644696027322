import React, { useEffect } from "react";
import styles from "./Privacy.module.css";
import { Helmet } from "react-helmet";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Plootus</title>
        <meta
          name="description"
          content="Plootus Privacy Policy covers how we protect your private information from unauthorized access, use, or disclosure is our priority."
        />
        <meta
          name="keywords"
          content="Protecting Personal Information, Anonymous Information, Protecting the privacy of children, Confidentiality, Use of Cookies"
        />
      </Helmet>
      <div className="col-sm-12 col-md-12">
        <br />
        <h2
          className="text-center text-blue prominant"
          style={{ marginTop: "80px !important" }}
        >
          Privacy Policy
        </h2>
        <br />
        <div className="blog-content blog-content-2">
          Protecting your private information is our priority. This Statement of
          Privacy applies to Plootus mobile applications and Plootus website
          www.plootus.com owned and operated by Analyze Future LLC and sometimes
          referred as "us", "we", or "our". For the purposes of this Privacy
          Policy, unless otherwise noted, all references to Plootus include
          Plootus mobile applications and www.plootus.com. Plootus through
          application or/and website ("App & Website") provides online service
          to assist its clients to make informed decisions about financial
          planning. By using the Plootus App or website, you consent to the data
          practices described in this statement and as they may be changed from
          time-to-time in the future without prior notice.
          <br />
          <br />
          <b>Collection of your Personal Information</b>
          <br />
          In order to better provide you with our Service, Plootus may collect
          personally identifiable information, such as your:
          <br />
          - First and Last Name
          <br />
          - E-mail Address
          <br />
          - Employer
          <br />
          Plootus may also collect anonymous demographic information, which is
          not unique to you, such as your:
          <br />
          - Age
          <br />
          - Gender
          <br />
          - Individual and/or Household Income & Expenses
          <br />
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use our Service
          through App & website. These may include: (a) registering for an
          account on our app or website; (b) entering a sweepstakes or contest
          sponsored by us or one of our partners; (c) signing up for special
          offers from selected third parties; (d) sending us an email message;
          (e) submitting your credit card or other payment information when
          ordering service on our App & Website. We will use your information
          for, but not limited to, communicating with you in relation to the
          Service you have requested from us. We also may gather additional
          personal or non-personal information in the future. You hereby give
          Plootus permission to combine information you enter or upload for the
          Services with that of other users of the Services and Plootus may use
          your and other users non-identifiable, aggregated data to improve
          the Services or to design promotions.
          <br />
          <br />
          <b>Use of your Personal Information</b>
          <br />
          Plootus collects and uses your personal information to operate its App
          and/or website(s) and deliver the Service you have requested.
          <br />
          Plootus may also use your personally identifiable information to
          inform you of other products or services available from Plootus and
          its affiliates.
          <br />
          <br />
          <b>Sharing Information with Third Parties</b>
          <br />
          Plootus does not sell, rent or lease its customer lists to third
          parties.
          <br />
          Plootus may, from time to time, contact you on behalf of external
          business partners about a particular offering that may be of interest
          to you. In those cases, your unique personally identifiable
          information (e-mail, name, address, telephone number) is transferred
          to the third party. Plootus may share data with trusted partners to
          help perform statistical analysis, send you email or postal mail and
          provide customer support. All such third parties are prohibited from
          using your personal information except to provide these services to
          Plootus, and they are required to maintain the confidentiality of your
          information.
          <br />
          Plootus may disclose your personal information without notice, if
          required to do so by law or to: (a) comply with applicable law or to
          comply with legal process served on Plootus or the site (c) to protect
          the personal safety of users of Plootus, or the public.
          <br />
          <br />
          <b>Tracking User Behavior</b>
          <br />
          Plootus may keep track of the websites and pages our users visit
          within Plootus, in order to determine what Plootus offerings are the
          most popular. This data is used to deliver customized content and
          advertising within Plootus to customers whose behavior indicates that
          they are interested in a particular subject area.
          <br />
          <br />
          <b>Automatically Collected Information</b>
          <br />
          Information about your computer hardware and software may be
          automatically collected by Plootus. This information can include: your
          IP address, browser type, domain names, access times and referring
          website addresses. This information is used for the operation of the
          service, to maintain quality of the service, and to provide general
          statistics regarding use of the Plootus website.
          <br />
          <br />
          <b>Use of Cookies</b>
          <br />
          The Plootus website may use "cookies" to help you personalize your
          online experience. A cookie is a text file that is placed on your hard
          disk by a web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you.
          <br />
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize Plootus pages, or register with Plootus App & Website or
          Service, a cookie helps Plootus to recall your specific information on
          subsequent visits. This simplifies the process of recording your
          personal information, such as billing addresses, shipping addresses,
          and so on. When you return to the same Plootus app or website, the
          information you previously provided can be retrieved, so you can
          easily use the Plootus features that you customized.
          <br />
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the Plootus Service.
          <br />
          <br />
          <b>Links</b>
          <br />
          Plootus app or website contains links to other sites. Please be aware
          that we are not responsible for the content or privacy practices of
          such other sites. We encourage our users to be aware when they leave
          our site and to read the privacy statements of any other site that
          collects personally identifiable information.
          <br />
          <br />
          <b>Security of your Personal Information</b>
          <br />
          Plootus secures your personal information from unauthorized access,
          use, or disclosure. Plootus uses the following methods for this
          purpose:
          <br />
          - SSL Protocol
          <br />
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.
          <br />
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
          <br />
          <br />
          <b>Children Under Thirteen</b>
          <br />
          Plootus does not knowingly collect personally identifiable information
          from children under the age of thirteen. If you are under the age of
          thirteen, you must ask your parent or guardian for permission to use
          this website.
          <br />
          <br />
          <b>Opt-Out & Unsubscribe from Third Party Communications</b>
          <br />
          We respect your privacy and give you an opportunity to opt-out of
          receiving announcements of certain information. Users may opt-out of
          receiving any or all communications from third-party partners of
          Plootus by contacting us here:
          <br />
          - Web page: www.plootus.com
          <br />
          - Email: support@plootus.com
          <br />
          <br />
          <b>E-mail Communications</b>
          <br />
          From time to time, Plootus may contact you via email for the purpose
          of providing announcements, promotional offers, alerts, confirmations,
          surveys, and/or other general communication. In order to improve our
          Service, we may receive a notification when you open an email from
          Plootus or click on a link therein.
          <br />
          <br />
          <b>External Data Storage Sites</b>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted. Plootus or third party hosting
          vendors may store such data in multiple countries, including countries
          outside of your own country to the extent permitted by applicable law.
          <br />
          <br />
          <b>Changes to this Statement</b>
          <br />
          Plootus reserves the right to change this Privacy Policy from time to
          time. We will notify you about significant changes in the way we treat
          personal information by sending a notice to the primary email address
          specified in your account, by placing a prominent notice on our site,
          and/or by updating any privacy information on this page. Your
          continued use of the App or Website and/or the Service available
          through this App or Website after such modifications will constitute
          your: (a) acknowledgment of the modified Privacy Policy; and (b)
          agreement to abide and be bound by that Policy.
          <br />
          <br />
          <b>Contact Information</b>
          <br />
          Plootus welcomes your questions or comments regarding this Statement
          of Privacy. If you believe that Plootus has not adhered to this
          Statement, please contact Plootus at:
          <br />
          Plootus
          <br />
          Attn: Privacy
          <br />
          470 James St, New Haven CT 06513.
          <br />
          <br />
          Effective as of December 1, 2017
        </div>
      </div>
    </div>
  );
};

export default Privacy;
