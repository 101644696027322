export function notNull(value) {
  return value !== null;
}

export const isEmpty = (value) => value === '';

export const validate = (field, value, fields, forcedTyped) => {
  const newFields = { ...fields };
  let newField;
  let result;

  switch (field) {
    case 'firstName':
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid first name',
        },
      };
      break;

    case 'lastName':
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid last name',
        },
      };
      break;
    case 'name':
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid last name',
        },
      };
      break;
    case 'mobileNo':
      result = /^\d{10}$/.test(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? 'Enter valid phone number' : '',
        },
      };
      break;

    case 'company':
      result = /^[A-Z]+$/i.test(value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid company name',
        },
      };
      break;

    // case "address":
    //   result = /^[A-Z]+$/i.test(value);
    //   newField = {
    //     value,
    //     typed: forcedTyped || false,
    //     err: {
    //       err: !result,
    //       value: result ? "" : "Enter a valid address",
    //     },
    //   };
    //   break;

    case 'email':
      result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? 'Enter valid email address' : '',
        },
      };
      break;

    case 'password':
      result = isEmpty(value);

      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: result,
          value: result ? 'Empty Password Not Allowed' : '',
        },
      };

      // if (
      //   newFields.confPassword.value &&
      //   newFields.confPassword.value !== value
      // ) {
      //   newFields.confPassword.err = {
      //     err: true,
      //     value: "Passwords dont match",
      //   };
      // } else {
      //   newFields.confPassword.err = {
      //     err: false,
      //     value: "Passwords match",
      //   };
      // }
      break;

    case 'confPassword':
      result =
        value === newFields.password.value &&
        !isEmpty(newFields.password.value);
      newField = {
        value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? 'Passwords Match' : 'Passwords Dont Match',
        },
      };

      break;

    case 'all': {
      // Validate all
      //firstname
      result = /^[A-Z]+$/i.test(newFields.firstName.value);
      newField = {
        value: newFields.firstName.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid First Name',
        },
      };
      newFields.firstName = newField;

      result = /^[A-Z]+$/i.test(newFields.lastName.value);
      newField = {
        value: newFields.lastName.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid Last Name',
        },
      };
      newFields.lastName = newField;

      // mobileNo
      result = /^\d{10}$/.test(newFields.mobileNo.value);
      newField = {
        value: newFields.mobileNo.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? 'Enter valid phone number' : '',
        },
      };
      newFields.mobileNo = newField;

      // Email
      result = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        newFields.email.value
      );
      newField = {
        value: newFields.email.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: !result ? 'Invalid Email Not Allowed' : '',
        },
      };
      newFields.email = newField;

      // Password

      //   if (
      //     newFields.confPassword.value &&
      //     newFields.confPassword.value !== newFields.password.value
      //   ) {
      //     newFields.confPassword.err = {
      //       err: true,
      //       value: "Passwords dont match",
      //     };
      //   }
      //   newFields.password = newField;

      //   // Confirm password
      //   result =
      //     newFields.confPassword.value === newFields.password.value &&
      //     !isEmpty(newFields.password.value);
      //   newField = {
      //     value: newFields.confPassword.value,
      //     typed: forcedTyped || false,
      //     err: {
      //       err: !result,
      //       value: result ? "Passwords Match" : "Passwords Dont Match",
      //     },
      //   };
      //   newFields.confPassword = newField;

      // Company name
      result = /^[A-Z]+$/i.test(newFields.company.value);
      newField = {
        value: newFields.company.value,
        typed: forcedTyped || false,
        err: {
          err: !result,
          value: result ? '' : 'Enter valid company name',
        },
      };
      newFields.company = newField;

      // result = /^[A-Z]+$/i.test(newFields.address.value);
      // newField = {
      //   value: newFields.address.value,
      //   typed: forcedTyped || false,
      //   err: {
      //     err: !result,
      //     value: result ? '' : 'Enter valid address',
      //   },
      // };
      // newFields.address = newField;

      return newFields;
    }

    default:
      return newFields;
  }

  newFields[field] = newField;

  return newFields;
};
