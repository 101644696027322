import React from "react";
import styles from "./TableFooter.module.css";

const TableFooter = ({ total }) => {
  console.log(total);
  return (
    <div className={styles.container}>
      <div className={styles.name}>TOTAL</div>
      <div className={styles.strat}>{total} %</div>
      <div className={styles.amount}></div>
    </div>
  );
};

export default TableFooter;
