import React from "react";
import InputText from "../../../Utils/InputText/InputText";
import styles from "./ProfileModal.module.css";

const AdvisorProfileModal = ({
  fields,
  handleTextChange,
  otherFields,
  setOtherFields,
  isEditing,
}) => {
  const {
    firstName,
    lastName,
    mobileNo,
    email,
    company,
    advisorAddress,
  } = fields;

  return (
    <div>
      <div className={styles.myRow}>
        <div className={styles.innerRow}>
          <InputText
            type="text"
            containerStyle={{ width: "45%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              value: firstName?.value,
              onChange: (e) => handleTextChange("firstName", e.target.value),
              disabled: !isEditing,
            }}
            title="First Name"
            err={firstName?.err}
            typed={firstName?.typed}
          />
          <InputText
            type="text"
            containerStyle={{ width: "45%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              value: lastName?.value,
              onChange: (e) => handleTextChange("lastName", e.target.value),
              disabled: !isEditing,
            }}
            title="Last Name"
            err={lastName?.err}
            typed={lastName?.typed}
          />
        </div>
      </div>
      <div className={styles.myRow}>
        <div className={styles.innerRow}>
          <InputText
            type="text"
            containerStyle={{ width: "45%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              maxLength: 10,
              value: mobileNo?.value,
              onChange: (e) => handleTextChange("mobileNo", e.target.value),
              disabled: !isEditing,
            }}
            title="Phone Number"
            err={mobileNo?.err}
            typed={mobileNo?.typed}
          />
          <InputText
            type="text"
            containerStyle={{ width: "45%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              maxLength: 50,
              value: company?.value,
              onChange: (e) => handleTextChange("company", e.target.value),
              disabled: !isEditing,
            }}
            title="Company"
            err={company?.err}
            typed={company?.typed}
          />
        </div>
      </div>
      <div className={styles.myRow}>
        <div className={styles.innerRow}>
          <InputText
            type="text"
            containerStyle={{ width: "95%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              value: email?.value,
              onChange: (e) => handleTextChange("email", e.target.value),
              disabled: true,
            }}
            title="Email ID"
            err={email?.err}
            typed={email?.typed}
          />
        </div>
      </div>
      <div className={styles.myRow}>
        <div className={styles.innerRow}>
          <InputText
            type="text"
            containerStyle={{ width: "95%" }}
            titleStyle={{ "font-size": "15px", marginBottom: "5px" }}
            textInputStyle={{ height: "28px", fontSize: "14px" }}
            config={{
              maxLength: 50,
              value: advisorAddress?.value,
              onChange: (e) =>
                handleTextChange("advisorAddress", e.target.value),
              disabled: !isEditing,
            }}
            title="Address"
            err={advisorAddress?.err}
            typed={advisorAddress?.typed}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvisorProfileModal;
