import React, { useEffect } from 'react';

const LinkModal = ({ url, accessToken, clicked }) => {
  useEffect(() => {
    (function (window) {
      window.fastlink.open(
        {
          fastLinkURL: url,
          accessToken: `Bearer ${accessToken}`,
          forceIframe: true,

          params: {
            userExperienceFlow: 'Aggregation',
          },
          onSuccess: function (data) {
            console.log('ONSUCCESS');
            // console.log(data);
          },
          onError: function (data) {
            console.log('ONERROR');
            // console.log(data);
          },
          onExit: function (data) {
            console.log('ONEXIT');
            // console.log(data);
          },
          onEvent: function (data) {
            console.log('ONEVENT');
            // console.log(data);
          },
        },
        'link-div'
      );
      //     }, false);
    })(window);
  }, []);

  return <div id="link-div"></div>;
};

export default LinkModal;
