import React, { useState, useContext } from 'react';
import RetirementStatus from './RetirementStatus';
import MainSignupScreen from '../../../SignUp/MainScreen';
import LoginSignupContext from '../../../../Reusables/VisualAndAccord/Context/LoginSignupContext';

const RetirementSignupMain = ({ status }) => {
  const { signupModal, setSignupModal, setLoginModal } = useContext(
    LoginSignupContext
  );

  const [modalIsOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const loginopenModal = () => {
    setLoginModal(true);
  };

  const aopenModal = () => {
    closeModal();
    setSignupModal(true);
  };

  const acloseModal = () => {
    setSignupModal(false);
  };

  return (
    <div>
      <RetirementStatus
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        aopenModal={aopenModal}
        status={status}
        setModalOpen={setModalOpen}
      />
      <MainSignupScreen
        modalIsOpen={signupModal}
        openModal={aopenModal}
        closeModal={acloseModal}
        isInEmployeer={false}
        loginopenModal={loginopenModal}
        show
      />
    </div>
  );
};

export default RetirementSignupMain;
