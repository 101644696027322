import Axios from "axios";

const BASE_URL = "https://www.plootus.com/api/";

export const saveDesign = async (data, name) => {
  try {
    const response = await Axios.post(`${BASE_URL}user/savetemplate`, {
      data: JSON.stringify(data.design),
      tag: name,
      htmlData: data.html.toString(),
    });
    if (response.status.toString().startsWith("2")) {
      return response;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateDesign = async ({ id, design, name, html }) => {
  try {
    const response = await Axios.post(`${BASE_URL}user/updatetemplate`, {
      id: id,
      data: JSON.stringify(design),
      tag: name,
      htmlData: html.toString(),
    });
    return response.status.toString().startsWith("2");
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const testEmailApi = async ({ emails, tag, subject }) => {
  try {
    const body = {
      emails,
      mailOptions: {
        subject,
      },
      tag,
    };
    const response = await fetch(`${BASE_URL}user/testEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return response.status.toString().startsWith("2");
  } catch (err) {
    console.log(err);
  }
};

export const fetchDesign = async () => {
  try {
    const response = await fetch(`${BASE_URL}user/gettemplate`, {
      method: "GET",
    });
    const resp = await response.json();
    if (response.status.toString().startsWith("2")) {
      return resp;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const searchEmployer = async (searchText) => {
  try {
    const response = await fetch(
      `https://www.plootus.com/api/employee/getemployee?ent_key=${searchText}`,
      {
        method: "GET",
      }
    );
    if (response.status.toString().startsWith("2")) {
      const { employees } = await response.json();
      return employees;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = async ({ selected, ein }) => {
  try {
    const response = await fetch(
      `https://www.plootus.com/api/user/getusersforemail?selected=${selected}&ein=${ein}`,
      {
        method: "GET",
      }
    );
    if (response.status.toString().startsWith("2")) {
      const { users } = await response.json();
      return users;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const emailUsers = async ({
  all,
  partial,
  users,
  ein,
  subject,
  tag,
}) => {
  const body = {
    partial: partial,
    selected: all ? "ALL" : "NOTALL",
    ein: ein,
    mailOptions: {
      subject: subject,
    },
    users: all || !partial ? [] : users,
    tag: tag,
  };
  try {
    const response = await fetch(`${BASE_URL}user/sendSelectedEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return response.status.toString().startsWith("2");
  } catch (err) {
    console.log(err);
    return false;
  }
};
