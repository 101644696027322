import {
  ASSET_CAT_API,
  ASSET_SCREEN,
  get_assets_cat,
  HIGHLIGHT_PIE,
} from "@plootus/common";
import React from "react";
import { connect } from "react-redux";
import VisualData from "../../Reusables/VisualAndAccord/Container/VisualData";
import styles from "./Assets.module.css";

const AssetsCat = (props) => (
  <div className={styles.wrapper}>
    <VisualData
      {...props}
      api_name={ASSET_CAT_API}
      screen={ASSET_SCREEN}
      dispatchAction={get_assets_cat}
      type="pie"
      title="TOTAL ASSETS"
      _key="id"
      actionType={HIGHLIGHT_PIE}
      _key_accord="id"
      nested
      singleScreen
    />
  </div>
);

export default connect(({ portfolioReducer: { assets } }) => ({
  ...assets,
}))(AssetsCat);
