import {
  employerNewDataSelector,
  get_employer_list,
  useSearch,
} from '@plootus/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useEmployerSearch = (initVal = '') => {
  const dispatch = useDispatch();

  const employerRedux = useSelector(employerNewDataSelector);

  const { employer_selected } = employerRedux;

  const [{ value, showList, ein }, setLocalData] = useState({
    value: initVal,
    showList: false,
    ein: null,
  });

  const setShowList = (show) =>
    setLocalData((prev) => ({
      ...prev,
      showList: show,
    }));

  const valIsEmpty = value === '';

  const useSearchState = useSearch(
    async () => {
      return await dispatch(get_employer_list(value));
    },
    value,
    500,
    !valIsEmpty
  );

  const { setData } = useSearchState;

  useEffect(() => {
    if (valIsEmpty || employer_selected) {
      setData([]);
    }
  }, [employerRedux]);

  return {
    ...useSearchState,
    setShowList,
    value,
    showList,
    valIsEmpty,
    employer_selected,
    ein,
    setLocalData,
  };
};

export default useEmployerSearch;
