import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid } from '@material-ui/core';
import BlogContent from './components/BlogContent';
import RecentPosts from './components/RecentPosts';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  }
}));

const BlogDetails = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={4}>
        {/* Main Content Column */}
        <Grid item xs={12} md={8}>
          <BlogContent />
        </Grid>

        {/* Sidebar Column */}
        <Grid item xs={12} md={4}>
          <RecentPosts />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BlogDetails;