import React from 'react';
import styles from './IncomeSearch.module.css';

const IncomeSearch = ({ setValue }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputDiv}>
        <div className={styles.iconWrap}>
          <img src="/search.svg" className={styles.icon} alt="" />
        </div>
        <div className={styles.inputWrap}>
          <input
            type="text"
            placeholder="Search"
            className={styles.input}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default IncomeSearch;
