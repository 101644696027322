import { ResponsiveLine } from '@nivo/line';
import React, { useCallback, useState, useEffect } from 'react';
import {
  getFormattedValue,
  BLUE_PLOOT,
  TEXT_BLACK_1,
  DRAK_GREEN_PLOOT,
} from '@plootus/common';
import styles from './LineChart.module.css';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import moment from 'moment';

const MyResponsiveLine = ({ data, pickerIndex, small }) => {
  const toolTipElement = (props) => {
    const date = props.point.data.x.toString();
    const fdate = moment(date).format('DD MMM YYYY');
    return (
      <div className={small ? styles.smalltoolTip : styles.toolTip}>
        Date:
        <span className={styles.xVal}> {fdate}</span>
        <div className={styles.yVal}>
          Net worth:{' '}
          <span
            className={small ? styles.smallmoney : styles.money}
            style={{ color: DRAK_GREEN_PLOOT }}
          >
            {getFormattedValue(props.point.data.y, 'money', 2)}
          </span>
        </div>
      </div>
    );
  };

  const { width } = useWindowDimensions();
  const newData = [
    {
      data: data,
    },
  ];

  const dummy = data.map((item) => {
    return {
      x: item.x,
      y: 0,
    };
  });
  const dummyData = [
    {
      data: dummy,
    },
  ];

  const [graphData, setGraphData] = useState(dummyData);

  const getMax = () => {
    let max = -1;
    data.map((item, index) => {
      max = Math.max(max, item.y);
    });
    return max;
  };

  const getMin = () => {
    let min = 100000000;
    graphData.map((item, index) => {
      min = Math.min(min, item.y);
    });
    return min > 10000 ? min - 10000 : 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setGraphData(newData);
    }, 100);
  }, []);

  const chartTheme = () => {
    return {
      grid: {
        line: {
          stroke: 'rgba(0,0,0,0.05)',
        },
      },
      axis: {
        legend: {
          text: {
            fill: BLUE_PLOOT,
            fontSize: width > 576 ? 15 : 12,
          },
        },
        ticks: {
          text: {
            fill: TEXT_BLACK_1,
            fontSize: width > 576 ? 12 : 9,
            fontFamily: 'Arial',
          },
          line: {
            stroke: 'rgba(0,0,0,0.3)',
            strokeWidth: 1,
          },
        },
        domain: {
          line: {
            stroke: 'rgba(0,0,0,0.1)',
            strokeWidth: 1,
          },
        },
      },
      crosshair: {
        line: {
          stroke: 'rgb(0,0,0)',
          strokeWidth: 1,
          strokeOpacity: 1,
        },
      },
    };
  };
  const maxY = getMax();
  const minY = getMin();

  return data?.length ? (
    <div className={styles.chartCont}>
      <ResponsiveLine
        data={newData}
        margin={{
          top: width > 576 ? (small ? 20 : 60) : 40,
          right: width > 576 ? (small ? 40 : 60) : 20,
          bottom: small ? 50 : width > 576 ? 80 : 40,
          left: width > 576 ? 100 : 60,
        }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          min: minY,
          max: maxY,
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: '%d %b %Y',
          tickValues:
            small || width < 576 ? 'every 3 months' : 'every 2 months',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 5,
          format: (d) => `${getFormattedValue(d, 'money', 2)}`,
        }}
        theme={chartTheme()}
        colors={[BLUE_PLOOT]}
        pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableArea
        areaBaselineValue={minY}
        areaOpacity={1}
        curve={'catmullRom'}
        tooltip={toolTipElement}
        animate={true}
        motionStiffness={50}
        crosshairType="cross"
      />
    </div>
  ) : null;
};

export default MyResponsiveLine;
