import React from 'react';
import styled from 'styled-components';

const ComingSoonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  ${'' /* background-color: #f5f5f5; */}
`;

const ComingSoonContent = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: #F5F7FD;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height:95%;
  width: 95%;
`;

const ComingSoonImage = styled.div`
  margin-right: 2rem;
  height: 100%;
  width: 100%;
  objectFit: contain;
`;

const ComingSoonText = styled.div`
  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 1rem;
  }
`;

const ComingSoonComponent = () => {
  return (
    <ComingSoonContainer>
      <ComingSoonContent>
        <ComingSoonImage>
          <img style={{height:'100%'}} src="/images/girl-image.png" alt="Coming Soon" />
        </ComingSoonImage>
       
      </ComingSoonContent>
    </ComingSoonContainer>
  );
};

export default ComingSoonComponent;