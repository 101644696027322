import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    gap: '12px',
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  searchInput: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: 'white',
      '& fieldset': {
        borderColor: '#E5E7EB'
      },
      '&:hover fieldset': {
        borderColor: '#4361EE'
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
        height: '24px'
      }
    }
  },
  searchButton: {
    backgroundColor: '#36B37E',
    color: 'white',
    textTransform: 'none',
    padding: '8px 24px',
    borderRadius: '8px',
    minWidth: '100px',
    '&:hover': {
      backgroundColor: '#2E9669'
    }
  }
}));