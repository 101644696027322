import { Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BLUE_PLOOT,
  DARK_RED_PLOOT,
  data_type_rows,
  DRAK_GREEN_PLOOT,
  formatCommaString,
  isLinkedSelector,
  roundNum,
  screen_api_to_params_mapping,
  searchData,
  sendAlert,
  sortData,
  TEXT_BLACK_2,
  timePeriod,
  useDidUpdate,
  usePickerModal,
  isAdvisorSelector,
} from '@plootus/common';
import React, { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import CategoryTabs from '../../../../Utils/CategoryTabs/CategoryTabs';
import Pie from '../../../../Utils/Charts/PieChart/Pie';
import Picker from '../../../../Utils/Containers/Picker/Picker';
import ProcessPicker from '../../../../Utils/Containers/ProcessPicker/ProcessPicker';
import IncomeSearch from '../../../../Utils/IncomeSearchBox/IncomeSearch';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import AccordPart from './AccordPart';
import NestedAccordPart from './NestedAccordPart';
import TableNewData from './TableNewData';
import styles from './TimePeriodNewData.module.css';

const theme = createMuiTheme({
  palette: {
    primary: { main: BLUE_PLOOT }, // Purple and green play nicely together.
  },
  typography: { useNextVariants: true },
});

function reducer(state, { type, payload }) {
  switch (type) {
    case 'SET_DATA':
      return {
        ...state,
        ...payload,
      };

    case 'LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'UNLOADING':
      return {
        ...state,
        loading: false,
      };

    default:
      return undefined;
  }
}

const TimePeroidNewData = ({
  dispatch,
  api_name,
  screen,
  type,
  title,
  chartComponent,
  actionType,
  _key,
  visualAction,
  nested,
  singleScreen,
  actionToDispatch,
  dispatchTransaction,
  ...props
}) => {
  const api_data = props[`${api_name}_data`];
  const trans_data = props[`${api_name}_trans_data`];
  const api_pie_data = props[`${api_name}_pie`];
  const total = props[`${api_name}_total`];
  const api_label_data = props[`${api_name}_labels`];
  const isLinked = useSelector((state) => isLinkedSelector(state));
  const { width } = useWindowDimensions();
  const lastLoc = useLastLocation();
  const isAdvisor = useSelector(isAdvisorSelector);
  const history = useHistory();
  const {
    setIndex: setdataTypePick,
    picker: dataTypePick,
    negativeModal: dataTypeModal,
  } = usePickerModal((idx) => {
    if (idx === 0) {
      if (!api_data?.length) {
        // console.log(idx);
        dispatchMyActionBoi();
      } else {
        setData(api_data, {
          pie_data: api_pie_data,
          label_data: api_label_data,
        });
      }
    } else if (idx === 1) {
      if (!trans_data?.length) {
        dispatchMyActionBoi();
      } else {
        setData(trans_data, {
          pie_data: api_pie_data,
          label_data: api_label_data,
        });
      }
    }
  });
  const { setIndex: setprocessPick, picker: processPick } = usePickerModal(
    () => {
      dispatchReducerAction({
        type: 'LOADING',
      });

      setData(data);
    },
    0,
    true
  );

  const { setIndex, negativeModal, picker } = usePickerModal(
    async (idx) => {
      await dispatch(
        visualAction({
          interval: timePeriod[idx].value,
        })
      );
      if (dataTypePick.index) {
        await dispatchMyActionBoi();
      }
    },
    api_name === 'cashflow' ? 3 : 0,
    true
  );

  const dispatchAction = dataTypePick.index
    ? dispatchTransaction
    : actionToDispatch;

  const [value, setValue] = useState('');

  const initialState = {
    data: null,
    loading: true,
    pie_data: null,
    label_data: null,
    selected_index: null,
  };
  const [
    { data, loading, pie_data, selected_index },
    dispatchReducerAction,
  ] = useReducer(reducer, initialState);

  const mappingData = screen_api_to_params_mapping(api_name);

  const AccordPartComponent = nested ? NestedAccordPart : AccordPart;

  const expandSearchedAccord = (index) => {
    const updatedAccords = data.map((item, idx) =>
      idx === index
        ? {
            ...item,
            selected: !item.selected,
          }
        : item
    );

    dispatchReducerAction({
      type: 'SET_DATA',
      payload: {
        data: updatedAccords,
        loading: false,
      },
    });
  };

  /**
   * Will set the current selected index of the pie chart arc
   * Will also expand the equivalent indexed accord item
   * @param {num} index
   */
  const expandAccordAndArc = (index) => {
    let index_selected = null;
    const updatedVisualData = [];
    const updatedAccords = [];

    // const visual_data = type === 'graph' ? graph_data : pie_data;

    if (type === 'graph') {
      const updatedAccordsGraph = [...data];
      const updatedIndex = {
        ...updatedAccordsGraph[index],
        selected: !updatedAccordsGraph[index].selected,
      };
      updatedAccordsGraph[index] = updatedIndex;

      setData(updatedAccordsGraph);
    } else {
      const visual_data = pie_data;

      for (let i = 0; i < visual_data.length; i += 1) {
        if (i === index) {
          if (!visual_data[i].selected) {
            index_selected = i;
          }
          updatedVisualData.push({
            ...visual_data[i],
            selected: !visual_data[i].selected,
          });
          updatedAccords.push({
            ...data[i],
            selected: !data[i].selected,
          });
        } else {
          updatedVisualData.push({
            ...visual_data[i],
            selected: false,
          });
          updatedAccords.push({
            ...data[i],
            selected: false,
          });
        }
      }

      const setDataAttrs = {
        pie_data: updatedVisualData,
        selected_index: index_selected,
      };

      setData(updatedAccords, setDataAttrs);
    }
  };

  const expandNestedAccord = (index, level) => {
    const updatedAccords = data.map((item1) => ({
      ...item1,
      list3: item1.list3.map((item2, index2) => {
        if (level === 2) {
          if (index === index2) {
            return { ...item2, selected: !item2.selected };
          }
          return item2;
        }
        return {
          ...item2,
          list2: item2.list2.map((item3, index3) => {
            if (index === index3) {
              return {
                ...item3,
                selected: !item3.selected,
              };
            }
            return item3;
          }),
        };
      }),
    }));

    setData(updatedAccords);
  };

  const setData = (dataArg, extraPayload) => {
    if (dataTypePick.index === 0) {
      dispatchReducerAction({
        type: 'SET_DATA',
        payload: {
          data: dataArg,
          loading: false,
          ...extraPayload,
        },
      });
    } else if (dataTypePick.index === 1) {
      dispatchReducerAction({
        type: 'SET_DATA',
        payload: {
          data: sortData(
            dataArg,
            processPick.index,
            mappingData.metaData.sortParams
          ),
          loading: false,
          ...extraPayload,
        },
      });
    }
  };

  const dispatchMyActionBoi = async () => {
    try {
      await dispatch(
        dispatchAction({
          interval: timePeriod[picker.index].value,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useDidUpdate(() => {
    if (api_data && dataTypePick.index === 0) {
      setData(api_data, {
        pie_data: api_pie_data,
        label_data: api_label_data,
      });
    } else {
      setData(trans_data, {
        pie_data: api_pie_data,
        label_data: api_label_data,
      });
    }
  }, [api_data, trans_data]);
  useDidUpdate(() => {
    const suffix = dataTypePick.index ? 'trans_data' : 'data';
    const localData = searchData(
      props[`${api_name}_${suffix}`],
      mappingData.metaData.searchParam,
      value,
      dataTypePick.index,
      mappingData.metaData.searchCont
    );
    setData(localData);
  }, [value]);

  if (!isLinked) {
    dispatch(
      sendAlert({
        title: 'Accounts not linked',
        textContent: isAdvisor
          ? 'Please inform your client to link his accounts to see this page'
          : 'Please Link your accounts to access these pages',
        buttonRightPress: () => {
          history.push('/auth/linkaccounts');
        },
        buttonLeftText: 'Cancel',
        buttonRightText: 'Link Accounts',
      })
    );
    return <Redirect to={lastLoc ? lastLoc.pathname : '/auth/dashboard'} />;
  }

  if (loading) {
    return (
      <div className={styles.loader}>
        <div className={styles.spinner}>
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={50} />
          </MuiThemeProvider>
        </div>
        <div className={styles.loading}>Loading ...</div>
      </div>
    );
  }
  const amount = total
    ? `$${formatCommaString(roundNum(Math.abs(total), 0, 2))}`
    : '$  --';
  if (dataTypePick.index === 1 && width > 1024) {
    return (
      <TableNewData
        api_name={api_name}
        mappingData={mappingData}
        title={title}
        amount={amount}
        picker={picker}
        timePeriod={timePeriod}
        dispatchReducerAction={dispatchReducerAction}
        setIndex={setIndex}
        negativeModal={negativeModal}
        setValue={setValue}
        singleScreen={singleScreen}
        data_type_rows={data_type_rows}
        dataTypePick={dataTypePick}
        setdataTypePick={setdataTypePick}
        dataTypeModal={dataTypeModal}
        processPick={processPick}
        value={value}
        data={data}
        screen={screen}
        dispatch={dispatch}
        actionType={actionType}
        canExpand={dataTypePick.index}
        _key={_key}
        expandSearchedAccord={expandSearchedAccord}
        expandAccordAndArc={expandAccordAndArc}
        expandNestedAccord={expandNestedAccord}
        chartComponent={chartComponent}
        selected_index={selected_index}
        pie_data={pie_data}
        total={total}
        setprocessPick={setprocessPick}
        AccordPartComponent={AccordPartComponent}
        type={type}
      />
    );
  }

  return (
    <div
      className={
        api_name === 'networth'
          ? styles.netContainer
          : mappingData.metaData
          ? styles.container
          : styles.other
      }
    >
      <div
        className={api_name === 'networth' ? styles.netPickers : styles.pickers}
      >
        <div className={styles.text}>
          <div className={styles.actualText}>{title}</div>
          {total ? (
            <div
              className={styles.value}
              style={{
                color:
                  total >= 0 && api_name !== 'expense'
                    ? DRAK_GREEN_PLOOT
                    : DARK_RED_PLOOT,
              }}
            >
              {amount.slice(0, -2)}
              <span className={styles.afterDecimal}>{amount.slice(-2)}</span>
            </div>
          ) : (
            <div
              className={styles.value}
              style={{
                color: TEXT_BLACK_2,
              }}
            >
              {amount}
            </div>
          )}
        </div>

        <div className={styles.datePicker}>
          {api_name === 'income' ||
          api_name === 'expense' ||
          api_name === 'cashflow' ? (
            <Picker
              {...picker}
              rows={timePeriod}
              setIndex={(idx) => {
                if (idx !== picker.index) {
                  dispatchReducerAction({
                    type: 'LOADING',
                  });
                  setIndex(idx);
                }
              }}
              handlePress={negativeModal}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {mappingData.metaData ? (
        <div className={styles.search}>
          <div className={styles.searchDiv}>
            <IncomeSearch setValue={setValue} />
          </div>
        </div>
      ) : null}
      {!singleScreen ? (
        <div className={styles.dataPicker}>
          <div className={styles.dataPickerWrapper}>
            <CategoryTabs
              {...dataTypePick}
              rows={data_type_rows}
              setIndex={(idx) => {
                dispatchReducerAction({
                  type: 'LOADING',
                });
                setdataTypePick(idx);
              }}
              handlePress={dataTypeModal}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.empty}></div>
      <div
        className={
          api_name === 'networth' ? styles.netAccordArea : styles.accordArea
        }
      >
        <div>
          <ProcessPicker
            singleScreen={singleScreen}
            setprocessPick={setprocessPick}
            setValue={setValue}
            value={value}
            setLoading={() =>
              dispatchReducerAction({
                type: 'LOADING',
              })
            }
            processPick={processPick}
            dataTypePick={dataTypePick}
          />
        </div>
        {api_name === 'networth' ? (
          <AccordPartComponent
            data={data}
            api_name={api_name}
            screen={screen}
            dispatch={dispatch}
            actionType={actionType}
            canExpand={dataTypePick.index}
            value={value}
            _key={_key}
            mappingData={mappingData}
            expandSearchedAccord={expandSearchedAccord}
            highlight_pie={expandAccordAndArc}
            expandNested={expandNestedAccord}
          />
        ) : (
          <div className={styles.accordCont}>
            <AccordPartComponent
              data={data}
              api_name={api_name}
              screen={screen}
              dispatch={dispatch}
              actionType={actionType}
              canExpand={dataTypePick.index}
              value={value}
              _key={_key}
              mappingData={mappingData}
              expandSearchedAccord={expandSearchedAccord}
              highlight_pie={expandAccordAndArc}
              expandNested={expandNestedAccord}
            />
          </div>
        )}
      </div>
      {data?.length ? (
        <div
          className={
            api_name === 'networth' ? styles.netGraphArea : styles.graphArea
          }
        >
          {type === 'graph' ? (
            <div className={styles.pieChart}>
              <Card
                className={
                  api_name === 'networth' ? styles.netCard : styles.graphCard
                }
                style={{
                  borderRadius: '14px',
                }}
              >
                {chartComponent(picker.index)}
              </Card>
            </div>
          ) : (
            <div className={styles.pieChart}>
              <Card className={styles.pieCard}>
                <Pie
                  selected_index={selected_index}
                  dataTypePick={dataTypePick}
                  data={pie_data}
                  screen={screen}
                  api={api_name}
                  highlight_pie={expandAccordAndArc}
                  total={total}
                  mappingData={mappingData}
                />
              </Card>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TimePeroidNewData;
