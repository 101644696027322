import React, { useContext } from 'react';
import styles from './Sidebar.module.css';
import DrawerToggleButton from './DrawerToggleButton';
import Logo from '../../../Assets/icon.png';
import HomeIcon from '../../../Assets/homeIcon.png';
import AssetsIcon from '../../../Assets/AssetsIcon.png';

import incomeIcon from '../../../Assets/incomeIcon.png';
import LiabilitiesIcon from '../../../Assets/LiabilitiesIcon.png';
import linkAccountsIcon from '../../../Assets/linkAccountsIcon.png';
import NewWorthIcon from '../../../Assets/NetWorthIcon.png';

import AdvisorsIcon from '../../../Assets/AdvisorsIcon.png';
import help from '../../../Assets/help.png';
import helpg from '../../../Assets/helpg.png';
import HomeIcong from '../../../Assets/homeIcong.png';

import Pig from '../../../Assets/pig.png';
import Pigg from '../../../Assets/pigg.png';

import Calculator from '../../../Assets/calculator.png';
import Calculatorg from '../../../Assets/calculatorg.png';

import AssetsIcong from '../../../Assets/AssetsIcong.png';
import incomeIcong from '../../../Assets/incomeIcong.png';
import LiabilitiesIcong from '../../../Assets/LiabilitiesIcong.png';
import linkAccountsIcong from '../../../Assets/linkAccountsIcong.png';
import NewWorthIcong from '../../../Assets/NetWorthIcong.png';
import { useHistory } from 'react-router-dom';
import AdvisorsIcong from '../../../Assets/AdvisorsIcong.png';
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, Route, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { CaresActContext } from '../../../Reusables/VisualAndAccord/Context/CaresActContext';
import ThumbsUp from '@material-ui/icons/ThumbUp';

const Sidebar = ({ drawerToggleClickHandler }) => {
  const selected = useRef(-1);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.userReducer.userData);

  const { openCaresAct } = useContext(CaresActContext);

  const myL = useRef('/');
  const [route, setRoute] = useState(false);

  myL.current = useLocation().pathname;

  switch (myL.current) {
    case '/auth/401k':
      selected.current = 0;
      break;

    case '/auth/dashboard':
      selected.current = 1;
      break;

    case '/auth/networth':
      selected.current = 2;
      break;

    case '/auth/assets':
      selected.current = 3;
      break;

    case '/auth/liabilities':
      selected.current = 4;
      break;

    case '/auth/incomexp':
      selected.current = 5;
      break;

    case '/auth/advisors':
      selected.current = 6;
      break;

    case '/auth/linkaccounts':
      selected.current = 7;
      break;

    case '/auth/faq':
      selected.current = 8;
      break;

    case '/auth/logout':
      selected.current = 9;
      break;
    case '/auth/termsandcondition':
      selected.current = 10;
      break;
    case '/auth/privacypolicy':
      selected.current = 11;
      break;

    case '/auth/401kcalculator':
      selected.current = 12;
      break;
  }
  if (route) {
    return <Redirect to='/static' />;
  }

  return (
    <div>
      <div className={styles.hamburger}>
        <DrawerToggleButton
          drawerToggleClickHandler={drawerToggleClickHandler}
        />
      </div>
      <div className={styles.sidebar_inner}>
        <div className={styles.logo}>
          {' '}
          <img src={Logo} className={styles.img} />
          <h2 className={styles.headingText}>Plootus</h2>
        </div>
        <ul className={styles.sidebar_menu}>
          <li>
            <Link
              to='/auth/dashboard'
              className={styles.link}
              onClick={() => (selected.current = 1)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 1 ? HomeIcon : HomeIcong}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 1 ? styles.bold : styles.text}
              >
                Home
              </div>
            </Link>
          </li>
          {/* <li>
            <Link
              to="#corona-virus-stimulus-calculator"
              className={styles.link}
              onClick={openCaresAct}
            >
              <div className={styles.icon}>
                <img
                  src={require("../../../Assets/cares_act.svg")}
                  width="30px"
                  height="30px"
                  style={{ color: "grey", opacity: 0.4 }}
                />
              </div>
              <div className={styles.text} style={{ cursor: "pointer" }}>
                Corona Virus Stimulus Calculator
              </div>
            </Link>
          </li> */}

          <li>
            <Link
              to='/auth/401k'
              className={styles.link}
              onClick={() => (selected.current = 0)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 0 ? Pig : Pigg}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 0 ? styles.bold : styles.text}
              >
                401k Plan
              </div>
            </Link>
          </li>
          <li
            className={styles.link}
            onClick={() => {
              selected.current = 2;
              history.push('/auth/networth');
            }}
          >
            <div className={styles.icon}>
              <img
                src={selected.current === 2 ? NewWorthIcon : NewWorthIcong}
                className={styles.img1}
              />
            </div>
            <div className={selected.current === 2 ? styles.bold : styles.text}>
              Net Worth
            </div>
          </li>
          <li>
            <Link
              to='/auth/assets'
              className={styles.link}
              onClick={() => (selected.current = 3)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 3 ? AssetsIcon : AssetsIcong}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 3 ? styles.bold : styles.text}
              >
                Assets
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='/auth/liabilities'
              className={styles.link}
              onClick={() => (selected.current = 4)}
            >
              <div className={styles.icon}>
                <img
                  src={
                    selected.current === 4 ? LiabilitiesIcon : LiabilitiesIcong
                  }
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 4 ? styles.bold : styles.text}
              >
                Liabilities
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='/auth/incomexp'
              className={styles.link}
              onClick={() => (selected.current = 5)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 5 ? incomeIcon : incomeIcong}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 5 ? styles.bold : styles.text}
              >
                Income and Expenses
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='/auth/401kcalculator'
              className={styles.link}
              onClick={() => (selected.current = 12)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 12 ? Calculator : Calculatorg}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 12 ? styles.bold : styles.text}
              >
                401k Calculator
              </div>
            </Link>
          </li>
          <li
            className={styles.link}
            onClick={() => {
              selected.current = 6;
              history.push('/auth/advisors');
            }}
          >
            <div className={styles.icon}>
              <img
                src={selected.current === 6 ? AdvisorsIcon : AdvisorsIcong}
                className={styles.img1}
              />
            </div>
            <div className={selected.current === 6 ? styles.bold : styles.text}>
              {user?.userType === 'primary' ? 'Advisors' : 'Clients'}
            </div>
          </li>
          <li>
            <Link
              to='/auth/linkaccounts'
              className={styles.link}
              onClick={() => (selected.current = 7)}
            >
              <div className={styles.icon}>
                <img
                  src={
                    selected.current === 7
                      ? linkAccountsIcon
                      : linkAccountsIcong
                  }
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 7 ? styles.bold : styles.text}
              >
                Link Accounts
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='/auth/faq'
              className={styles.link}
              onClick={() => (selected.current = 8)}
            >
              <div className={styles.icon}>
                <img
                  src={selected.current === 8 ? help : helpg}
                  className={styles.img1}
                />
              </div>
              <div
                className={selected.current === 8 ? styles.bold : styles.text}
              >
                FAQ
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
