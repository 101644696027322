import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Radio, Input } from "antd";

import "./Unsubscribe.css";

const { TextArea } = Input;

function Unsubscribe(props) {
  const [checked, setChecked] = useState(null);
  const [other, setOther] = useState("");
  let textAreaRef = useRef(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let id = props.location.search.split("=")[1];
    setUserId(id);
    console.log(id);
  }, []);

  const handleDone = async () => {
    try {
      const response = await fetch(
        "https://www.plootus.com/api/user/unsubscribeResponse",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: userId,
            response: checked,
            responseString: textAreaRef.state.value,
          }),
        }
      );
      console.log(response);
      props.history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="center">
      <div className="container">
        <span className="center logospan">
          <img
            src={require("../../Assets/plootus-p(2).png")}
            className="logo"
          />
        </span>
        <span className="header-span">
          <p className="header">You will be missed!</p>
          <p className="sub-header">
            You've been successfully unsubscribed from our email list.
          </p>
        </span>

        <div className="content">
          <p>
            Your opinions mean everything to us! Please take a moment to tell us
            why you no longer wish to hear from us
          </p>
          <Radio
            className="radio"
            checked={checked === 1}
            onClick={() => setChecked(1)}
          >
            I'm not interested anymore
          </Radio>
          <br />
          <Radio
            className="radio"
            checked={checked === 2}
            onClick={() => setChecked(2)}
          >
            I never signed up
          </Radio>
          <br />
          <Radio
            className="radio"
            checked={checked === 3}
            onClick={() => setChecked(3)}
          >
            I don't think the content is relevant anymore
          </Radio>
          <br />
          <Radio
            className="radio"
            checked={checked === 4}
            onClick={() => setChecked(4)}
          >
            Other (please explain below)
          </Radio>
          <TextArea
            rows={4}
            className="textarea"
            ref={(ref) => (textAreaRef = ref)}
          />
          <span className="buttons-span">
            <Link to="/">
              <Button type="text" className="skip-button">
                SKIP
              </Button>
            </Link>
            <Button type="primary" className="done-button" onClick={handleDone}>
              Done
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Unsubscribe;
