import { OFF_WHITE } from '@plootus/common';
import React, { useState } from 'react';
import { isIOS } from 'react-device-detect';
import IconPlootus from '../../Assets/icon.png';

const AppBanner = () => {
  const link = isIOS
    ? 'https://apps.apple.com/us/app/plootus/id1311669590'
    : 'https://play.google.com/store/apps/details?id=com.plootus.android';

  const [crossClick, setCrossClick] = useState(false);

  return (
    <>
      {!crossClick ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            backgroundColor: OFF_WHITE,
            padding: 16,
            zIndex: 999,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <a href={link}>
            <img
              src={IconPlootus}
              style={{
                height: '50px',
                width: '50px',
                cursor: 'pointer',
                marginRight: 16,
                borderRadius: 4,
              }}
              alt="Close"
            />
          </a>
          <div>
            <a href={link} style={{ margin: 0 }}>
              Plootus: 401k Retirement Planner
            </a>
            <br />
            <a href={link} style={{ margin: 0 }}>
              Open in Plootus app
            </a>
          </div>
          <i
            onClick={() => setCrossClick(true)}
            class="fa fa-close"
            style={{
              marginLeft: 'auto',
              fontSize: 20,
              alignSelf: 'center',
            }}
          ></i>
        </div>
      ) : null}
    </>
  );
};

export default AppBanner;
