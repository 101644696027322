// src/components/Newsletter/newsletterData.js

export const newsletterData = [
  {
    date: "1 Jan 2023",
    image: "/images/blog/trump-news.jpg",
    headlines: [
      "Market trends showing strong recovery in tech sector",
      "New retirement planning strategies for 2023",
      "Impact of global economic policies on personal investments"
    ],
    description: "Discover key insights about market trends, investment strategies, and personal finance tips in our latest newsletter edition."
  },
  {
    date: "8 Jan 2023",
    image: "/api/placeholder/640/320",
    headlines: [
      "Emerging opportunities in sustainable investments",
      "Understanding cryptocurrency market fluctuations",
      "Best practices for portfolio diversification"
    ],
    description: "Stay informed about sustainable investing, crypto markets, and portfolio management strategies in this week's edition."
  }
];