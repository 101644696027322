import React from 'react';
import styles from './Algorithm.module.css';
import Image from '../../Assets/Group 25.svg';
import { Helmet } from 'react-helmet';

const Algorithm = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
        Minimum inputs. AI driven retirement calculator. Plootus
        </title>
        <meta
          name='description'
          content="The best-in-class algorithm, easy to use. Best investment advice for your 401k, 403b plans. It does not matter if it's with Fidelity, TIAA, or ADP."
        />
        <meta
          name='keywords'
          content="best retirement algorithm, best retirement website, top retirement algorithm. Top retirement website, worry free retirement; your Robo advisor for 401k, 4O1k, 401 (K), 403b, 4O3b, 457 plans, cryptocurrency, which crypto to buy, which stock to buy, top performing cryptocurrency, Crypto market trends, Crypto sentiment score, crypto sentiment analysis, stock sentiment analysis, S&P 500 best performing stocks."
        />
      </Helmet>
      <div className={styles.gapper} />
      <div className={styles.main}>
        <div className={styles.textContent}>
          <h1 className={styles.philoH1}>Algorithm</h1>
          <p className={styles.philoP}>
            Change is the only constant in life.
            <br />
            This is why we have created a dynamic, comprehensive, artificially
            intelligent (AI) and efficient product to meet your ever-changing
            financial needs.
          </p>
        </div>
        <div className={styles.imageContent}>
          <img
            src={Image}
            alt='Youth no aware about Retirement planning'
            className={styles.img}
          />
        </div>
      </div>

      <div className={styles.mainphilosophy}>
        <h1 className={styles.philoH3}>
          Comprehensive plans customized according to individual needs
        </h1>
        <div className={styles.ptagP}>
          <p>
            We believe that everyone’s financial situation is unique and is
            influenced by multiple factors like earnings, expense behavior,
            assets, risk appetite, age, commitments and other demographics. Our
            main focus is to help you create a diversified portfolio and balance
            it with your risk tolerance. We analyze all aspects like current
            income and expenses, assets, contribution over a period of work
            life, retirement year, life expectancy, funds availability and fees
            before making any recommendations. Of course, we monitor your
            accounts regularly to keep you on track.
          </p>
        </div>

        <h1 className={styles.philoH3}>
          We do the grunt work and ensure you never go off course
        </h1>
        <div className={styles.ptagP}>
          <p>
            Our algorithm reviews your current & past expenditure pattern,
            family status and risk appetite, and forecasts future income and
            expenses until projected lifespan. It adjusts for changes such as
            child care needs and changes in healthcare expenses over the years.
            We then review your risk appetite and determine if the current
            assets in your employer's supported retirement account and the
            strategy you are using are sufficient to cover future needs. This
            helps us select your ideal investment portfolio based on fee
            structure, volatility of returns and diversification. We track the
            performance of the funds on a regular basis.
          </p>
        </div>
      </div>

      <div className={styles.mainphilosophy}>
        <h1 className={styles.philoH1}>How our algorithm works</h1>
        <div className={styles.ulTag}>
          <ul>
            <li className={styles.ulItem}>
              <div className={styles.squareP}>
                <div className={styles.square} />
              </div>
              <div className={styles.ptagP}>
                <p>
                  The user creates a profile and links accounts such as bank
                  accounts, credit cards, mortgage 401k, 529 plan to acquire a
                  comprehensive view of your financial profile.
                </p>
              </div>
            </li>

            <li className={styles.ulItem}>
              <div className={styles.squareP}>
                <div className={styles.square} />
              </div>
              <div className={styles.ptagP}>
                <p>
                  Our proprietary models estimate total money required and
                  suggest how you should accumulate money to meet your
                  expectations. We suggest how much you should contribute and
                  which strategy you should choose.
                </p>
              </div>
            </li>

            <li className={styles.ulItem}>
              <div className={styles.squareP}>
                <div className={styles.square} />
              </div>
              <div className={styles.ptagP}>
                <p>
                  Once the investment strategy is determined. Plootus analyzes
                  the fund options from 401k/403b /457 plans based on various
                  risk/return factors and recommends how to invest/allocate into
                  different asset classes.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Algorithm;
