import { ResponsiveLine } from '@nivo/line';
import {
  BLUE_PLOOT,
  DRAK_GREEN_PLOOT,
  getFormattedValue,
  TEXT_BLACK,
} from '@plootus/common';
import React from 'react';
import useWindowDimensions from '../../../Reusables/VisualAndAccord/Hooks/useWindowDimensions';
import styles from './RetirementChart.module.css';

let bottom = '';
let left = '';

const MyResponsiveLine = ({ data, xAxis, yAxis, color, toolY }) => {
  left = toolY;
  bottom = xAxis;
  const newData = [
    {
      data: data,
    },
  ];

  const { height, width } = useWindowDimensions();

  const toolTipElement = (props) => {
    const xval = props.point.data.x;
    const yval = getFormattedValue(props.point.data.y, 'money', 2);
    return (
      <div className={styles.toolTip}>
        <div className={styles.xVal}>
          {xAxis}:{xval}
        </div>
        <div className={styles.yVal}>
          {toolY}: <span style={{ color: DRAK_GREEN_PLOOT }}>{yval}</span>
        </div>
      </div>
    );
  };

  const getMax = () => {
    let max = -1;
    data.map((item, index) => {
      max = Math.max(max, item.y);
    });
    return max + 200;
  };

  const getMin = () => {
    let min = 100000000;
    data.map((item, index) => {
      min = Math.min(min, item.y);
    });
    return min;
  };

  const getMinX = () => {
    let min = 100000000;
    data.map((item, index) => {
      min = Math.min(min, item.x);
    });
    return min;
  };

  const chartTheme = () => {
    return {
      grid: {
        line: {
          stroke: 'rgba(0,0,0,0.05)',
        },
      },
      axis: {
        legend: {
          text: {
            fill: BLUE_PLOOT,
            fontSize: width > 576 ? 15 : 12,
          },
        },
        ticks: {
          text: {
            fill: TEXT_BLACK,
            fontSize: width > 576 ? 12 : 9,
          },
          line: {
            stroke: 'rgba(0,0,0,0.3)',
            strokeWidth: 1,
          },
        },
        domain: {
          line: {
            stroke: 'rgba(0,0,0,0.1)',
            strokeWidth: 1,
          },
        },
      },
      crosshair: {
        line: {
          stroke: 'rgb(0,0,0)',
          strokeWidth: 1,
          strokeOpacity: 1,
        },
      },
    };
  };
  const maxY = getMax();
  const minY = getMin();
  const minX = getMinX();
  return data?.length ? (
    <div className={styles.chartCont}>
      <ResponsiveLine
        data={newData}
        margin={{
          top: width > 576 ? 40 : 30,
          right: width > 576 ? 50 : 30,
          bottom: 60,
          left: width > 576 ? 90 : 70,
        }}
        xScale={{ type: 'linear', min: minX }}
        yScale={{
          type: 'linear',
          min: minY,
          max: maxY,
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xAxis,
          legendOffset: 46,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxis,
          legendOffset: width > 576 ? -65 : -50,
          legendPosition: 'middle',
          tickValues: 5,
          format: (d) => `${getFormattedValue(d, 'money', 2)}`,
        }}
        theme={chartTheme()}
        colors={[color]}
        pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableArea={true}
        areaBaselineValue={minY}
        areaOpacity={1}
        curve="catmullRom"
        tooltip={toolTipElement}
        crosshairType="cross"
      />
    </div>
  ) : null;
};

export default MyResponsiveLine;
