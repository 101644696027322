import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { BLUE_PLOOT } from '@plootus/common';
import BlogSearch from './BlogSearch';
import BlogCard from './BlogCard';
import BlogPagination from './BlogPagination';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 700,
    color: '#1E293B',
    marginBottom: theme.spacing(4)
  },
  gridContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400
  }
}));

const AllBlogPosts = () => {
  const classes = useStyles();
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const result = await axios.get(
          'https://www.plootus.com/blog/?rest_route=/wp/v2/posts&per_page=100'
        );
        const formattedPosts = result.data.map(post => ({
          id: post.id,
          slug: post.slug,
          title: post.title.rendered,
          excerpt: extractExcerpt(post.content.rendered),
          image: extractFirstImage(post.content.rendered),
          date: formatDate(post.date),
          link: post.link
        }));
        setBlogPosts(formattedPosts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  // Helper functions for content extraction
  const extractFirstImage = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const img = div.querySelector('img');
    return img ? img.src : '/placeholder.png';
  };

  const extractExcerpt = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const paragraphs = div.getElementsByTagName('p');
    for (let p of paragraphs) {
      const text = p.textContent.trim();
      if (text && 
          !text.startsWith('Please Share') && 
          text !== 'Until next time!' && 
          !text.includes('Plootus') && 
          text.length > 50) {
        return text.slice(0, 150) + '...';
      }
    }
    return '';
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Filter posts based on search query
  const filteredPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    post.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  if (loading) {
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.title}>
            All Blog Posts
          </Typography>
          <div className={classes.loaderContainer}>
            <CircularProgress style={{ color: BLUE_PLOOT }} size={40} />
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Typography
          variant="h2"
          className={classes.title}
        >
          All Blog Posts
        </Typography>

        {/* Search Bar */}
        <BlogSearch 
          searchQuery={searchQuery} 
          onSearchChange={setSearchQuery}
        />

        {/* Blog Grid */}
        <Grid container spacing={4} className={classes.gridContainer}>
          {currentPosts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <BlogCard post={post} />
            </Grid>
          ))}
        </Grid>

        {/* Pagination */}
        {filteredPosts.length > postsPerPage && (
          <BlogPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </Container>
    </div>
  );
};

export default AllBlogPosts;