import React from "react";
import styles from "./MessageBox.module.css";
import { TEXT_BLACK } from "@plootus/common";

const MessageBox = () => {
  return (
    <div className={styles.container} style={{ color: TEXT_BLACK }}>
      Thanks for using our app. We have not analyzed your employer's 401k plan
      but we will get right at it. Check back in a few days.
    </div>
  );
};

export default MessageBox;
