import React from "react";
import { useSelector, useDispatch } from "react-redux";
import VisualData from "../../../Reusables/VisualAndAccord/Container/VisualData";
import { CASH_API, INCOMEXP_SCREEN, getDate } from "@plootus/common";
import { get_cashflow } from "@plootus/common";
import { EXPAND_ACCORD } from "@plootus/common";
import CashFlowGraph from "./CashFlowGraph";

const CashFlow = (props) => {
  const { cashflow_data, cashflow_graph, cashflow_total } = useSelector(
    (state) => state.portfolioReducer.incomexp
  );
  const cash = {
    cashflow_data,
    cashflow_graph,
    cashflow_total,
  };
  const dispatch = useDispatch();

  let newGraph = [];
  if (cashflow_graph)
    cashflow_graph.map((item, index) => {
      newGraph.push({
        x: item.x.substr(0, 3) + " " + item.year,
        y: item.y,
        idx: index,
      });
    });

  return (
    <VisualData
      api_name={CASH_API}
      screen={INCOMEXP_SCREEN}
      dispatchAction={get_cashflow}
      title="Total Cashflow"
      chartComponent={(pickerIndex) => (
        <CashFlowGraph data={newGraph} edit={false} pickerIndex={pickerIndex} />
      )}
      type="graph"
      _key="id"
      actionType={EXPAND_ACCORD}
      _key_accord="id"
      singleScreen
      dispatch={dispatch}
      {...cash}
      {...props}
    />
  );
};

export default CashFlow;
